import { Button, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateEditAgreement,
  GetAgreementById,
} from "../../../../../helper/setup/payroll/agreement";
import CreateAgreementTable from "../../../../../shared/DataTable/CreateAgreementTable";
import { ButtonSpinner } from "../../../../../components/spinners";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { FaTimes } from "react-icons/fa";
const AgreementForm = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  let { cursor } = useParams();

  const initialvalues = {
    id: id ? id : "",
    isSave: id ? 0 : 1,
    name: "",
    agreement_breaks_id: "",
    hours: [
      {
        mon_to_thr: "",
        friday: "",
        saturday: "",
        sunday: "",
        late_and_early: "",
        time: "Normal Time",
      },
      {
        mon_to_thr: "",
        friday: "",
        saturday: "",
        sunday: "",
        late_and_early: "",
        time: "Over Time",
      },
      {
        mon_to_thr: "",
        friday: "",
        saturday: "",
        sunday: "",
        late_and_early: "",
        time: "Double Time",
      },
      /* {
        mon_to_thr: "All Remaining",
        friday: "All Remaining",
        saturday: "All Remaining",
        sunday: "All Remaining",
        late_and_early: "All Remaining",
        time: "Double Time",
      }, */
    ],
    unpaid_break_value: "",
    unpaid_break_duration: "Minutes",
    first_break_value: "",
    first_break_duration: "Minutes",
    first_break_paid_value: "",
    first_break_paid_duration: "Hours",
    second_break_value: "",
    second_break_duration: "Minutes",
    second_break_paid_value: "",
    second_break_paid_duration: "Hours",
    third_break_value: "",
    third_break_duration: "Minutes",
    third_break_paid_value: "",
    third_break_paid_duration: "Hours",
    selectedErrorIndex: null,
    errors: {
      name: "",
      unpaid_break_value: "",
      first_break_value: "",
      second_break_value: "",
      third_break_value: "",
      first_break_paid_value: "",
      second_break_paid_value: "",
      third_break_paid_value: "",
      mon_to_thr: "",
      friday: "",
      saturday: "",
      sunday: "",
      late_and_early: "",
    },
    isLoading: false,
    isShimmer: true,
  };

  const [agreement, setAgreement] = useState(initialvalues);

  let name = useRef();
  let unpaid_break_value = useRef();
  let first_break_value = useRef();
  let second_break_value = useRef();
  let third_break_value = useRef();
  let first_break_paid_value = useRef();
  let second_break_paid_value = useRef();
  let third_break_paid_value = useRef();
  let mon_to_thr = useRef([]);
  let friday = useRef([]);
  let saturday = useRef([]);
  let sunday = useRef([]);
  let late_and_early = useRef([]);

  agreement?.hours?.map((hours, index) => {
    mon_to_thr.current[index] = React.createRef();
    friday.current[index] = React.createRef();
    saturday.current[index] = React.createRef();
    sunday.current[index] = React.createRef();
    late_and_early.current[index] = React.createRef();
  });

  const handleAgreement = {
    get: (e) => {
      //

      setAgreement((prev) => ({
        ...prev,
        [e?.target?.name]: e?.target?.value,
      }));
    },
    getHour: (e, index) => {
      const { name, value } = e.target;
      const checkerIndex = index ? 0 : 1;

      setAgreement((prev) => {
        let hours = prev.hours;

        const checkerValue = parseFloat(hours[checkerIndex][name]) || 0;

        hours[index][name] = String(
          parseFloat(value) + checkerValue > 24 ? hours[index][name] : value
        );

        hours[2][name] = String(
          24 -
            ((parseFloat(hours[0][name]) || 0) +
              (parseFloat(hours[1][name]) || 0))
        );

        return {
          ...agreement,
          hours,
        };
      });
    },
    submit: async () => {
      let allHours = [];
      agreement?.hours?.map((value, index) => {
        if (!value?.mon_to_thr) {
          allHours?.push({ index: index, for: "mon_to_thr" });
        } else if (!value?.friday) {
          allHours?.push({ index: index, for: "friday" });
        } else if (!value?.saturday) {
          allHours?.push({ index: index, for: "saturday" });
        } else if (!value?.sunday) {
          allHours?.push({ index: index, for: "sunday" });
        }
        // else if (!value?.late_and_early) {
        // allHours?.push({ index: index, for: "late_and_early" });
        // }
      });

      if (!agreement?.name) {
        setAgreement({
          ...agreement,
          errors: { ...initialvalues?.errors, name: "Enter name" },
        });
        name?.current?.focus();
      } else if (allHours?.length > 0) {
        allHours?.map((value, index) => {
          if (index < 1) {
            if (value?.for == "mon_to_thr") {
              setAgreement({
                ...agreement,
                errors: {
                  ...initialvalues?.errors,
                  mon_to_thr: "Enter time",
                },
                selectedErrorIndex: value?.index,
              });
              mon_to_thr?.current[value?.index]?.current?.focus();
            } else if (value?.for == "friday") {
              setAgreement({
                ...agreement,
                errors: {
                  ...initialvalues?.errors,
                  friday: "Enter time",
                  selectedErrorIndex: value?.index,
                },
                selectedErrorIndex: value?.index,
              });
              friday?.current[value?.index]?.current?.focus();
            } else if (value?.for == "saturday") {
              setAgreement({
                ...agreement,
                errors: {
                  ...initialvalues?.errors,
                  saturday: "Enter time",
                },
                selectedErrorIndex: value?.index,
              });
              saturday?.current[value?.index]?.current?.focus();
            } else if (value?.for == "sunday") {
              setAgreement({
                ...agreement,
                errors: {
                  ...initialvalues?.errors,
                  sunday: "Enter time",
                },
                selectedErrorIndex: value?.index,
              });
              sunday?.current[value?.index]?.current?.focus();
            }
            //  else if (value?.for == "late_and_early") {
            // setAgreement({
            //   ...agreement,
            //   errors: {
            //     ...initialvalues?.errors,
            //     late_and_early: "Enter time",
            //   },
            //   selectedErrorIndex: value?.index,
            // });
            // late_and_early?.current[value?.index]?.current?.focus();
            // }
          }
        });
      } else if (!agreement?.unpaid_break_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            unpaid_break_value: "Enter break time",
          },
        });
        unpaid_break_value?.current?.focus();
      } else if (!agreement?.first_break_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            first_break_value: "Enter break time",
          },
        });
        first_break_value?.current?.focus();
      } else if (!agreement?.second_break_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            second_break_value: "Enter break time",
          },
        });
        second_break_value?.current?.focus();
      } else if (!agreement?.third_break_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            third_break_value: "Enter break time",
          },
        });
        third_break_value?.current?.focus();
      } else if (!agreement?.first_break_paid_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            first_break_paid_value: "Enter break time",
          },
        });
        first_break_paid_value?.current?.focus();
      } else if (!agreement?.second_break_paid_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            second_break_paid_value: "Enter break time",
          },
        });
        second_break_paid_value?.current?.focus();
      } else if (!agreement?.third_break_paid_value) {
        setAgreement({
          ...agreement,
          errors: {
            ...initialvalues?.errors,
            third_break_paid_value: "Enter break time",
          },
        });
        third_break_paid_value?.current?.focus();
      } else {
        setAgreement({
          ...agreement,
          errors: { ...initialvalues?.errors },
          isLoading: true,
        });

        let data = await CreateEditAgreement(agreement);
        if (data?.status) {
          setAgreement(initialvalues);
          navigate("/admin/settings/payroll/agreements");
        } else {
          setAgreement({
            ...agreement,
            isLoading: false,
          });
        }
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (id) {
        let data = await GetAgreementById(agreement, cursor);
        setAgreement({
          ...agreement,
          ...data?.data?.data,
          ...data?.data?.data?.agreement_breaks?.[0],
          hours: [...data?.data?.data?.agreement_hours],
          id: id,
          agreement_breaks_id: data?.data?.data?.agreement_breaks?.[0]?.id,
          isShimmer: false,
        });
        setTimeout(() => {
          name?.current?.focus();
        }, 100);
      } else {
        setAgreement({
          ...agreement,
          isShimmer: false,
        });
        setTimeout(() => {
          name?.current?.focus();
        }, 100);
      }
    })();
  }, []);

  return (
    <div>
      {agreement?.isShimmer ? (
        <div className="card bg-white p-4">
          <TextShimmer data={{ gap: 15, line: 21 }} />
        </div>
      ) : (
        <>
          <div className="card bg-white p-4 ">
            <div className="flex justify-between items-center">
              <p className="">Enter Agreement Name</p>

              <button
                className="ml-auto"
                onClick={() => navigate("/admin/settings/payroll/agreements")}
              >
                <FaTimes />
              </button>
            </div>
            <div>
              {" "}
              <Input
                className={
                  agreement?.errors?.name
                    ? "mt-4 w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-4 w-full"
                }
                ref={name}
                name="name"
                value={agreement?.name}
                onChange={handleAgreement?.get}
              />
              {agreement?.errors?.name && (
                <p className="pt-2 text-red-600 text-sm">
                  {agreement?.errors?.name}
                </p>
              )}
            </div>
          </div>

          <div className="card mt-5 bg-white p-4">
            <p className="">Hours</p>
            <CreateAgreementTable
              data={agreement}
              handle={handleAgreement}
              mon_to_thr={mon_to_thr}
              friday={friday}
              saturday={saturday}
              sunday={sunday}
              late_and_early={late_and_early}
            />
          </div>
          <div className="card mt-5 bg-white p-4">
            <p className="">Hours</p>
            <table className="w-full mt-4 agdtable">
              <thead className="bg-[#E8F3FF] text-center">
                <tr className="text-left">
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      Unpaid Break
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      First Break
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Second Break
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Third Break
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                <tr className="text-left">
                  {/* unpaid */}
                  <td className=" px-4 py-3">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={unpaid_break_value}
                        className={
                          agreement?.errors?.unpaid_break_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="unpaid_break_value"
                        value={agreement?.unpaid_break_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="unpaid_break_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.unpaid_break_duration}
                      >
                        <option value="Minutes">Minutes</option>
                        <option value="Hours">Hours</option>
                      </select>
                    </div>
                    <div>
                      {agreement?.errors?.unpaid_break_value && (
                        <p className="pt-2 text-red-600 text-sm">
                          {agreement?.errors?.unpaid_break_value}
                        </p>
                      )}
                    </div>
                  </td>
                  {/* first break */}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={first_break_value}
                        className={
                          agreement?.errors?.first_break_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="first_break_value"
                        value={agreement?.first_break_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="first_break_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.first_break_duration}
                      >
                        <option value="Minutes">Minutes</option>
                        <option value="Hours">Hours</option>
                      </select>
                    </div>
                    <div>
                      {agreement?.errors?.first_break_value && (
                        <p className="pt-2 text-red-600 text-sm">
                          {agreement?.errors?.first_break_value}
                        </p>
                      )}
                    </div>
                  </td>
                  {/* second break */}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={second_break_value}
                        className={
                          agreement?.errors?.second_break_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="second_break_value"
                        value={agreement?.second_break_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="second_break_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.second_break_duration}
                      >
                        <option value="Minutes">Minutes</option>
                        <option value="Hours">Hours</option>
                      </select>
                    </div>
                    {agreement?.errors?.second_break_value && (
                      <p className="pt-2 text-red-600 text-sm">
                        {agreement?.errors?.second_break_value}
                      </p>
                    )}
                  </td>
                  {/* third break */}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={third_break_value}
                        className={
                          agreement?.errors?.third_break_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="third_break_value"
                        value={agreement?.third_break_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="third_break_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.third_break_duration}
                      >
                        <option value="Minutes">Minutes</option>
                        <option value="Hours">Hours</option>
                      </select>
                    </div>
                    {agreement?.errors?.third_break_value && (
                      <p className="pt-2 text-red-600 text-sm">
                        {agreement?.errors?.third_break_value}
                      </p>
                    )}
                  </td>
                </tr>
                <tr className="text-left">
                  <th className=" px-4 py-3 "></th>

                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Paid After
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Paid After
                    </div>
                  </th>
                  <th className=" px-4 py-3 ">
                    <div className="flex items-center justify-center">
                      Paid After
                    </div>
                  </th>
                </tr>
                <tr className="text-left">
                  <td className=" px-4 py-3"></td>
                  {/* first break paid after*/}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={first_break_paid_value}
                        className={
                          agreement?.errors?.first_break_paid_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="first_break_paid_value"
                        value={agreement?.first_break_paid_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="first_break_paid_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.first_break_paid_duration}
                      >
                        <option value="Hours">Hours</option>
                        <option value="Minutes">Minutes</option>
                      </select>
                    </div>
                    <div>
                      {agreement?.errors?.first_break_paid_value && (
                        <p className="pt-2 text-red-600 text-sm">
                          {agreement?.errors?.first_break_paid_value}
                        </p>
                      )}
                    </div>
                  </td>
                  {/* second break paid after*/}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={second_break_paid_value}
                        className={
                          agreement?.errors?.second_break_paid_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="second_break_paid_value"
                        value={agreement?.second_break_paid_value}
                        onChange={handleAgreement?.get}
                      />
                      <select
                        className="w-full ml-2"
                        name="second_break_paid_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.second_break_paid_duration}
                      >
                        <option value="Hours">Hours</option>
                        <option value="Minutes">Minutes</option>
                      </select>
                    </div>
                    <div>
                      {agreement?.errors?.second_break_paid_value && (
                        <p className="pt-2 text-red-600 text-sm">
                          {agreement?.errors?.second_break_paid_value}
                        </p>
                      )}
                    </div>
                  </td>
                  {/* third break paid after*/}
                  <td className=" px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        ref={third_break_paid_value}
                        className={
                          agreement?.errors?.third_break_paid_value
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        name="third_break_paid_value"
                        value={agreement?.third_break_paid_value}
                        onChange={handleAgreement?.get}
                        onKeyPress={(e) =>
                          e?.key == "Enter" && handleAgreement?.submit()
                        }
                      />
                      <select
                        className="w-full ml-2"
                        name="third_break_paid_duration"
                        onChange={handleAgreement?.get}
                        value={agreement?.third_break_paid_duration}
                      >
                        <option value="Hours">Hours</option>
                        <option value="Minutes">Minutes</option>
                      </select>
                    </div>
                    <div>
                      {agreement?.errors?.third_break_paid_value && (
                        <p className="pt-2 text-red-600 text-sm">
                          {agreement?.errors?.third_break_paid_value}
                        </p>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="py-3">
            <Button
              className="btn btn-blue bg-blue-disable w-1/5 float-right"
              onClick={handleAgreement?.submit}
              disabled={agreement?.isLoading}
            >
              {agreement?.isLoading ? <ButtonSpinner /> : "Save"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AgreementForm;
