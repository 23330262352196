import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import TabTasksTable from "../../../../../shared/DataTable/TabTasksTable";

import {
  AddTaskLogCause,
  GetTasks,
} from "../../../../../helper/worksite/worksiteDetails.js/task";
import { useParams } from "react-router-dom";
import { Dropdown } from "antd";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { toast } from "react-toastify";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { NoSearchTableSvg } from "../../../../../assets/svg/tableDefaul";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import AreasDefaultSvg from "../../../../../assets/svg/areasDefault";
import Checkbox from "antd/es/checkbox/Checkbox";
import InfiniteScroll from "react-infinite-scroll-component";
import { ButtonSpinner } from "../../../../../components/spinners";
import { GetAreas } from "../../../../../helper/worksite/worksiteDetails.js/areas";
import TaskDataDefaultPage from "../../../../../components/default/taskDataDefaultTable";

const Tasks = () => {
  let { id } = useParams();

  const initialTasks = {
    search: "",
    logger: "all",
    status: "all",
    cursor: "",
    skip: "",
    area_id: "",
    data: [],
    extra: {},
    hourlyTasks: [],
    hrte_id: [],
    hourlySearch: "",
    log: "",
    errors: {
      log: "",
    },
    selectedItem: {},
    isShimmer: true,
    isLoading: false,
  };

  let log = useRef();

  const initialHourlyTask = {
    all: false,
    search: "",
    selectedData: [],
  };

  const [hourlyTasks, setHorulyTasks] = useState(initialHourlyTask);

  const [tasks, setTasks] = useState(initialTasks);

  const [selectedTab, setSelectedTabs] = useState("all");
  const [isAnimatePulse, setIsAnimatePulse] = useState(false);
  const [activeItem, setActiveItem] = useState("All Tasks");
  const [visibleModal, setVisibleModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handleActiveState = (e) => {
    const items = document.querySelectorAll(".custom-tab");
    items.forEach((item) => {
      item.classList.remove("btn-blue");
    });
    e.target.classList.add("btn-blue");
    setActiveItem(e.target.textContent);
  };

  let deafaulPage = {
    disclaimer: "No data available for displaying tasks",
    description: "The tasks needs to have areas added.",
    buttonText: "Create New Task",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  const Nologged = {
    disclaimer: "No data available for logged tasks",
    description: "The tasks needs to updated.",
    svg: <AreasDefaultSvg />,
  };

  const NoWarnings = {
    disclaimer: "No data available for warning tasks",
    description: "The tasks needs to updated.",
    svg: <AreasDefaultSvg />,
  };

  const allTask = {
    disclaimer: "No data available to display all tasks",
    description: "The tasks needs to updated.",
    svg: <AreasDefaultSvg />,
  };
  const handleTasks = {
    getTasks: async (hrt) => {
      const params = {
        worksite_id: id,
        search: hrt?.search,
      };

      let data = await GetAreas(params);
      if (data?.status) {
        setTasks({
          ...tasks,
          hourlyTasks: [...data?.data?.data],
        });
      }
    },
    searchTask: async (e) => {
      e?.preventDefault();
      const params = {
        search: e?.target?.value,
      };

      setHorulyTasks({
        ...hourlyTasks,
        search: e?.target?.value,
      });

      let data = await handleTasks?.getTasks(params);
    },
    checkHrt: async (e, id) => {
      if (e?.target?.checked) {
        let allHrt = [...hourlyTasks?.selectedData];
        allHrt?.push(id);

        setHorulyTasks({
          ...hourlyTasks,
          selectedData: allHrt,
        });

        const params = {
          ...tasks,
          worksite_id: id,
          hrte_id: allHrt,
        };

        let data = await handleTasks?.getAllTasks(params);
      } else {
        let allHrt = [...hourlyTasks?.selectedData];
        allHrt?.splice(allHrt?.indexOf(id), 1);

        setHorulyTasks({
          ...hourlyTasks,
          selectedData: allHrt,
        });

        const params = {
          ...tasks,
          worksite_id: id,
          hrte_id: allHrt,
        };

        let data = await handleTasks?.getAllTasks(params);
      }
    },
    selectAll: async (e) => {
      if (e?.target?.checked) {
        let allHrt = [];
        tasks?.hourlyTasks?.map((hrt, index) => {
          allHrt?.push(hrt?._id);
        });

        setHorulyTasks({
          ...hourlyTasks,
          all: true,
          selectedData: allHrt,
        });

        const params = {
          ...tasks,
          worksite_id: id,
          hrte_id: [],
        };

        let data = await handleTasks?.getAllTasks(params);
      } else {
        setHorulyTasks({
          ...hourlyTasks,
          all: false,
          selectedData: [],
        });
      }

      const params = {
        ...tasks,
        worksite_id: id,
        hrte_id: [],
      };

      let data = await handleTasks?.getAllTasks(params);
    },
    getStatus: async (status) => {
      const params = {
        ...tasks,
        worksite_id: id,
        logger: status,
        hrte_id: hourlyTasks?.selectedData?.toString(),
      };

      setSelectedTabs(status);

      let data = await handleTasks?.getAllTasks(params);
    },
    search: async (e) => {
      const params = {
        ...tasks,
        worksite_id: id,
        search: e?.target?.value,
        logger: selectedTab,
      };

      let data = await handleTasks?.getAllTasks(params);
    },
    fetch: async () => {
      const params = {
        ...tasks,
        worksite_id: id,
        cursor: tasks?.extra?.cursor,
        type: "hourly",
      };

      if (tasks?.data?.length != tasks?.extra?.totalItems) {
        let data = await GetTasks(params);
        if (data?.status) {
          let allTask = [...tasks?.data];
          data?.data?.data?.map((task, index) => {
            allTask.push({ ...task });
          });

          setTasks({
            ...tasks,
            data: allTask,
            extra: data?.extra,
            isShimmer: false,
            isLoading: false,
          });
        }
      }
    },
    getAllTasks: async (taskData) => {
      setIsAnimatePulse(true);

      const params = {
        worksite_id: id,
        status: taskData?.status,
        search: taskData?.search,
        logger: taskData?.logger,
        type: "hourly",
        area_ids: taskData?.hrte_id?.toString(),
        skip: taskData?.skip,
      };
      let data = await GetTasks(params);

      if (data?.status) {
        setTasks({
          ...tasks,
          search: taskData?.search,
          logger: taskData?.logger,
          status: taskData?.status,
          skip: taskData?.skip,
          area_id: taskData?.area_id,
          data: [...data?.data?.data],
          extra: data?.extra,
          isShimmer: false,
          isLoading: false,
        });
        setIsAnimatePulse(false);
        return data;
      } else {
        return data;
      }
    },
    getLogCause: (e) => {
      setTasks({
        ...tasks,
        [e?.target?.name]: e?.target?.value,
      });
    },
    saveLogCause: async (e) => {
      if (!tasks?.log) {
        setTasks({
          ...tasks,
          errors: {
            ...tasks?.errors,
            log: "Enter Log",
          },
        });
      } else {
        setTasks({
          ...tasks,
          isLoading: true,
          errors: {
            ...tasks?.errors,
            log: "",
          },
        });
        const params = {
          task_id: tasks?.selectedItem?.id,
          log: tasks?.log,
          id: tasks?.selectedItem?.log_cause?.[0]?.id
            ? tasks?.selectedItem?.log_cause?.[0]?.id
            : "",
          isSave: tasks?.selectedItem?.log_cause?.[0]?.id ? 0 : 1,
          type: "hourly",
          // type: "productivity_order",
          /*  tasks?.selectedItem?.productivity_orders_data?.length > 0
              ? "productivity_order"
              : "hourly", */
        };

        let data = await AddTaskLogCause(params);
        if (data?.status) {
          const params = {
            ...tasks,
            worksite_id: id,
            logger: selectedTab,
          };

          let getTask = await handleTasks?.getAllTasks(params);
          if (getTask?.status) {
            toast?.success(data?.message);
            setVisibleModal(false);
          }
        } else {
          setTasks({
            ...tasks,
            isLoading: false,
          });
          toast?.error(data?.message);
        }
      }
    },
  };

  function toggleDropdown() {
    setDropdownOpen((prev) => !prev);
  }

  function TaskCheck(e) {
    return (
      <div
        className="min-w-[25vw] max-h-[35vh] bg-white p-2 rounded-lg drop-shadow-lg shadow-lg"
        onBlur={toggleDropdown}
      >
        <span className="flex justify-between p-2">
          <p>Areas</p>
          <p>
            Select All{" "}
            <Checkbox
              checked={hourlyTasks?.all}
              onChange={handleTasks?.selectAll}
            />
          </p>
        </span>
        <hr className="border-[#F5F5F5] border-b-4" />
        <div className="max-h-[20vh] overflow-y-scroll">
          {tasks?.hourlyTasks?.map((area, index) => (
            <div
              key={area?._id}
              className="flex justify-between p-2 border-b border-[#F5F5F5]"
            >
              <div>
                <label htmlFor={area?._id} key={area?._id}>
                  {area?.abbreviation}&nbsp;{area?.name}
                </label>
              </div>
              <div>
                <Checkbox
                  id={area?._id}
                  key={area?._id}
                  checked={hourlyTasks?.selectedData.includes(area?._id)}
                  onChange={(e) => handleTasks?.checkHrt(e, area?._id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  useEffect(() => {
    (async () => {
      handleTasks?.getAllTasks(initialTasks);
    })();
  }, []);

  const renderDefaultPage = (selectedTab, hourlyTasks) => {
    if (
      selectedTab === "all" &&
      hourlyTasks?.selectedData?.length > 0 &&
      tasks?.data?.length === 0
    ) {
      //
      return <TaskDataDefaultPage data={allTask} />;
    } else if (
      selectedTab === "warning" &&
      hourlyTasks?.selectedData?.length > 0 &&
      tasks?.data?.length === 0
    ) {
      //
      return <TaskDataDefaultPage data={NoWarnings} />;
    } else if (
      selectedTab === "2" &&
      hourlyTasks?.selectedData?.length > 0 &&
      tasks?.data?.length === 0
    ) {
      //
      return <TaskDataDefaultPage data={Nologged} />;
    } else {
      return <TableDefaultPage data={deafaulPage} />;
    }
  };

  return (
    <>
      <div className="p-4">
        {tasks?.isShimmer ? (
          <div>
            <TextShimmer data={{ gap: 15, line: 20 }} />
          </div>
        ) : (
          <>
            {/* {tasks?.data?.length === 0 && tasks?.logger === "all" ? (
              <div className="py-8 h-[70vh]">
                <TableDefaultPage data={deafaulPage} />
              </div>
            ) : ( */}
            <>
              <div className={isAnimatePulse ? "animate-pulse" : ""}>
                <div className="taskTab py-5">
                  <div className="flex justify-start items-center">
                    <button
                      className={
                        selectedTab == "all"
                          ? "btn custom-tab bg-white border border-black px-4 py-2 mr-3 btn-blue"
                          : "btn custom-tab bg-white border border-black px-4 py-2 mr-3"
                      }
                      onClick={() => handleTasks?.getStatus("all")}
                    >
                      All Tasks
                    </button>
                    <button
                      className={
                        selectedTab == "warning"
                          ? "btn custom-tab bg-white border border-black px-4 py-2 mr-3 btn-blue"
                          : "btn custom-tab bg-white border border-black px-4 py-2 mr-3"
                      }
                      onClick={() => handleTasks?.getStatus("warning")}
                    >
                      Warnings
                    </button>
                    <button
                      className={
                        selectedTab == "2"
                          ? "btn custom-tab bg-white border border-black px-4 py-2 mr-3 btn-blue"
                          : "btn custom-tab bg-white border border-black px-4 py-2 mr-3"
                      }
                      onClick={() => handleTasks?.getStatus("2")}
                    >
                      Logged
                    </button>
                    <div className="task-header flex justify-between items-cente ml-auto">
                      <div className="flex justify-end items-center">
                        <div className="item mr-4 flex justify-end items-center "></div>
                        <div className="item flex justify-start items-center">
                          <div className="border-[1px] rounded-md border-black text-center">
                            <Dropdown
                              trigger={["click"]}
                              placement="bottomRight"
                              dropdownRender={(menu) => (
                                <div>
                                  <div className="p-2">
                                    <div className="border border-[#000] p-2 rounded-md w-full flex justify-start items-center gap-3">
                                      <FaSearch className="scale-90 text-gray-400" />
                                      <input
                                        type="text"
                                        placeholder="Search by areas"
                                        className="w-full border-none outline-none text-sm"
                                        value={hourlyTasks?.search}
                                        onChange={handleTasks?.searchTask}
                                      />
                                    </div>
                                  </div>
                                  <TaskCheck />
                                </div>
                              )}
                              className="bg-white rounded-lg hover:bg-white"
                            >
                              <div
                                className="px-12 py-2 cursor-pointer"
                                onClick={handleTasks?.getTasks}
                              >
                                All Areas
                              </div>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {tasks?.data?.length === 0 && !tasks?.search ? (
                  <div className="py-8 h-[70vh]">
                    {tasks?.logger === "all" &&
                    hourlyTasks?.selectedData?.length <= 0 ? (
                      <TableDefaultPage data={deafaulPage} />
                    ) : (
                      renderDefaultPage(selectedTab, hourlyTasks)
                    )}
                  </div>
                ) : (
                  <>
                    <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white min-w-[300px] mb-20">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        placeholder="Search by Task or Productivity Orders"
                        className="h-[31px] bg-transparent text-sm w-full px-2"
                        onChange={handleTasks?.search}
                      />
                    </div>
                    {tasks?.data?.length <= 0 && tasks?.search ? (
                      <NoTableOption data={noSearchOption} />
                    ) : (
                      <>
                        <div id="scrollableDiv" className="h-[400]">
                          <InfiniteScroll
                            className=""
                            dataLength={tasks?.data?.length}
                            next={handleTasks?.fetch}
                            hasMore={
                              tasks?.data?.length < tasks?.extra?.totalItems
                            }
                            loader={
                              <div>
                                <ButtonSpinner />
                              </div>
                            }
                            height={400}
                            endMessage={<div></div>}
                            scrollableTarget="scrollableDiv"
                          >
                            <TabTasksTable
                              data={tasks}
                              setData={setTasks}
                              handle={handleTasks}
                              visibleModal={visibleModal}
                              setVisibleModal={setVisibleModal}
                              selectedTab={selectedTab}
                            />
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default Tasks;
