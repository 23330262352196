import React, { useEffect, useState, useRef, useContext } from "react";
import CompanyTable from "../../../shared/DataTable/CompanyTable";
import { FaSearch } from "react-icons/fa";
import { CompanySetupCreate } from "../../../helper/setup/induction/company";
import { GetCompany } from "../../../helper/setup/induction/company";
import { SettingService } from "../../../config/axiosUrl";
import { SearchData } from "../../../helper/global/global";
import { TablePagination } from "../../../components/pagination/pagination";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import { CreateAdminContext } from "../../../layouts/SettingsLayout";
import { TableDefaultPage } from "../../../components/default/defaultPage";
import {
  TableDefaultSvg,
  NoSearchTableSvg,
} from "../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../components/noOption/noOption";

const Companies = () => {
  const [createAdmincontext, setcreateAdminContext] =
    useContext(CreateAdminContext);

  const initialvalue = {
    data: [],
    selectedCompany: {},
    trigger: false,
    extra: {},
    pagination: {
      service: SettingService,
      api: "/api/v1/setting/company/get-company-data",
    },
    isShimmer: true,
    selectedIndex: null,
  };

  const [company, setCompany] = useState(initialvalue);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [configModalVisible, setConfigModalVisible] = React.useState(false);

  let deafaulPage = {
    disclaimer: "No Company Found",
    description: "Create Your First Company",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  let search = useRef();

  const handleEmail = {
    selectEmail: async (email, index) => {
      setCompany({
        ...company,
        selectedIndex: index,
      });
      const params = {
        id: company?.selectedCompany?.id,
        type: "EMAIL_TEMPLATE",
        email_template_id: email?.id,
      };
      let data = await CompanySetupCreate(params);
      if (data?.status) {
        /*    let allData = [...company?.data];
        allData[company?.selectedCompany?.index] = {
          ...allData[company?.selectedCompany?.index],
          email_templates: { ...company?.selectedCompany },
        };
        
        setCompany({
          ...company,
          data: allData,
          selectedIndex: null,
        }); */
        setCompany({
          ...company,
          trigger: true,
          selectedIndex: null,
        });
        setModalVisible(false);
      }
    },
    selectDisclaimer: async (dislaimer, index) => {
      setCompany({
        ...company,
        selectedIndex: index,
      });
      const params = {
        id: company?.selectedCompany?.id,
        type: "DISCLAIMER",
        disclaimer_id: dislaimer?.id,
      };

      let data = await CompanySetupCreate(params);
      if (data?.status) {
        setCompany({
          ...company,
          trigger: true,
          selectedIndex: null,
        });
        setConfigModalVisible(false);
      }
    },
    search: async (e) => {
      const params = {
        value: e?.target?.value,
        service: SettingService,
        api: "/api/v1/setting/company/get-company-data",
      };
      let data = await SearchData(params);
      if (data?.status) {
        setCompany({
          ...company,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          [e?.target?.name]: e?.target?.value,
        });
      }
    },
  };

  const EmailModalVisible = (getcompany, index) => {
    setCompany({
      ...company,
      selectedCompany: { ...getcompany, index: index },
    });
    setModalVisible(true);
  };

  const ConfigModalVisible = (getcompany, index) => {
    setCompany({
      ...company,
      selectedCompany: { ...getcompany, index: index },
    });
    setConfigModalVisible(true);
  };

  useEffect(() => {
    (async () => {
      let data = await GetCompany();

      if (data?.status) {
        setCompany({
          ...company,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          trigger: false,
          isShimmer: false,
        });
        setcreateAdminContext(false);
      }
    })();
  }, [company?.trigger, createAdmincontext]);

  return (
    <div className="p-6">
      <div className="bg-white">
        {company?.isShimmer ? (
          <div className="py-6 px-4 ">
            <TextShimmer data={{ gap: 15, line: 25 }} />
          </div>
        ) : (
          <>
            {!company?.data?.length > 0 && !company?.search ? (
              <>
                <TableDefaultPage data={deafaulPage} />
              </>
            ) : (
              <>
                <div className="p-4">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder="Search by Company Name"
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      ref={search}
                      name="search"
                      onChange={handleEmail?.search}
                    />
                  </div>
                </div>
                <CompanyTable
                  company={company}
                  setCompany={setCompany}
                  handleEmail={handleEmail}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  configModalVisible={configModalVisible}
                  setConfigModalVisible={setConfigModalVisible}
                  EmailModalVisible={EmailModalVisible}
                  ConfigModalVisible={ConfigModalVisible}
                />

                {company?.data?.length <= 0 && company?.search ? (
                  <>
                    <NoTableOption data={noSearchOption} />
                  </>
                ) : (
                  <>
                    <TablePagination data={company} setData={setCompany} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Companies;
