import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import DetailsTop from "./DetailsTop";
import WorksiteTabs from "./WorksiteTabs";
import {
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
import OverViews from "./Tabpans/Overviews";
import Tasks from "./Tabpans/Tasks";
import Areas from "./Tabpans/Areas";
import Timesheet from "./Tabpans/Timesheet";
import AreaCardDetails from "../../../../components/areaCardDetails/AareaCardDetails";
import { GetWorksiteData } from "../../../../helper/worksite/worksiteDetails.js/worksitedetails";
import MergeHistory from "../../../../components/areaCardDetails/mergeCards/mergeHistory";
import MergeCreate from "../../../../components/areaCardDetails/mergeCards/mergeCreate";
import ProductivityOrders from "./Tabpans/ProductivityOrders";
import TimesheetDetails from "./WorksiteComponents/TimesheetDetails";
import WorkerTimeline from "./WorksiteComponents/WorkerTimeline";
import WorkerBreakdown from "./WorksiteComponents/WorkerBreakdown";
import moment from "moment";

const WorksiteDetails = () => {
  let { id } = useParams();
  let { area } = useParams();
  let path = useLocation();

  const initialvalue = {
    worksite: {},
    deficit: {},
    lost: {},
    remaining_estimated: {},
    remaining_reserve: {},
    worked: {},
    selectedArea: {
      abbreviation: "",
      name: "",
      id: "",
    },
    selectedOptions: [],
    addedSiteManager: [],
    topCards: [],
    selectedArea: {},
    isShimmer: true,
  };

  const [deatails, setDetails] = useState(initialvalue);
  const [mainDate, setMainDate] = useState();

  const handleWorksiteDetails = {
    getDate: async (e) => {
      let selectedDate = e?.$d ? moment?.(e?.$d)?.format("YYYY-MM-DD") : "";
      setMainDate(selectedDate);

      const params = {
        selectedDate: selectedDate,
      };
      let data = await handleWorksiteDetails?.getWorksite(params);
    },
    getWorksite: async (worksiteDate) => {
      const params = {
        id: id,
        worksite_id: id,
        area_id: area,
        from: "",
        start_date: "",
        type: "hourly",
        date: worksiteDate?.selectedDate,
      };
      let data = await GetWorksiteData(params);

      if (data?.status) {
        let allSiteManager = [];
        data?.data?.data?.worksite?.worksite_managers?.map((sitemng, index) =>
          allSiteManager?.push(sitemng?.site_manager_id)
        );

        let addedSiteManager = [];
        data?.data?.data?.worksite?.worksite_managers?.map((sitemng, index) =>
          addedSiteManager?.push({
            id: sitemng?.id,
            site_manager_id: sitemng?.site_manager_id,
          })
        );

        let topCards = [
          {
            name: "Worked",
            color: "#FFD43B",
            hours:
              path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.worked?.val
                : data?.data?.data?.worksite_data?.worked?.val,
            percentage:
              (path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.worked?.percentage
                : data?.data?.data?.worksite_data?.worked?.percentage) ||
              "0.0%",
          },
          {
            name: "Remaining Estimated",
            color: "#748FFC",
            hours:
              path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? parseFloat(
                    data?.data?.data?.area_extra?.remaining_estimated?.val
                  )?.toFixed(1)
                : parseFloat(
                    data?.data?.data?.worksite_data?.remaining_estimated?.val
                  )?.toFixed(1),
            percentage:
              (path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? parseFloat(
                    data?.data?.data?.area_extra?.remaining_estimated
                      ?.percentage
                  )?.toFixed(1)
                : parseFloat(
                    data?.data?.data?.worksite_data?.remaining_estimated
                      ?.percentage
                  )?.toFixed(1)) || "0.0%",
          },
          {
            name: "Lost",
            color: "#F03E3E",
            hours:
              path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.lost?.val
                : data?.data?.data?.worksite_data?.lost?.val,
            percentage:
              (path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.lost?.percentage
                : data?.data?.data?.worksite_data?.lost?.percentage) || "0.0%",
          },
          {
            name: "Remaining Reserve",
            color: "#40C057",
            hours:
              path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? parseFloat(
                    data?.data?.data?.area_extra?.remaining_reserve?.val
                  )?.toFixed(1)
                : parseFloat(
                    data?.data?.data?.worksite_data?.remaining_reserve?.val
                  )?.toFixed(1),
            percentage:
              (path?.pathname ===
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? parseFloat(
                    data?.data?.data?.area_extra?.remaining_reserve?.percentage
                  )?.toFixed(1)
                : parseFloat(
                    data?.data?.data?.worksite_data?.remaining_reserve
                      ?.percentage
                  )?.toFixed(1)) || "0.0%",
          },
          {
            name: "Deficit",
            color: "#FF922B",
            hours:
              path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.deficit?.val
                : data?.data?.data?.worksite_data?.deficit?.val,
            percentage:
              (path?.pathname ==
              `/admin/worksite/details/areas/cards/${id}/${area}`
                ? data?.data?.data?.area_extra?.deficit?.percentage
                : data?.data?.data?.worksite_data?.deficit?.percentage) ||
              "0.0%",
          },
        ];

        setDetails({
          ...deatails,
          worksite: { ...data?.data?.data?.worksite },
          deficit: data?.extra?.deficit,
          selectedArea: {
            abbreviation: data?.data?.data?.area?.abbreviation,
            name: data?.data?.data?.area?.name,
            id: data?.data?.data?.area?.id,
          },
          lost: data?.extra?.lost,
          remaining_estimated: data?.extra?.remaining_estimated,
          remaining_reserve: data?.extra?.remaining_reserve,
          worked: data?.extra?.worked,
          isShimmer: false,
          selectedOptions: allSiteManager,
          addedSiteManager: addedSiteManager,
          topCards: topCards,
        });

        return data;
      }
    },
  };

  useEffect(() => {
    (async () => {
      let data = await handleWorksiteDetails?.getWorksite();
    })();
  }, [area]);

  return (
    <>
      {path?.pathname == "/admin/worksite/details" && (
        <Navigate
          to={`/admin/worksite/details/overviews/${id}`}
          replace={true}
        />
      )}
      <div className="p-6">
        <div className="bg-white">
          <div className="p-4">
            <DetailsTop
              data={deatails}
              setData={setDetails}
              handle={handleWorksiteDetails}
            />
          </div>
        </div>
        <div className="mt-5 bg-white">
          <div className="">
            <WorksiteTabs />
          </div>

          <Routes>
            <Route
              path="overviews/:id"
              element={<OverViews mainDate={mainDate} />}
            />
            <Route path="areas/:id" element={<Areas />} />
            <Route path="areas-create/:id" element={<Areas />} />
            <Route path="areas/cards/:id/:area" element={<AreaCardDetails />} />
            <Route
              path="areas/cards/merge/:id/:area"
              element={<MergeCreate />}
            />
            <Route
              path="areas/cards/merge/history/:id/:area"
              element={<MergeHistory />}
            />
            <Route path="tasks/:id" element={<Tasks />} />
            <Route path="timesheet/:id" element={<Timesheet />} />

            <Route
              path="timesheet/:id/:weekDayId/*"
              element={<TimesheetDetails />}
            >
              <Route path="timesheet-details" element={<TimesheetDetails />} />
              <Route path="worker-timeline" element={<WorkerTimeline />} />
              <Route path="work-breakdown" element={<WorkerBreakdown />} />
            </Route>
            <Route
              path="productivity-orders/:id"
              element={<ProductivityOrders />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default WorksiteDetails;
