import React, { Fragment } from "react";
import "./QATemplate.css";
import ReactPDF, {
  PDFDownloadLink,
  BlobProvider,
  Document,
  Text,
  Page,
  Image,
  View,
} from "@react-pdf/renderer";
import { styles } from "./QATemplateStyles";
import Logo from "./logo.png";
import moment from "moment";

const QATemplate = ({ data }) => {
  return (
    <Document>
      {data.map((item) => (
        <Page style={styles.page}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Image style={styles.headerImage} src={Logo} />
              <Text style={styles.headerTitle}>QUALITY ASSURANCE</Text>
              <Text style={styles.headerSubtitle}>INSPECTION TEST POINT</Text>
            </View>
            <View style={styles.introContainer}>
              <View>
                <View style={styles.sectionContainer}>
                  <Text style={styles.sectionTitle}>{`Job Name:`}</Text>
                  <Text style={styles.sectionValue}>
                    {item?.worksites?.[0]?.name}
                  </Text>
                </View>
                <View style={styles.sectionContainer}>
                  <Text style={styles.sectionTitle}>{`Job Address:`}</Text>
                  <Text style={styles.sectionValue}>
                    {item?.worksites?.[0]?.address}
                  </Text>
                </View>
                <View style={styles.sectionContainer}>
                  <Text style={styles.sectionTitle}>{`Area:`}</Text>
                  <Text style={styles.sectionValue}>
                    {item?.areas?.[0]?.name}
                  </Text>
                </View>
                <View style={styles.sectionContainer}>
                  <Text style={styles.sectionTitle}>{`Task:`}</Text>
                  <Text style={styles.sectionValue}>
                    {item?.hourly_rate_task?.[0]?.name}
                  </Text>
                </View>
              </View>
              <View>
                <Text style={{ ...styles.sectionValue, fontWeight: 700 }}>
                  Date:{moment().format("DD/MM/YYYY")}
                </Text>
                <Text style={{ ...styles.sectionValue, fontWeight: 700 }}>
                  Percent Complete:{" "}
                  {item?.quality_assurance_details[0]?.percentage_complete}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.tableHeader}>
                <Text>No.</Text>
              </View>
              <View style={[styles.tableHeader, { flex: 1 }]}>
                <Text>Inspection Details</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Status</Text>
              </View>
            </View>
            {item.quality_assurance_details?.map((qaItem, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.tableItems}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={[styles.tableItems, { flex: 1 }]}>
                  <Text>{qaItem.name}</Text>
                </View>
                <View style={styles.tableItems}>
                  <Text>{qaItem.status_data_message}</Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.imageContainer}>
            {item.inspections.map((item, index) =>
              Array.isArray(item.file) ? (
                item.file.map((image, index) => (
                  <Image
                    src={item.imgPath + image}
                    key={index}
                    style={styles.inspectionImages}
                  />
                ))
              ) : typeof item.file === "string" && item.file ? (
                <Image
                  src={item.imgPath + item.file}
                  key={index}
                  style={styles.inspectionImages}
                />
              ) : (
                <Fragment />
              )
            )}
          </View>

          <View style={styles.table} wrap={false}>
            <View style={styles.row}>
              <View style={styles.tableHeader}>
                <Text>No.</Text>
              </View>
              <View style={[styles.tableHeader, { flex: 1 }]}>
                <Text>Notes</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Date</Text>
              </View>
            </View>
            {item.inspections.map((item, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.tableItems}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={[styles.tableItems, { flex: 1 }]}>
                  <Text>{item.description}</Text>
                </View>
                <View style={styles.tableItems}>
                  <Text>{moment(item.created_at).format("DD/MM/YYYY")}</Text>
                </View>
              </View>
            ))}
          </View>

          <View style={styles.footerContainer} fixed>
            <View style={styles.footerHolder}>
              <Image style={styles.footerImage} src={Logo} />
              <Text style={styles.footerCree8Text}>Product of Cree8</Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default QATemplate;
