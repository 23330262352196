import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../../../../../components/spinners";
import { Button, Checkbox, DatePicker, Form, Select } from "antd";
import { TextShimmer } from "../../../../../../components/shimmer/shimmer";
import { useParams } from "react-router-dom";
import {
  GetWorkersType,
  MassAbsense,
} from "../../../../../../helper/worksite/worksite";

const MassAbsenseModal = ({
  onClose,
  data,
  setData,
  search,
  setWorksite,
  worksite,
  absenseType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const id = useParams();
  const saveChanges = async () => {
    setIsLoading(true);
    const selectedOption = data?.selectedOption || [];
    setWorksite({
      ...worksite,
      is_mass_absence: 1,
    });
    const response = await MassAbsense(data, id?.id, worksite, selectedOption);
    if (response?.status) {
      onClose();
      const param = { type: worksite?.worker_status, id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  const onChange = (time, timeString) => {
    setWorksite({
      ...worksite,
      duration: timeString,
    });
  };
  const handleChange = (value) => {};
  const handleMultySelect = {
    check: (e, id) => {
      if (e?.target?.checked) {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.push(id);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      } else {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.splice(allselectedOption?.indexOf(id), 1);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      }
    },
  };
  const closeModal = () => {
    setData({
      ...data,
      isMultySelectMassAbsense: false,
    });
    onClose();
  };
  useEffect(() => {
    (async () => {
      await search();
    })();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          {data?.isShimmer ? (
            <>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </>
          ) : (
            <>
              <h2 className="text-xl font-bold">Mass Absences</h2>
              <p className="text-[#A1A1A1]">
                Total Number of selected Workers{" "}
                <span className="text-[#000]">
                  {data?.selectedOption?.length
                    ? data?.selectedOption?.length
                    : 0}
                </span>
              </p>
              <div className="mt-5">
                <Form layout="vertical" className="mt-4">
                  <div className="flex items-center">
                    <Form.Item label="Absence Type">
                      <Select
                        placeholder="Select Absense Type"
                        style={{ width: 400 }}
                        onChange={handleChange}
                      >
                        {absenseType?.length > 0 &&
                          absenseType?.map((item) => (
                            <Select.Option value={item?.id} key={item?.id}>
                              {item?.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Start Time" className="ml-3">
                      <DatePicker
                        picker="time"
                        onChange={onChange}
                        placeholder="Start Time"
                        className="absense-timer"
                      />
                    </Form.Item>
                  </div>
                </Form>
                <div className="mb-5">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder={data?.searchBarTitle}
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      onChange={search}
                    />
                  </div>
                </div>
                <div className="border divide-y rounded-md">
                  <div className="px-6 py-2 bg-slate-100 flex justify-between">
                    <label className="text-sm font-bold">Worker Name</label>
                    <label className="text-sm font-bold">Email</label>
                    <label className="text-sm font-bold">Select All</label>
                  </div>
                  {data?.options?.map((item, index) => {
                    return (
                      <div
                        className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                        key={index}
                      >
                        <label className="text-sm">{item?.name}</label>
                        <label className="text-sm">{item?.email}</label>

                        <div>
                          <Checkbox
                            id={index}
                            key={index}
                            checked={data?.selectedOption?.includes(item?.id)}
                            onChange={(e) =>
                              handleMultySelect?.check(e, item?.id)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="">
                  <div className="pt-4">
                    <Button
                      className="float-right btn btn-blue w-1/8 bg-blue-disable"
                      onClick={saveChanges}
                      disabled={data?.selectedOption?.length == 0}
                    >
                      {isLoading ? <ButtonSpinner /> : "Confirm"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MassAbsenseModal;
