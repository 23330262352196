import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PayRollService } from "../../config/axiosUrl";
import Modal from "antd/es/modal/Modal";
import { EditSvg } from "../../assets/svg/editsvg";
import { DefaultContext } from "react-icons";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";
import { TablePagination } from "../../components/pagination/pagination";
const NotesTable = ({ selectedDate, searchInput }) => {
  const initialvalues = {
    data: [],
    search: "",
    extra: {},
    isShimmer: true,
    isLoading: false,
    pagination: {
      api: "api/v1/setting/payroll/notes",
      service: PayRollService,
    },
  };

  const [notes, setNotes] = useState(initialvalues);

  const [updatednotes, setUpdatedNotes] = useState([]);
  const [id, setId] = useState([]);
  const [date, setDate] = useState([]);
  const [name, setName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editableNote, setEditableNote] = useState("");

  const requestData = {
    date: selectedDate,
    search: searchInput,
  };

  const handleEditClick = (note, id, date, names) => {
    setEditableNote(note);
    setDate(date);
    setId(id);
    setName(names);
    setShowModal(true);
  };

  const handleSaveClick = () => {
    const updateData = {
      id: id,
      notes: editableNote,
    };

    PayRollService.post("api/v1/setting/payroll/update-notes", updateData)
      .then((res) => {
        setUpdatedNotes(res?.data?.data?.data || []);

        setShowModal(false);
        notesBreakdown();
      })
      .catch((error) => {
        console.error("Error updating notes:", error);
      });
  };

  const notesBreakdown = useCallback(() => {
    PayRollService.post("api/v1/setting/payroll/notes", requestData)
      .then((res) => {
        setNotes({
          ...initialvalues,
          data: res?.data?.data?.data || [],
          extra: res?.data?.extra,
        });
      })
      .catch((error) => {
        console.error("Error fetching notes:", error);
      });
  }, [selectedDate, searchInput]);

  useEffect(() => {
    notesBreakdown();
  }, [selectedDate, searchInput]);

  let deafaulPage = {
    disclaimer: "No Notes Found",
    svg: <TableDefaultSvg />,
  };
  return (
    <div>
      {notes.data.length === 0 ? (
        <div style={{ alignContent: "center" }}>
          <TableDefaultPage data={deafaulPage} />
        </div>
      ) : (
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr>
              <th className="text-left p-4">Date</th>
              <th>Worker</th>
              <th>Site</th>
              <th>Note </th>
              <th>View</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {notes?.data?.map((item, index) => (
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {new Date(item.updated_at).toLocaleDateString()}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.worker_details?.map((items, index) => (
                      <div key={index}>
                        {items?.user?.map((users, value = {}) => (
                          <div key={value}>{users.name}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.worksite?.map((worksites, index) => (
                      <div key={index}>{worksites.name}</div>
                    ))}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item.notes}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() =>
                        handleEditClick(
                          item.notes,
                          item._id,
                          item.updated_at,
                          item.lastModifiedBy?.[0]?.name
                        )
                      }
                    >
                      <EditSvg />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* <TablePagination data={notes} setData={setNotes} /> */}
      {showModal && (
        <Modal
          title="Editing Notes"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <div>Last Modified by :</div>
          <div className="flex justify-content-between  mt-3">
            <div className="w-70">{name}</div>{" "}
            <div className="">Date : {new Date(date).toLocaleDateString()}</div>
          </div>
          <div style={{ padding: "20px" }}>
            <textarea
              value={editableNote}
              onChange={(e) => setEditableNote(e.target.value)}
              style={{
                display: "flex",
                border: "1px solid #ddd",
                padding: "10px",
                marginBottom: "10px",
                width: "100%",
                resize: "vertical",
              }}
            />

            <button
              onClick={handleSaveClick}
              style={{
                backgroundColor: "#0F4C7D",
                color: "#fff",
                padding: "10px",
                cursor: "pointer",
                margin: "0 auto",
                display: "block",
              }}
            >
              Save Changes
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default NotesTable;
