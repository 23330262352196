import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { TextShimmer } from "../shimmer/shimmer";
import { FaSearch } from "react-icons/fa";
import { Button, Checkbox } from "antd";
import { ButtonSpinner } from "../spinners";

const MultySelect = ({ data, setData, search, submit }) => {
  const option = data?.options; // Assuming data is defined

  const filteredOptions = option.filter((item) => item.remaining === 0);

  // Now, filteredOptions contains only the options where remaining is equal to 0

  const closeModal = () => {
    setData({
      ...data,
      isMultySelect: false,
    });
  };

  const handleMultySelect = {
    check: (e, id) => {
      if (e?.target?.checked) {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.push(id);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      } else {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.splice(allselectedOption?.indexOf(id), 1);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      await search();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          {data?.isShimmer ? (
            <>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </>
          ) : (
            <>
              <h2 className="text-xl font-bold">{data?.title}</h2>

              <div className="mt-5">
                <div className="mb-5">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder={data?.searchBarTitle}
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      onChange={search}
                    />
                  </div>
                </div>
                <div className="border divide-y rounded-md">
                  {data?.title === "Available Hourly Rate Tasks" ? (
                    <>
                      {data?.options.map((item, index) => {
                        return (
                          <>
                            <div
                              className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                              key={index}
                            >
                              <div>
                                <label
                                  className="text-sm"
                                  key={index}
                                  htmlFor={index}
                                >
                                  {item?.name}
                                </label>
                              </div>
                              <div>
                                <Checkbox
                                  id={index}
                                  key={index}
                                  checked={data?.selectedOption?.includes(
                                    item?.id
                                  )}
                                  onChange={(e) =>
                                    handleMultySelect?.check(e, item?.id)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {data?.options?.map((item, index) => {
                        return (
                          <>
                            <div
                              className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                              key={index}
                            >
                              <div>
                                <label
                                  className="text-sm"
                                  key={index}
                                  htmlFor={index}
                                >
                                  {item?.name}
                                </label>
                              </div>
                              <div>
                                <Checkbox
                                  id={index}
                                  key={index}
                                  checked={data?.selectedOption?.includes(
                                    item?.id
                                  )}
                                  onChange={(e) =>
                                    handleMultySelect?.check(e, item?.id)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="">
                  <div className="pt-4">
                    <Button
                      className="float-right btn btn-blue w-1/5 bg-blue-disable"
                      onClick={submit}
                      disabled={data?.isLoading}
                    >
                      {data?.isLoading ? <ButtonSpinner /> : data?.buttonTitle}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultySelect;
