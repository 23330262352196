import React, { useEffect, useRef, useState, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import {
  CreateUpdateEmployeementStatus,
  GetEmployeeStatus,
} from "../../helper/setup/payroll/employeentstatus";
import EmployeStatusModalForm from "../EmployeStatusModalForm/EmployeStatusModalForm";
import { CreateAdminContext } from "../../layouts/SettingsLayout";

const CreateEmployementStatusModal = ({
  setCreateEmployementStatus,
  data,
  setData,
}) => {
  //context
  const [createAdmincontext, setcreateAdminContext] =
    useContext(CreateAdminContext);

  const initialvalues = {
    name: data?.selectedData?.name ? data?.selectedData?.name : "",
    is_ignore_worker_allowances: data?.selectedData?.is_ignore_worker_allowances
      ? data?.selectedData?.is_ignore_worker_allowances
      : 0,
    is_pay_as_salary: data?.selectedData?.is_pay_as_salary
      ? data?.selectedData?.is_pay_as_salary
      : 0,
    is_ignore_allowances: data?.selectedData?.is_ignore_allowances
      ? data?.selectedData?.is_ignore_allowances
      : 0,
    isSave: data?.selectedData?.id ? 0 : 1,
    id: data?.selectedData?.id ? data?.selectedData?.id : "",
    errors: {
      name: "",
    },
    isLoading: false,
  };

  const [employee, setEmployee] = useState(initialvalues);

  let name = useRef();

  const handleEmployee = {
    get: (e) => {
      setEmployee({
        ...employee,
        [e?.target?.name]: e?.target?.value,
      });
    },
    check: (e) => {
      setEmployee({
        ...employee,
        [e?.target?.name]: Number(e?.target?.checked),
      });
    },
    submit: async () => {
      if (!employee?.name) {
        setEmployee({
          ...employee,
          errors: {
            name: "Enter name",
          },
        });
        name?.current?.focus();
      } else {
        setEmployee({
          ...employee,
          isLoading: true,
        });
        let getdata = await CreateUpdateEmployeementStatus(employee);
        if (getdata?.status) {
          if (employee?.id) {
            let getallData = [...data?.data];
            getallData[data?.selectedData?.index] = { ...getdata?.data?.data };
            setData({
              ...data,
              data: getallData,
              selectedData: {},
            });
            setEmployee(initialvalues);
            setCreateEmployementStatus(false);
          } else {
            setcreateAdminContext(true);
            setEmployee(initialvalues);
            setCreateEmployementStatus(false);
          }
        } else {
          setEmployee({
            ...employee,
            isLoading: false,
          });
        }
      }
    },
  };

  useEffect(() => {
    setTimeout(() => {
      name?.current?.focus();
    }, 100);
  }, []);

  const closeModal = () => {
    setCreateEmployementStatus(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">Creating Employment Status</h2>

          <EmployeStatusModalForm
            data={employee}
            setData={setEmployee}
            handle={handleEmployee}
            name={name}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEmployementStatusModal;
