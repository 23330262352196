import { TreeSelect } from "antd";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const path = {
  worksite: "/admin/worksite",
  workers: "/admin/personnel/workers/1/1/1/1",
  "site-manager": "/admin/personnel/site-managers",
  summary: "/admin/summary",
  timesheet: "/admin/timesheets",
  productivity: "/admin/productivity",
  notes: "/admin/notes",
};

const treeData = [
  {
    value: "worksite",
    title: "Worksite",
  },
  {
    value: "personnel",
    title: "Personnel",
    children: [
      {
        value: "workers",
        title: "Workers",
      },
      {
        value: "site-manager",
        title: "Site Manager",
      },
    ],
  },

  {
    value: "Payroll",
    title: "Payroll",
    children: [
      {
        value: "summary",
        title: "Summary",
      },
      {
        value: "timesheet",
        title: "Timesheet",
      },
      {
        value: "productivity",
        title: "Productivity",
      },
      {
        value: "notes",
        title: "Notes",
      },
    ],
  },
];
const TopSearch = ({ setVisibleSearch }) => {
  const [value, setValue] = useState(undefined);
  const navigate = useNavigate();

  const onChange = (value) => {
    navigate(path[value]);
    //
    // setValue(newValue);
  };

  const onClose = () => {
    setVisibleSearch(false);
  };
  return (
    <div className="flex bg-white rounded-md">
      <TreeSelect
        showSearch
        className="border-none"
        style={{
          width: "100%",
          border: "none",
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: "auto",
        }}
        placeholder="Enter the Needed Search Term here"
        allowClear
        treeDefaultExpandAll
        onChange={onChange}
        treeData={treeData}
      />
      <button onClick={onClose}>
        <FaTimes className="mx-3 text-black" />
      </button>
    </div>
  );
};
export default TopSearch;
