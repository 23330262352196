import React, { useState, useRef, useEffect } from "react";
import { ButtonSpinner } from "../../../../components/spinners";
import { PayRollService, SettingService } from "../../../../config/axiosUrl";
import { SearchData } from "../../../../helper/global/global";
import {
  DeletePayrollCategory,
  GetPayrollCatagory,
} from "../../../../helper/setup/payroll/payrollcatagory";
import {
  CreateEditWorksite,
  DeleteWorksitePayroll,
  GetWorkSite,
  GetWorkSiteById,
} from "../../../../helper/worksite/worksite";
import WorksiteForm from "../../../../shared/WorksiteForm/WorksiteForm";
import PayrollCategories from "../../Settings/Payroll/Categories/Categories";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import moment from "moment";

const CreateNewWorksite = () => {
  let navigate = useNavigate();
  let { id } = useParams();

  const initialvalues = {
    id: id ? id : "",
    isSave: id ? 0 : 1,
    companyOptions: [],
    selectedCompany: {},
    payrollCategoryOptions: [],
    slectedCatefor: {},
    company_id: "",
    job_no: "",
    name: "",
    address: "",
    builder: "",
    builder_contact_email: "",
    project_cost: "",
    work_status: "",
    site_manager_id: "",
    start_date: null,
    finish_date: null,
    standard_start_time: null,
    standard_end_weekend_time: null,
    standard_end_friday_time: null,
    standard_end_weekday_time: null,
    upper_control: "",
    lower_control: "",
    productivity_payroll_category: "",
    productivity_surplus_rate: "",
    payroll_category_ids: [],
    selected_payroll_category: [],
    modal_selected_data: {},
    isModal: false,
    isModalLoading: false,
    modalTitle: "",
    errors: {
      company_id: "",
      job_no: "",
      name: "",
      address: "",
      builder: "",
      builder_contact_email: "",
      project_cost: "",
      // work_status: "",
      // site_manager_id: "",
      start_date: "",
      finish_date: "",
      standard_start_time: "",
      standard_end_weekend_time: "",
      standard_end_friday_time: "",
      standard_end_weekday_time: "",
      upper_control: "",
      lower_control: "",
      productivity_payroll_category: "",
      productivity_surplus_rate: "",
      // payroll_category_ids: "",
    },
    isLoading: false,
    isShimmer: id ? true : false,
  };

  const [worksite, setWorksite] = useState(initialvalues);
  const [files, setFiles] = useState([]);

  let errorKeys = Reflect.ownKeys(initialvalues?.errors);

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Payroll Categories",
    searchBarTitle: "Search by payroll categories",
    buttonTitle: "Add",
  };

  let errors = useRef([]);
  errorKeys?.map((value, index) => (errors.current[value] = React.createRef()));
  const [multySelect, setMultySelect] = useState(initialMultySelectValue);

  const handleWorksite = {
    get: (e) => {
      setWorksite({
        ...worksite,
        [e?.target?.name]: e?.target?.value,
      });
    },
    select: (select, name) => {
      if (select != "isLoading") {
        setWorksite({
          ...worksite,
          [name]: select,
        });
      }
    },
    getPayrollCatagory: async (e) => {
      if (!e?.target?.value) {
        setMultySelect({
          ...multySelect,
          isShimmer: true,
          isMultySelect: true,
        });
      }

      const params = {
        value: e?.target?.value,
        api: "/api/v1/payroll/setting/get-payroll-category-data",
        service: PayRollService,
      };

      let data = await SearchData(params);
      if (data?.status) {
        setMultySelect({
          ...multySelect,
          options: [...data?.data?.data],
          isShimmer: false,
          isMultySelect: true,
        });
      }
    },
    addPayrollCategories: () => {
      // worksite.selected_payroll_category.filter((item) => )

      setMultySelect({
        ...multySelect,
        isMultySelect: false,
      });
    },
    multySelect: (select) => {
      setWorksite({
        ...worksite,
        payroll_category_ids: select,
      });
    },
    openModal: (data) => {
      setWorksite({
        ...worksite,
        modal_selected_data: data,
        modalTitle: "Are You Sure You Wish To Delete This Payroll Category",
        isModal: true,
      });
    },
    openImgModal: (data) => {
      setWorksite({
        ...worksite,
        modal_selected_data: data,
        modalTitle: "Are You Sure You Wish To Delete This Worksite Image",
        isModal: true,
      });
    },
    closeModal: () => {
      setWorksite({
        ...worksite,
        modal_selected_data: {},
        isModal: false,
      });
    },
    modalAction: async () => {
      setWorksite({
        ...worksite,
        isModalLoading: true,
      });

      if (worksite?.modal_selected_data?.id) {
        let data = await DeleteWorksitePayroll(worksite?.modal_selected_data);

        if (data?.status) {
          let allSelPayroll = [...worksite?.selected_payroll_category];
          allSelPayroll?.splice(worksite?.modal_selected_data?.index, 1);

          let allModalSelectedModal = [...multySelect?.selectedOption];
          let delSel = allModalSelectedModal?.indexOf(
            worksite?.modal_selected_data?.payroll_id
          );

          allModalSelectedModal?.splice(delSel, 1);

          setMultySelect({
            ...multySelect,
            selectedOption: allModalSelectedModal,
          });

          setWorksite({
            ...worksite,
            isModal: false,
            modal_selected_data: {},
            selected_payroll_category: allSelPayroll,
            isModalLoading: false,
          });
          toast?.success(data?.message);
        } else {
          setWorksite({
            ...worksite,
            isModalLoading: false,
          });
          toast?.error(data?.message);
        }
      } else {
        setMultySelect((prev) => {
          const selectedOption = prev.selectedOption;
          selectedOption.splice(
            selectedOption.indexOf(worksite?.modal_selected_data?.payroll_id),
            1
          );
          return {
            ...prev,
            selectedOption,
          };
        });

        setWorksite((prev) => {
          const selected_payroll_category = prev.selected_payroll_category;

          selected_payroll_category.splice(
            selected_payroll_category.findIndex(
              (item) => item.id === worksite?.modal_selected_data?.payroll_id
            ),
            1
          );

          return {
            ...worksite,
            isModalLoading: false,
            selected_payroll_category,
            isModal: false,
            modal_selected_data: {},
          };
        });
      }
    },
    getCompany: async (e) => {
      let allCompany = [...worksite?.companyOptions];

      let allCompID = [];
      worksite?.companyOptions?.map((compId, index) => {
        allCompID?.push(compId?.value);
      });

      let loadingOptions = [{ value: "isLoading", label: <ButtonSpinner /> }];
      setWorksite({
        ...worksite,
        companyOptions: loadingOptions,
      });

      const params = {
        value: e,
        api: "/api/v1/setting/company/get-company-data",
        service: SettingService,
      };

      let data = await SearchData(params);

      if (data?.status) {
        data?.data?.data?.map(
          (company, index) =>
            !allCompID?.includes(company?.id) &&
            allCompany?.push({ value: company?.id, label: company?.name })
        );

        setWorksite({
          ...worksite,
          companyOptions: allCompany,
        });
      } else {
        setWorksite({
          ...worksite,
          companyOptions: [],
        });
      }
    },
    submit: async () => {
      let errorCount = 0;
      errorKeys?.map((value, index) => {
        if (errorCount == index) {
          if (!worksite?.[value]) {
            setWorksite({
              ...worksite,
              errors: {
                ...initialvalues?.errors,
                [value]: `Enter ${value?.replace(/_|id/g, " ")} `,
              },
            });
            errors?.current?.[value]?.current?.focus();
          } else {
            setWorksite({
              ...worksite,
              errors: {
                ...initialvalues?.errors,
              },
            });
            errorCount++;
            if (errorCount == errorKeys?.length) {
              handleWorksite?.create();
            }
          }
        }
      });
    },
    create: async () => {
      setWorksite({
        ...worksite,
        isLoading: true,
      });
      let data = await CreateEditWorksite({
        ...worksite,
        picture: files[0],
        payroll_category_ids: multySelect?.selectedOption,
      });
      if (data?.status) {
        toast?.success(data?.message);
        setWorksite(initialvalues);
        navigate(-1);
      } else {
        setWorksite({
          ...worksite,
          isLoading: false,
        });
        toast?.error(data?.message);
      }
    },
    getWorksiteById: async () => {
      const params = {
        id: id,
      };
      let data = await GetWorkSiteById(params);

      if (data?.status) {
        let payrollOpt = data?.data?.data?.worksite_payroll_categories.map(
          (item) => ({
            id: item.payroll_category.id,
            name: item.payroll_category.name,
            parent: item.id,
          })
        );

        let selPayrollCat = [];
        data?.data?.data?.worksite_payroll_categories?.map((payroll, index) => {
          selPayrollCat?.push(payroll?.payroll_category?.id);
        });

        setWorksite({
          ...worksite,
          isShimmer: false,
        });

        setWorksite({
          ...worksite,
          ...data?.data?.data?.worksite_timelines?.[0],
          ...data?.data?.data?.worksite_payrolls?.[0],
          ...data?.data?.data,
          companyOptions: [
            {
              value: data?.data?.data?.companies?.id,
              label: data?.data?.data?.companies?.name,
            },
          ],
          selected_payroll_category: payrollOpt,
          start_date: moment(
            data?.data?.data?.worksite_timelines?.[0]?.start_date
          ).format("YYYY-MM-DD"),
          finish_date: moment(
            data?.data?.data?.worksite_timelines?.[0]?.finish_date
          ).format("YYYY-MM-DD"),
          isShimmer: false,
        });

        setMultySelect({
          ...multySelect,
          selectedOption: selPayrollCat,
        });
        return data;
      }
    },
  };

  useEffect(() => {
    if (id) {
      (async () => {
        let data = await handleWorksite?.getWorksiteById();
      })();
    }
  }, []);

  return (
    <div className="p-10">
      {worksite?.isShimmer ? (
        <div className="lg:w-1/2 w-full mx-auto bg-white p-5 shadow-sm">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : (
        <WorksiteForm
          data={worksite}
          setData={setWorksite}
          handle={handleWorksite}
          errors={errors}
          files={files}
          setFiles={setFiles}
          multySelect={multySelect}
          setMultySelect={setMultySelect}
        />
      )}
    </div>
  );
};

export default CreateNewWorksite;
