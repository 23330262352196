import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { FaSearch } from "react-icons/fa";
import { PayRollService, UserService } from "../../../../config/axiosUrl";

const ExpensesFrontModal = ({
  visible,
  onCancel,
  onSave,
  emplist,
  selectedWorkers,
}) => {
  const modalTitle = "Available Workers";
  const [workers, setWorkers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };
  const apiFunctions = {
    getWorkers: () => {
      UserService.get(
        `/api/v1/personal/workers/get-lists?type=1&search=${searchValue}`
      ).then((res) => {
        setWorkers(res?.data?.data?.data);
      });
    },
  };

  useEffect(() => {
    apiFunctions.getWorkers();
  }, [searchValue]);
  useEffect(() => {
    // Use selectedWorkers prop to initialize state
    setWorkers((prev) =>
      prev.map((w) =>
        selectedWorkers.includes(w.id) ? { ...w, selected: true } : w
      )
    );
  }, [selectedWorkers]);

  const handleSaveClick = () => {
    const selectedWorkers = workers.filter((worker) => worker.selected);
    const selectedWorkerIds = selectedWorkers.map((worker) => worker.id);

    // Save the selected state locally
    setWorkers((prev) =>
      prev.map((w) =>
        selectedWorkerIds.includes(w.id) ? { ...w, selected: true } : w
      )
    );

    onCancel();

    let params = {
      ids: selectedWorkerIds,
    };
    PayRollService.post(`/api/v1/payroll/setting/assign-employees`, params)
      .then((res) => {
        emplist();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div className="border border-[#111111] flex items-center p-1 rounded-md w-full my-2 bg-white">
        <FaSearch className="ml-2" />
        <input
          placeholder="Search by worker name"
          value={searchValue}
          onChange={handleSearch}
          className="h-[31px] bg-transparent text-sm w-full px-2"
        />
      </div>

      <div>
        <div className="item mt-3 border-b-2 border-[#F0F0F0]">
          {workers?.map((item, index) => (
            <div className="flex justify-between items-center mt-4" key={index}>
              <div>{item.name}</div>
              <div>
                <input
                  type="checkbox"
                  id={`workerCheckbox${index}`}
                  name="Export to Summary"
                  onChange={() =>
                    setWorkers((prev) =>
                      prev.map((w, i) => {
                        if (i === index) {
                          return { ...w, selected: !w.selected };
                        }
                        return w;
                      })
                    )
                  }
                />
                <label
                  htmlFor={`workerCheckbox${index}`}
                  className="ml-2"
                ></label>
              </div>
            </div>
          ))}
        </div>
        <div className="item mt-5 text-center">
          <Button className="btn btn-blue" onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExpensesFrontModal;
