import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaInfoCircle,
  FaPlus,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";
import DeleteInspection from "../Modal/DeleteInspection";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateEditInspection,
  GetAreaById,
  GetInspection,
  InspectionDelete,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { Button, Popover, Select, Tooltip } from "antd";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../components/spinners";
import NotesIcon from "../../assets/svg/notesIcon";
import { TextShimmer } from "../../components/shimmer/shimmer";
import AreasDefaultSvg from "../../assets/svg/areasDefault";
import { NoSearchTableSvg } from "../../assets/svg/tableDefaul";
import { NoTableOption } from "../../components/noOption/noOption";
import EditInspectionWorksite from "./editInspectionWorksite";
import NotesModal from "./notesModal";
import Webcam from "react-webcam";
import {
  changeStatus,
  deleteImageUpload,
} from "../../helper/worksite/worksite";
import CameraICon from "../../assets/svg/cameraIcon";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { WorkService } from "../../config/axiosUrl";
import ImageSliderModal from "./imageSliderModal";
import GreenCameraIcon from "../../assets/svg/greencameraIcon";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";

const InspectionTableWorkSite = ({
  setVisibleInspection,
  selectedTask,
  inspection,
  setInspection,
}) => {
  const { width } = useWindowDimensions;
  const initialInspection = {
    data: [],
    extra: {},
    allCheck: false,
    selectedData: {
      id: "",
      inspection: "",
      description: "",
      isSave: "",
      errors: {
        inspection: "",
        description: "",
      },
      isLoading: false,
    },
    search: "",
    isShimmer: true,
  };
  const { id, area, taskid, type } = useParams();
  let navigate = useNavigate();
  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const initialTasks = {
    data: [],
    options: [],
    selectedTask: "",
  };

  let ref = {
    inspection: "",
    description: "",
  };

  const [tasks, setTasks] = useState(initialTasks);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [IsOpenNotesModal, setIsOpenNotesModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [animatePulse, setAnimatePulse] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [imageModal, setOpenImageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedImage, setSelectedImg] = useState();
  let formref = useRef([]);

  Reflect.ownKeys(ref)?.map((value, index) => {
    formref.current[value] = React?.createRef();
  });
  const capture = React.useCallback(
    async (item) => {
      const imageSrc = await webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
      uploadCameraPhoto(imageSrc, item);
    },
    [webcamRef, setImgSrc]
  );

  const uploadCameraPhoto = async (image, item) => {
    let formData = new FormData();
    let splitImage = image?.split("data:image/jpeg;base64,");

    try {
      const binaryData = atob(splitImage[1]);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "image/jpeg" });
      formData.append("file", blob, "image.jpg");
      formData?.append("id", item?.id);
    } catch (error) {
      console.error("Error sending image to the API:", error);
    }
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let res = await WorkService?.post(
        "/api/v1/worksite/inspection/upload",
        formData,
        authorization
      );
      if (res?.status) {
        toast?.success("file upload successfully");
        setCameraOpen(false);
        const params = {
          cursor: "",
          skip: "",
          search: "",
          type: type,
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
        };
        await handleInspection?.get(params);
      }
    } catch (error) {
      return error;
    }
  };
  const deleteImage = async (image) => {
    let data = await deleteImageUpload(image, selectedItem);
    if (data?.status) {
      closeConfirmDeleteModaL();
      toast?.success(
        data?.message ? data?.message : "File Deleted Successfully."
      );
      setSelectedItem(data?.data?.data[0]);
      const params = {
        cursor: "",
        skip: "",
        search: "",
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };
      await handleInspection?.get(params);
    }
  };

  const visibleEditModal = (item, index) => {
    setVisibleEdit(true);
    setInspection({
      ...inspection,
      selectedData: item,
    });
  };
  const visibleDeleteModal = (item, index) => {
    setVisibleDelete(true);
    setInspection({
      ...inspection,
      selectedData: item,
    });
  };
  const handleInspection = {
    back: () => {
      setVisibleInspection(false);
      navigate(`/site-manager/worksite/details/task/${id}/${area}/1`);
    },
    taskSearch: async (e) => {
      const params = {
        search: e,
      };

      let data = await handleInspection?.getTasks(params);
    },
    getTasks: async (taskData) => {
      let defaultOption = [{ value: "loading", label: <ButtonSpinner /> }];

      setTasks({
        ...tasks,
        data: [],
        options: defaultOption,
      });

      const params = {
        id: area,
        worksite_id: id,
        search: taskData?.search,
      };
      let data = await GetAreaById(params);

      let allData = [];
      let allOptions = [];

      data?.data?.data?.hourly_rate_tasks_estimations?.map((task, tindex) => {
        allData?.push({
          ...task,
          type: "hourly",
          id: task?.id,
          abbreviation: task?.hourly_rate_task?.abbreviation,
          name: task?.hourly_rate_task?.name,
        });

        allOptions?.push({
          value: task?.id,
          label:
            task?.hourly_rate_task?.abbreviation +
            " " +
            task?.hourly_rate_task?.name,
        });
      });

      data?.data?.data?.productivity_orders?.map((task, tindex) => {
        allData?.push({
          ...task,
          type: "po",
          id: task?.id,
          abbreviation: task?.code,
          name: task?.name,
        });

        allOptions?.push({
          value: task?.id,
          label: task?.code + " " + task?.name,
        });
      });

      if (data?.status) {
        setTasks({
          ...tasks,
          data: allData,
          options: allOptions,
        });
      }
    },
    selectTask: async (selId) => {
      let allTask = [];
      tasks?.data?.map((task, tindex) => {
        allTask?.push(task?.id);
      });

      navigate(
        `/admin/worksite/inspection/${id}/${area}/${selId}/${
          tasks?.data?.[allTask?.indexOf(selId)]?.type
        }`
      );
    },
    search: async (e) => {
      const params = {
        search: e?.target?.value,
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };
      await handleInspection?.get(params);
    },
    get: async (taskData) => {
      const params = {
        cursor: taskData?.cursor,
        skip: taskData?.skip,
        search: taskData?.search,
        type: taskData?.type,
        productivity_orders_id: taskData?.productivity_orders_id,
        hourly_rate_tasks_estimations_id:
          taskData?.hourly_rate_tasks_estimations_id,
      };

      let data = await GetInspection(params);
      if (data?.status) {
        setInspection({
          ...inspection,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          search: taskData?.search,
          isShimmer: false,
        });
        return data;
      } else {
        return data;
      }
    },
    add: (task, index) => {
      setVisibleEdit(true);
      setInspection({
        ...inspection,
        selectedData: {
          ...inspection?.selectedData,
          id: task?.id,
          isSave: task?.id ? 0 : 1,
          inspection: task?.inspection,
          description: task?.description,
        },
      });
    },
    getForm: (e) => {
      setInspection({
        ...inspection,
        selectedData: {
          ...inspection?.selectedData,
          [e?.target?.name]: e?.target?.value,
        },
      });
    },
    submit: async () => {
      if (!inspection?.selectedData?.inspection) {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            errors: {
              ...initialInspection?.selectedData?.errors,
              inspection: "Enter inspection",
            },
          },
        });
        formref?.current["inspection"]?.current?.focus();
      } else if (!inspection?.selectedData?.description) {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            errors: {
              ...initialInspection?.selectedData?.errors,
              description: "Enter description",
            },
          },
        });
        formref?.current["description"]?.current?.focus();
      } else {
        setInspection({
          ...inspection,
          selectedData: {
            ...inspection?.selectedData,
            isLoading: true,
            errors: {
              ...initialInspection?.selectedData?.errors,
            },
          },
        });

        const params = {
          worksite_id: id,
          area_id: area,
          inspection: inspection?.selectedData?.inspection,
          description: inspection?.selectedData?.description,
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
          isSave: inspection?.selectedData?.isSave
            ? inspection?.selectedData?.isSave
            : 0,
          id: inspection?.selectedData?.id,
        };

        let data = await CreateEditInspection(params);
        if (data?.status) {
          toast?.success(
            inspection?.selectedData?.isSave
              ? "Inspection created SuccessFully"
              : "Inspection updated SuccessFully"
          );

          const params = {
            productivity_orders_id: type == "po" && taskid,
            hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
            type: type,
          };
          let data = await handleInspection?.get(params);
          if (data?.status) {
            setInspection({
              ...inspection,
              data: [...data?.data?.data],
              extra: { ...data?.extra },
              selectedData: {
                isLoading: false,
              },
            });
            setVisibleEdit(false);
          }
        } else {
          setInspection({
            ...inspection,
            selectedData: {
              ...inspection?.selectedData,
              isLoading: false,
              errors: {
                ...initialInspection?.selectedData?.errors,
              },
            },
          });
          toast?.error(data?.message);
        }
      }
    },
    fetch: async (e) => {
      if (
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight
      ) {
        const params = {
          cursor: inspection?.extra?.cursor,
          type: type,
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
        };

        let data = await handleInspection?.get(params);

        if (data?.status) {
          let alldata = [...inspection?.data];
          if (data?.data?.data?.length > 0) {
            data?.data?.data?.map((site, index) => {
              alldata?.push({ ...site });
            });
            setInspection({
              ...inspection,
              data: alldata,
              extra: data?.extra,
            });
          } else {
            setInspection({
              ...inspection,
            });
          }
        } else {
          setInspection({
            ...inspection,
          });
        }
      }
    },
    delete: async () => {
      setInspection({
        ...inspection,
        selectedData: {
          isLoading: true,
          ...inspection?.selectedData,
        },
      });
      let data = await InspectionDelete(inspection?.selectedData);
      if (data?.status) {
        toast?.success("Deleted Successfully");
        const params = {
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
          type: type,
        };
        let data = await handleInspection?.get(params);
        if (data?.status) {
          setInspection({
            ...inspection,
            data: [...data?.data?.data],
            extra: { ...data?.extra },
            selectedData: {
              isLoading: false,
            },
          });
          setVisibleDelete(false);
        }
      }
    },
    check: async (e, id) => {
      const { checked } = e.target;
      let allData = inspection?.data?.length > 0 ? [...inspection?.data] : [];

      let allIds = [];
      inspection?.data?.forEach((inspect, index) => {
        allIds?.push(inspect?.id);
      });

      if (e?.target?.checked) {
        allData[allIds?.indexOf(id)].checked = 1;

        const isAllChildsChecked = inspection?.data?.every(
          (user) => user.checked === 1
        );
        if (isAllChildsChecked) {
          setisChecked(checked);
        } else {
          setisChecked(false);
        }
        setInspection({
          ...inspection,
          data: allData,
          allCheck: isAllChildsChecked ? true : false,
        });
        let checkId = [];
        allData?.map((item) => {
          if (item?.checked == 1) {
            checkId.push(item?.id);
          }
        });
        setCheckedList(checkId);
      } else {
        allData[allIds?.indexOf(id)].checked = 0;
        const isAllChildsChecked = inspection?.data?.every(
          (user) => user.checked === 1
        );
        if (isAllChildsChecked) {
          setisChecked(checked);
        } else {
          setisChecked(false);
        }
        setInspection({
          ...inspection,
          data: allData,
          allCheck: isAllChildsChecked ? true : false,
        });
        setCheckedList([id]);
      }
    },
    checkAll: async (e) => {
      let allIds = [];
      inspection?.data?.forEach((inspect, index) => {
        allIds?.push(inspect?.id);
      });

      if (e?.target?.checked) {
        let allChecks = [];
        inspection?.data?.forEach((inspect, index) => {
          allChecks?.push({ ...inspect, checked: 1 });
        });

        setInspection({
          ...inspection,
          data: allChecks,
          allCheck: true,
        });
        setCheckedList(allIds);
      } else {
        let allChecks = [];
        inspection?.data?.forEach((inspect, index) => {
          allChecks?.push({ ...inspect, checked: 0 });
        });

        setInspection({
          ...inspection,
          data: allChecks,
          allCheck: false,
        });
        setCheckedList(allIds);
      }
    },
  };
  const handleChangeStatus = async (e) => {
    let res = await changeStatus(e, checkedList);
    if (res?.status) {
      // setisChecked(false)
      setInspection({
        ...inspection,
        allCheck: false,
      });
      toast?.success("Status change successfully");
      const params = {
        cursor: "",
        skip: "",
        search: "",
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };
      await handleInspection?.get(params);
    }
  };
  const openNotesModal = (item, index) => {
    setIsOpenNotesModal(true);
    setSelectedItem(item);
  };
  const closeNotesModal = () => {
    setIsOpenNotesModal(false);
  };
  useEffect(() => {
    (async () => {
      const params = {
        cursor: "",
        skip: "",
        search: "",
        type: type,
        productivity_orders_id: type == "po" && taskid,
        hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
      };
      await handleInspection?.get(params);
    })();
  }, []);

  const viewImg = () => {
    setOpenImageModal(true);
  };
  const closeImageModal = () => {
    setOpenImageModal(false);
  };
  const confirmDeleteModal = () => {
    setConfirmModal(true);
  };
  const closeConfirmDeleteModaL = () => {
    setConfirmModal(false);
  };
  const fromGallry = async (e) => {
    let formData = new FormData();
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    formData?.append("id", selectedItem?.id);
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let res = await WorkService?.post(
        "/api/v1/worksite/inspection/upload",
        formData,
        authorization
      );

      if (res?.status) {
        toast?.success("file upload successfully");
        const params = {
          cursor: "",
          skip: "",
          search: "",
          type: type,
          productivity_orders_id: type == "po" && taskid,
          hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
        };
        await handleInspection?.get(params);
      }
    } catch (error) {
      return error;
    }
  };
  const ImgContent = (
    <div>
      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={takePhoto}>Take Photo</p> */}
      <p className="px-4 py-2 cursor-pointer border-b-2">
        <input
          type="file"
          id="f1"
          multiple
          className="mt-3 h-12 border rounded p-2 w-full hidden"
          onChange={fromGallry}
          name="picture"
          accept="image/*,capture=camera"
        />
        <label htmlFor="f1" className="cursor-pointer">
          Upload Files
        </label>
      </p>
      <p className="px-4 py-2 cursor-pointer" onClick={viewImg}>
        View Image
      </p>
      {imageModal && (
        <ImageSliderModal
          onClose={closeImageModal}
          selectedItem={selectedItem}
          deleteImage={deleteImage}
          setConfirmModal={setConfirmModal}
          confirmModal={confirmModal}
          confirmDeleteModal={confirmDeleteModal}
          setSelectedImg={setSelectedImg}
          handle={handleInspection}
        />
      )}
      {confirmModal && (
        <div className="modal confirm-img">
          <div className="modal-content">
            <div className="text-right">
              <button className="close-modal" onClick={closeConfirmDeleteModaL}>
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-8">
                Do you want to delete this Image?
              </h2>
            </div>
            <div className="flex justify-center">
              <Button
                key="save"
                onClick={() => deleteImage(selectedImage)}
                className="btn btn-blue bg-blue-disable mr-2"
              >
                Yes{" "}
              </Button>
              <Button
                onClick={closeConfirmDeleteModaL}
                className="btn bg-blue-disable"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  const nullContent = (
    <div>
      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={takePhoto}>Take Photo</p> */}
      <p className="px-4 py-2 cursor-pointer border-b-2">
        <input
          type="file"
          id="f1"
          multiple
          className="mt-3 h-12 border rounded p-2 w-full hidden"
          onChange={fromGallry}
          name="picture"
          accept="image/*,capture=camera"
        />
        <label htmlFor="f1" className="cursor-pointer ">
          Upload Files
        </label>
      </p>
    </div>
  );
  let inspectionCompleted =
    inspection?.data?.length > 0
      ? inspection?.data?.filter((item) => item.status === 1)
      : [];
  return (
    <>
      {visibleEdit && (
        <EditInspectionWorksite
          setVisibleEdit={setVisibleEdit}
          inspection={inspection}
          setInspection={setInspection}
          handle={handleInspection}
          formref={formref}
        />
      )}
      {visibleDelete && (
        <DeleteInspection
          setVisibleDelete={setVisibleDelete}
          inspection={inspection}
          setInspection={setInspection}
          handle={handleInspection}
          formref={formref}
        />
      )}

      <div className="bg-white">
        <div className={"flex pb-4 items-center justify-between"}>
          <div className="flex">
            <button onClick={handleInspection?.back}>
              <FaArrowLeft
                className={
                  width > 767
                    ? "text-[#000] mr-3"
                    : "text-[#000] mr-3 smallText ml-2  "
                }
              />
            </button>
            <div className={width > 767 ? "" : "smallText mt-2"}>
              Common Fire Walls
            </div>
            <span
              className={
                width > 767
                  ? "text-[#000] mr-3"
                  : "text-[#000] mr-1 smallText ml-2 mt-3"
              }
            >
              <FaArrowRight />
            </span>
            <div>
              <p className={width > 767 ? "" : "smallText  mr-3 mt-2"}>
                {selectedTask?.task_name}
              </p>
              {/* <Select
              showSearch
              placeholder="Select Tasks"
              optionFilterProp="children"
              className="text-sm"
              style={{ width: 300 }}
              bordered={false}
              name="task"
              size="large"
              filterOption={false}
              onFocus={handleInspection?.getTasks}
              options={tasks?.options}
              onChange={handleInspection?.selectTask}
              onSearch={handleInspection?.taskSearch}
            /> */}
            </div>
          </div>
        </div>
        <div className="flex ">
          <Select
            size="small"
            placeholder="Inspection Status"
            onChange={(e) => handleChangeStatus(e)}
            options={[
              { value: 1, label: "Mark Completed" },
              { value: 0, label: "Mark Incompleted" },
              { value: 3, label: "Omit from QA" },
            ]}
          />
          {IsOpenNotesModal && (
            <NotesModal closeNotesModal={closeNotesModal} item={selectedItem} />
          )}
          <Button
            onClick={handleInspection?.add}
            className={
              width > 767
                ? "flex justify-center items-center py-3 border border-[#111111] h-[42px] ml-2 "
                : "flex justify-center items-center py-1 border border-[#111111] h-[32px] fs-10 mt-1 ml-3"
            }
          >
            Add New Inspection
            <div
              className={
                width > 767
                  ? "bg-orange-500 p-2 rounded-md text-white ml-3"
                  : "bg-orange-500 p-1 rounded-md text-white ml-1"
              }
            >
              <FaPlus />
            </div>
          </Button>
        </div>
        <div className=" " style={{ overflowX: "auto" }}>
          <div className="bg-white">
            {inspection?.isShimmer ? (
              <TextShimmer data={{ gap: 15, line: 20 }} />
            ) : (
              <Fragment>
                {inspection?.data?.length <= 0 && !inspection?.data?.search ? (
                  <div className="py-8 h-[35vh]">
                    <div className="h-full flex justify-center items-center flex-col bg-white">
                      <div className="py-2">
                        <AreasDefaultSvg />
                      </div>
                      <div className="">
                        No data available for displaying Inspection
                      </div>
                      <div className="text-gray-300 text-base py-2">
                        The inspection needs to have areas added.
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className="text-gray-400 text-sm float-right p-2 ">
                      <span className="text-black">
                        {inspectionCompleted.length}
                      </span>{" "}
                      Out of &nbsp;
                      <span className="text-black">
                        {inspection?.data?.length}
                      </span>{" "}
                      Inspection Completed
                    </div>
                    {inspection?.data?.length <= 0 &&
                    inspection?.data?.search ? (
                      <NoTableOption data={noSearchOption} />
                    ) : (
                      <Fragment>
                        <table className="w-full">
                          <thead className="bg-[#E8F3FF] text-center">
                            <tr className="text-left">
                              <th className=" px-4 py-3 w-[100px]">
                                <div className="flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    className="ml-2"
                                    checked={isChecked || inspection?.allCheck}
                                    onChange={handleInspection?.checkAll}
                                    disabled={animatePulse}
                                  />
                                </div>
                              </th>
                              <th className=" px-4 py-3 w-[200px]">
                                <div className="flex items-center justify-start">
                                  Inspection
                                </div>
                              </th>
                              <th className=" px-4 py-3 w-[200px]">
                                <div className="flex items-center justify-center">
                                  Image
                                </div>
                              </th>
                              <th className=" px-4 py-3 w-[200px]">
                                <div className="flex items-center justify-center">
                                  Status
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Actions
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          style={{ height: "400px", overflow: "auto" }}
                          onScroll={handleInspection?.fetch}
                          className="scroll-display-none "
                        >
                          <table className="w-full">
                            <tbody className="bg-white">
                              {inspection?.data?.map((item, index) => (
                                <tr
                                  key={index}
                                  className="border-b border-[#F0F0F0]"
                                >
                                  <td className="px-4 py-3 w-[100px]">
                                    <div className="flex items-center justify-center">
                                      <input
                                        type="checkbox"
                                        checked={Boolean(item?.checked)}
                                        onChange={(e) =>
                                          handleInspection?.check(e, item?.id)
                                        }
                                        disabled={animatePulse}
                                      />
                                    </div>
                                  </td>
                                  <td className="px-4 py-3 w-[200px]">
                                    <div className="flex items-center">
                                      <div>{item?.inspection}</div>
                                      <div>
                                        <Tooltip
                                          placement="rightBottom"
                                          title={item?.description}
                                        >
                                          <FaInfoCircle className="ml-2 text-md" />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-4 py-3 w-[200px] cursor-pointer">
                                    <div
                                      className="flex items-center justify-center"
                                      onClick={() => setSelectedItem(item)}
                                    >
                                      {item?.file?.length > 0 ? (
                                        <Popover
                                          placement="left"
                                          content={ImgContent}
                                          trigger={"click"}
                                        >
                                          <span>
                                            <GreenCameraIcon />
                                          </span>
                                        </Popover>
                                      ) : (
                                        <Popover
                                          placement="left"
                                          content={nullContent}
                                          trigger={"click"}
                                        >
                                          <span>
                                            <CameraICon />
                                          </span>
                                        </Popover>
                                      )}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3 w-[200px]">
                                    <div className="flex items-center justify-center">
                                      {item?.status == 3
                                        ? "Omit from QA"
                                        : item?.status == 1
                                        ? "Completed"
                                        : "Incompleted"}
                                    </div>
                                  </td>

                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center cursor-pointer">
                                      <button
                                        className="mx-2"
                                        onClick={() =>
                                          openNotesModal(item, index)
                                        }
                                      >
                                        <NotesIcon />
                                      </button>
                                      <button
                                        className="mx-2"
                                        onClick={() =>
                                          visibleEditModal(item, index)
                                        }
                                      >
                                        <FaEdit />
                                      </button>
                                      <button
                                        className="mx-2"
                                        onClick={() => {
                                          visibleDeleteModal(item, index);
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            {cameraOpen && (
              <>
                <Webcam
                  audio={false}
                  screenshotFormat="image/jpeg"
                  ref={webcamRef}
                />
                <button onClick={() => capture(selectedItem)}>
                  Capture photo
                </button>
                {imgSrc && <img src={imgSrc} />}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InspectionTableWorkSite;
