import { Card, Collapse, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Input from "antd/es/input/Input";
import { HiOutlineSearch } from "react-icons/hi";
import { Button } from "antd";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { GetWorkSite } from "../../../helper/worksite/worksite";
import { useState, useCallback, useEffect } from "react";
import { PayRollService } from "../../../config/axiosUrl";
import * as XLSX from "xlsx";
const { Panel } = Collapse;

const Productivity = () => {
  const [filter, setFilter] = useState({
    worksites: [],
    payment: [],
    allWorksiteLoaded: false,
    selectedWorksite: "",
  });
  const [loading, setLoading] = useState(true);
  const [productivity, setProductivity] = useState([]);
  const [email, setEmail] = useState([]);

  const [finishedSA, setfinieshedSA] = useState(false);
  const [finishedSNA, setfinieshedSNA] = useState(false);

  // surplus achieved process
  const handleButtonClick = (productivityIdSA) => {
    setfinieshedSA((prevState) => ({
      ...prevState,
      [productivityIdSA]: true,
    }));
    let params = {
      productivity_order_id: productivityIdSA,
      type: 2,
    };
    const processAction = () => {
      PayRollService.post(
        "api/v1/setting/payroll/productivity-process-finish",
        params
      ).catch((error) => {
        console.error("Error fetching process:", error);
      });
    };

    processAction();
  };

  // surplus achieved revoke
  const handleRevokeClick = (productivityIdSA) => {
    setfinieshedSA((prevState) => ({
      ...prevState,
      [productivityIdSA]: false,
    }));

    let params = {
      productivity_order_id: productivityIdSA,
    };
    const processRevoke = () => {
      PayRollService.post(
        "api/v1/setting/payroll/productivity-revoke",
        params
      ).catch((error) => {
        console.error("Error fetching revoke:", error);
      });
    };
    processRevoke();
  };

  // surplus not achieved process
  const handleButtonClickSNA = (productivityIdSNA) => {
    setfinieshedSNA((prevState) => ({
      ...prevState,
      [productivityIdSNA]: true,
    }));
    let params = {
      productivity_order_id: productivityIdSNA,
      type: 1,
    };
    const processActions = () => {
      PayRollService.post(
        "api/v1/setting/payroll/productivity-process-finish",
        params
      ).catch((error) => {
        console.error("Error fetching process:", error);
      });
    };
    processActions();
  };

  // surplus not achieved revoke
  const handleRevokeClickSNA = (productivityIdSNA) => {
    setfinieshedSNA((prevState) => ({
      ...prevState,
      [productivityIdSNA]: false,
    }));
    let params = {
      productivity_order_id: productivityIdSNA,
    };
    const processRevokee = () => {
      PayRollService.post(
        "api/v1/setting/payroll/productivity-revoke",
        params
      ).catch((error) => {
        console.error("Error fetching process:", error);
      });
    };
    processRevokee();
  };

  const handleEmailClicks = (productivityIdSA, userid) => {
    let params = {
      productivity_order_id: productivityIdSA,
      user_id: userid,
    };

    const processEmail = () => {
      PayRollService.post("api/v1/setting/payroll/productivity-mail", params)
        .then((res) => {
          setEmail(res?.data?.data?.poData || []);
          window.location = `mailto:${email.toEmail}?subject=${email.subject}&body=${email.content}`;
        })
        .catch((error) => {
          console.error("Error fetching process:", error);
        });
    };

    processEmail();
  };

  // const getProductivity = useCallback(
  //   async (worksite_id = "", employment_status_id = []) => {
  //     await new Promise((res) => {
  //       setProductivity([]); // Initialize productivity as an empty array
  //       res(true);
  //     });

  //     let params = {
  //       worksite_id: Array.isArray(worksite_id)
  //         ? worksite_id[0] || ""
  //         : worksite_id || "",
  //     };

  //     PayRollService.post("/api/v1/setting/payroll/productivity-list", params)
  //       .then((res) => {
  //         setProductivity(res?.data?.data?.allDatas || []);
  //       })
  //       .catch((err) => {
  //         setProductivity([]); // Handle the error by setting it as an empty array
  //       });
  //   }
  // );

  const getProductivity = useCallback(
    async (worksite_id = "", employment_status_id = []) => {
      try {
        setLoading(true);

        let params = {
          worksite_id: Array.isArray(worksite_id)
            ? worksite_id[0] || ""
            : worksite_id || "",
        };

        const response = await PayRollService.post(
          "/api/v1/setting/payroll/productivity-list",
          params
        );

        setProductivity(response?.data?.data?.allDatas || []);
      } catch (error) {
        console.error("Error fetching productivity data:", error);
        setProductivity([]);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const getWorksites = useCallback(
    async (search = "") => {
      const worksite = await GetWorkSite({
        search,
        take: 20,
        cursor: filter.worksites[filter.worksites.length - 1]?.id || "",
      });
      setFilter((prev) => {
        const allWork = prev.worksites.concat(worksite?.data?.data);
        const ids = [];
        const filtered = [];
        allWork.forEach((item) => {
          if (!ids.includes(item?.id)) {
            ids.push(item?.id);
            filtered.push(item);
          }
        });

        return {
          ...prev,
          worksites: filtered,
          allWorksiteLoaded: worksite?.extra?.totalItems <= filtered.length,
        };
      });
    },
    [filter.worksites]
  );

  const onScrollEnd = async (e) => {
    if (
      Math.round(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight &&
      !filter.allWorksiteLoaded
    ) {
      getWorksites();
    }
  };

  // useEffect(() => {
  //   getProductivity(filter.selectedWorksite || "" || []);
  // }, [filter.selectedWorksite]);

  useEffect(() => {
    // Check if a worksite is selected before fetching productivity data
    if (filter.selectedWorksite !== "") {
      getProductivity(filter.selectedWorksite);
    }
  }, [filter.selectedWorksite]);

  useEffect(() => {
    getWorksites();
  }, []);
  const handleExportToExcel = () => {
    const headerRow = [
      "Emp. Co./Last Name",
      "Emp. First Name",
      "Payroll Category",
      "Job",
      "Cust. Co./Last Name",
      "Cust. First Name",
      "Notes",
      "Date",
      "Units",
      "Emp.Card ID",
      "Emp. Record ID",
      "Start/Stop Time",
      "Customer Card ID",
      "Customer Record ID",
    ];

    const dataRows = [];

    productivity.forEach((product) => {
      const job = product.job_no;
      const worksite = product.name;

      product?.worker_details?.forEach((data) => {
        const name = data.users.name;

        data.calculated_data.forEach((item) => {
          const date = item.finished || "-";
          const surplus = item.surplus;
          const payroll = "-";
          const custLn = "-";
          const custFn = "-";
          const note = "-";
          const units = "-";
          const EmpRecordID = "-";
          const EmpCardID = data.users.employee_id;
          const StartTime = "-";
          const cusCardId = "-";
          const cusRecorDiD = "-";

          // Add a row with the company name, day, and worksite name

          // Add a row for the header
          if (surplus > 0) {
            dataRows.push(headerRow);

            // Add a row with the day's data
            dataRows.push([
              worksite,
              name,
              payroll,
              job,
              custLn,
              custFn,
              note,
              date,
              units,
              EmpCardID,
              EmpRecordID,
              StartTime,
              cusCardId,
              cusRecorDiD,
            ]);

            // Add an empty row as a separator
            dataRows.push([""]);
            dataRows.push([""]);
          }
        });
      });
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataRows);
    const columnWidths = headerRow.map(() => ({ wpx: 100 }));
    columnWidths[headerRow.indexOf("Date")] = { wpx: 160 };
    columnWidths[headerRow.indexOf("Cust. Co./Last Name")] = { wpx: 160 };
    columnWidths[headerRow.indexOf("Cust. First Name")] = { wpx: 160 };
    columnWidths[headerRow.indexOf("Emp. Co./Last Name")] = { wpx: 160 };
    columnWidths[headerRow.indexOf("Emp. First Name")] = { wpx: 160 };
    worksheet["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "FilteredProductivityData"
    );

    XLSX.writeFile(workbook, "filtered_productivity.xlsx");
  };
  return (
    <div className="m-5 flex flex-col gap-7">
      <div className="bg-white p-8">
        <span className="flex justify-between">
          <h2 style={{ fontSize: "26px" }}>Worksite</h2>
        </span>
        <div className="flex gap-6 mt-5 mb-8">
          <span className="flex-8 flex flex-col">
            <Select
              style={{ width: 350 }}
              onPopupScroll={onScrollEnd}
              onChange={(selectedWorksite) =>
                setFilter((prev) => ({ ...prev, selectedWorksite }))
              }
            >
              {filter.worksites.map((item) => (
                <Select.Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          {/* <Input
            className="w-30 font-lexend h-10 "
            placeholder="Search by Name"
            prefix={<HiOutlineSearch color="#A1A1A1" />}
          /> */}

          <Button
            className="border border-[#001C33]"
            onClick={handleExportToExcel}
          >
            Export Productivity to Excel
          </Button>
        </div>
      </div>

      <div>
        {productivity && productivity.length > 0 ? (
          productivity.map((product) => {
            let dataFound = false;
            return (
              <div key={product.job_no}>
                {product.worker_details?.map((data) => {
                  if (data.calculated_data.length > 0) {
                    dataFound = true;
                    return (
                      <div className="bg-white mt-10" key={data.worker_id}>
                        <Collapse
                          className="border-none p-0 m-[0px] "
                          ghost
                          accordion
                          expandIcon={({ isActive }) =>
                            isActive ? <FaAngleUp /> : <FaAngleDown />
                          }
                        >
                          <Panel
                            ghost
                            header={
                              <span className="inline-flex gap-8 ">
                                <p className="font-semibold text-lg">
                                  {product.job_no}
                                </p>
                                <p className="font-semibold text-lg">
                                  {data.users.name}
                                </p>
                              </span>
                            }
                            style={{
                              margin: "5px",
                            }}
                            className="border-none p-0 m-[0px] "
                          >
                            {/* Check if the surplus is positive and greater than zero */}

                            {data.calculated_data.every(
                              (item) => item.surplus > 0
                            ) ? (
                              <React.Fragment>
                                <th>
                                  <div
                                    className=" font-semibold"
                                    style={{
                                      color: "#FE650C",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Surplus Achieved
                                  </div>
                                </th>
                                <table className="text-center my-10 font-sm w-full mt-5">
                                  <thead className="timesheet-detailed-table-head-container">
                                    <tr className="timesheet-detailed-table-main-head">
                                      <th>
                                        <div className=" font-semibold">
                                          Worksite
                                        </div>
                                      </th>
                                      <th>
                                        <div className=" font-semibold text-center">
                                          Productivity Order
                                        </div>
                                      </th>
                                      <th>
                                        <div className=" font-semibold">
                                          Total
                                        </div>
                                      </th>

                                      <th colSpan={2}>Surplus</th>

                                      <th colSpan={2}>%</th>
                                      <th colSpan={2}>Hours</th>
                                      <th colSpan={2}>$</th>
                                      <th colSpan={2}>Finished</th>
                                      <th colSpan={2}>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className="timesheet-detailed-table-body">
                                    {data.calculated_data?.map((item) => (
                                      <tr className="border-b border-b-[#E9E9E9] ">
                                        <td className="">{product.name}</td>
                                        <td>
                                          {" "}
                                          {item.productivity_order_name}{" "}
                                        </td>
                                        <td>{item.total_time_spent}</td>
                                        <td colSpan={2}>
                                          {parseFloat(item.surplus).toFixed(2)}
                                        </td>
                                        <td colSpan={2}>
                                          {parseFloat(item.percentage).toFixed(
                                            2
                                          )}
                                        </td>
                                        <td colSpan={2}>
                                          {parseFloat(item.hours).toFixed(2)}
                                        </td>
                                        <td colSpan={2}>{item.dollar}</td>
                                        <td colSpan={2}>
                                          {item.finished
                                            ? new Date(
                                                item.finished
                                              ).toLocaleDateString()
                                            : "-"}
                                        </td>

                                        <td colSpan={2}>
                                          {finishedSA[
                                            item.productivity_order_id
                                          ] ? (
                                            <>
                                              <span
                                                style={{
                                                  color: "green",
                                                  fontWeight: "bolder",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                Processed
                                              </span>
                                              <Button
                                                className="mr-5"
                                                onClick={() =>
                                                  handleRevokeClick(
                                                    item.productivity_order_id
                                                  )
                                                }
                                              >
                                                Revoke
                                              </Button>
                                            </>
                                          ) : (
                                            <Button
                                              className="mr-5"
                                              onClick={() =>
                                                handleButtonClick(
                                                  item.productivity_order_id
                                                )
                                              }
                                            >
                                              Finish
                                            </Button>
                                          )}

                                          {/* <a
                                        href={`mailto:${email.toEmail}?subject=${email.subject}&body=${email.content}`}
                                      > */}
                                          <Button
                                            onClick={() =>
                                              handleEmailClicks(
                                                item.productivity_order_id,
                                                data.user_id
                                              )
                                            }
                                          >
                                            Email
                                          </Button>
                                          {/* </a> */}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </React.Fragment>
                            ) : null}

                            {data.calculated_data.every(
                              (item) => item.surplus <= 0
                            ) ? (
                              <React.Fragment>
                                <th>
                                  <div
                                    className=" font-semibold"
                                    style={{
                                      color: "#ff0000",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Surplus Not Achieved
                                  </div>
                                </th>
                                <table className="text-center my-10 font-sm w-full mt-5">
                                  <thead className="timesheet-detailed-table-head-container">
                                    <tr className="timesheet-detailed-table-main-head">
                                      <th>
                                        <div className=" font-semibold">
                                          Worksite
                                        </div>
                                      </th>
                                      <th>
                                        <div className=" font-semibold text-center">
                                          Productivity Order
                                        </div>
                                      </th>
                                      <th>
                                        <div className=" font-semibold">
                                          Total
                                        </div>
                                      </th>

                                      <th colSpan={2}>Surplus</th>
                                      <th colSpan={2}>%</th>
                                      <th colSpan={2}>Hours</th>
                                      <th colSpan={2}>$</th>
                                      <th colSpan={2}>Finished</th>
                                      <th colSpan={2}>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className="timesheet-detailed-table-body">
                                    {data.calculated_data?.map((item) => (
                                      <tr className="border-b border-b-[#E9E9E9] ">
                                        <td className="">{product.name}</td>
                                        <td>
                                          {" "}
                                          {item.productivity_order_name}{" "}
                                        </td>
                                        <td>{item.total_time_spent}</td>
                                        <td colSpan={2}>
                                          {parseFloat(item.surplus).toFixed(2)}
                                        </td>
                                        <td colSpan={2}>
                                          {parseFloat(item.percentage).toFixed(
                                            2
                                          )}
                                        </td>
                                        <td colSpan={2}>
                                          {parseFloat(item.hours).toFixed(2)}
                                        </td>
                                        <td colSpan={2}>{item.dollar}</td>

                                        <td colSpan={2}>
                                          {item.finished
                                            ? new Date(
                                                item.finished
                                              ).toLocaleDateString()
                                            : "-"}
                                        </td>

                                        <td colSpan={2}>
                                          {finishedSNA[
                                            item.productivity_order_id
                                          ] ? (
                                            <>
                                              <span
                                                style={{
                                                  color: "green",
                                                  fontWeight: "bolder",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                Processed
                                              </span>
                                              <Button
                                                className="mr-5"
                                                onClick={() =>
                                                  handleRevokeClickSNA(
                                                    item.productivity_order_id
                                                  )
                                                }
                                              >
                                                Revoke
                                              </Button>
                                            </>
                                          ) : (
                                            <Button
                                              className="mr-5"
                                              onClick={() =>
                                                handleButtonClickSNA(
                                                  item.productivity_order_id
                                                )
                                              }
                                            >
                                              Process
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </React.Fragment>
                            ) : null}
                          </Panel>
                        </Collapse>
                      </div>
                    );
                  }
                  return null; // If calculated_data is empty, don't render anything for this worker
                })}
                {!dataFound && (
                  <p style={{ textAlign: "center" }} key={product.job_no}>
                    {" "}
                    Productivity data found to be empty
                  </p>
                )}
              </div>
            );
          })
        ) : (
          <div>
            {filter.selectedWorksite.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                Select a worksite to display data
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>No data available</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Productivity;
