import React, { useEffect, useState } from "react";
import BarChartWorkerBreakdown from "../Charts/BarChartWorkerBreakdown";
import { GetWorkerOnSite } from "../../../../../helper/worksite/worksiteDetails.js/timesheet";
import { useParams } from "react-router-dom";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";

const WorkerBreakdown = ({ activeDate, setActiveDate }) => {
  const { id } = useParams();
  const { timesheet } = useParams();

  const initialbraekDown = {
    data: [],
    isShimmer: true,
  };

  const [workerbreakDown, setWorkerBreakDown] = useState(initialbraekDown);

  const handlebreakDown = {
    get: async (getBreak) => {
      const params = {
        worksite_id: id,
        worksheet_id: timesheet,
        date: getBreak?.date,
        timesheet: getBreak?.timesheet,
      };
      let data = await GetWorkerOnSite(params);
      if (data?.status) {
        setWorkerBreakDown({
          data: [...data?.data?.data],
          isShimmer: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      const params = {
        worksheet_id: id,
        date: activeDate,
        timesheet: "",
      };
      let data = await handlebreakDown?.get(params);
    })();
  }, [activeDate]);

  return (
    <div>
      {workerbreakDown?.isShimmer ? (
        <TextShimmer data={{ gap: 15, line: 20 }} />
      ) : (
        <>
          <h1 className="px-8 py-3 text-2xl font-lexend font-semibold">
            Workers on Site
          </h1>
          <span className="flex items-center">
            <p className="rotate-[270deg] text-[#A1A1A1]">Worker</p>
            <BarChartWorkerBreakdown data={workerbreakDown} />
          </span>
        </>
      )}
    </div>
  );
};

export default WorkerBreakdown;
