import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { GetWorkSite, markAsSplitShift } from "../../helper/worksite/worksite";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ButtonSpinner } from "../../components/spinners";
import { toast } from "react-toastify";

const SplitShiftModal = ({
  closeModal,
  selectedItem,
  getWorkersList,
  setDisplayTextWor,
  //   setData,
}) => {
  const [worksiteList, setWorksiteList] = useState();
  const [selectedWorksite, setSelectedWorksite] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (value) => {
    setSelectedWorksite(value);
  };
  const fetchWorksiteList = async () => {
    let data = await GetWorkSite();
    if (data?.status) {
      setWorksiteList({
        ...worksiteList,
        data: [...data?.data?.data],
      });
    }
  };
  const saveChanges = async () => {
    setIsLoading(true);
    setDisplayTextWor(true);
    let data = await markAsSplitShift(selectedItem, selectedWorksite);

    if (data?.status) {
      //   setData(data?.status);
      closeModal();
      toast?.success(data?.message ? data?.message : "");
      getWorkersList();
    } else {
      toast?.error(data?.message);
    }
  };
  useEffect(() => {
    (async () => {
      fetchWorksiteList();
    })();
  }, []);

  return (
    <div className="modal shift-details">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          <h2 className="text-xl font-bold">Split Shift</h2>
        </div>
        <p className="text-[#A1A1A1] mt-3">
          Make this a split shift if the worker will be working on a another
          worksite today.
        </p>
        <Form layout="vertical" className="mt-4">
          <Form.Item label="Select A Worksite">
            <Select
              className="shift-details-select"
              placeholder="Select Worksite"
              onChange={handleChange}
            >
              {worksiteList?.data?.length > 0 &&
                worksiteList?.data?.map((item) => (
                  <Select.Option value={item?.id} key={item?.id}>
                    {item?.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
        <div className="w-full text-center font-[500] items-center flex justify-start">
          <AiOutlineInfoCircle className="text-[#F4511E] mr-1 text-[20px]" />
          <p className="text-[#A1A1A1]">
            The paid break will be removed if you mark this shift as split
            shift.
          </p>
        </div>
        <div className="flex justify-center ">
          <Button
            className="btn btn-blue bg-blue-disable mt-5"
            onClick={saveChanges}
            disabled={!selectedWorksite}
          >
            {isLoading ? <ButtonSpinner /> : "Mark as Split Shift"}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SplitShiftModal;
