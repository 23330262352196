import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TabBtns from "../../../../shared/Workers/TabBtns";
import ActiveProfile from "./WorkerProfiles/ActiveProfile";
import {
  AddPayrollCategory,
  GetWorkersById,
  TerminateRevokeWorker,
} from "../../../../helper/personnel/workers";
import { toast } from "react-toastify";
import { SearchData } from "../../../../helper/global/global";
import { PayRollService } from "../../../../config/axiosUrl";
import MultySelect from "../../../../components/multySelect/multyselect";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import { Button, Modal } from "antd";
import { ConfirmRightBig, Receipt } from "../../../../assets/svg";

const WorkersDetails = () => {
  const { id } = useParams();

  const initialvalues = {
    isShimmer: true,
    isLoading: false,
    selectedPayrollCategory: [],
    workerTab: 1,
  };

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Payroll Category",
    searchBarTitle: "Search by Payroll Category Name",
    buttonTitle: "Add",
  };

  const [worker, setWorker] = useState(initialvalues);
  const [multySelect, setMultySelect] = useState(initialMultySelectValue);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleWorker = {
    getWorker: async () => {
      const params = {
        id: id,
      };

      let data = await GetWorkersById(params);
      let allSelectedOptions = [];
      data?.data?.data?.workers_payroll_category?.map((item, index) => {
        return allSelectedOptions?.push(item?.payroll_category?.id);
      });

      if (data?.status) {
        setWorker({
          ...worker,
          ...data?.data?.data,
          isShimmer: false,
        });
        setMultySelect({
          ...multySelect,
          selectedOption: allSelectedOptions,
        });
      }
    },
    getPayrollCategories: async (e) => {
      if (!e?.target?.value) {
        setMultySelect({
          ...multySelect,
          isShimmer: true,
          isMultySelect: true,
        });
      }

      const params = {
        value: e?.target?.value,
        api: "/api/v1/payroll/setting/get-payroll-category-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setMultySelect({
          ...multySelect,
          options: [...data?.data?.data],
          isShimmer: false,
          isMultySelect: true,
        });
      }
    },
    terminateRevoke: async (status) => {
      setWorker({
        ...worker,
        isLoading: true,
      });
      const params = {
        id: id,
        status: status,
      };

      let data = await TerminateRevokeWorker(params);
      if (data?.status) {
        toast?.success(data?.message);
        handleWorker?.getWorker();
        await setWorker({
          ...worker,
          status: status,
          isLoading: false,
        });
      } else {
        toast?.error(data?.message);
      }
    },
    addPayrollCategoties: async () => {
      setMultySelect({
        ...multySelect,
        isLoading: true,
      });

      const params = {
        ids: multySelect?.selectedOption,
        worker_id: worker?.id,
      };

      let getData = await AddPayrollCategory(params);
      if (getData?.status) {
        await handleWorker?.getWorker();
        setMultySelect(initialMultySelectValue);
      } else {
        toast?.error(getData?.message);
        setMultySelect({
          ...multySelect,
          isLoading: false,
        });
      }
    },
    getWorkerTab: (tab) => {
      setWorker({
        ...worker,
        workerTab: tab,
      });
    },
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await handleWorker?.getWorker();
      })();
    }
  }, []);

  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={toggleModal}
        footer={[]}
        centered
        className="max-w-[30vw]"
      >
        <div className="flex items-center gap-14 flex-col justify-center py-3">
          <ConfirmRightBig />
          <h1 className="text-2xl text-center font-semibold">
            Induction Details Submitted Successfully
          </h1>
          <div className="flex w-full gap-8 justify-around">
            <button className="flex flex-1 justify-center items-center gap-1 bg-white border border-[#D5D5D5] py-3 rounded-xl">
              <Receipt />
              Print
            </button>
            <button className="flex-1 bg-[#0F4C7D] text-white rounded-xl py-3">
              Done
            </button>
          </div>
        </div>
      </Modal>

      {worker?.isShimmer ? (
        <>
          <div className="p-6">
            <div className="data-card p-4 my-3  bg-white">
              <TextShimmer data={{ gap: 15, line: 22 }} />
            </div>
          </div>
        </>
      ) : (
        <>
          {multySelect?.isMultySelect && (
            <MultySelect
              data={multySelect}
              setData={setMultySelect}
              search={handleWorker?.getPayrollCategories}
              submit={handleWorker?.addPayrollCategoties}
            />
          )}

          <div className="p-4">
            <div className="data-card  my-3">
              <div className="tab-buttons flex justify-start items-center">
                <Button
                  className={`flex justify-center items-center p-0 mr-3 h-[42px] w-32 btn-blue`}
                  onClick={() =>
                    window.location.toString().includes("site-manager")
                      ? navigate(`/site-manager/workers/1/details/${id}`)
                      : navigate(
                          `/admin/personnel/workers/details/terminated/${id}`
                        )
                  }
                >
                  <span
                    className={"block w-full py-3 lg:px-5 h-full btn-blue btn"}
                  >
                    {worker?.status == 3 ? "Terminated" : "Active"}
                  </span>
                </Button>

                <Button
                  className={`flex justify-center items-center p-0 mr-3 h-[42px] w-42`}
                  onClick={() =>
                    window.location.toString().includes("site-manager")
                      ? navigate(
                          `/site-manager/workers/1/details/worker_details/${id}`
                        )
                      : navigate(
                          `/admin/personnel/workers/details/worker_details/${id}`
                        )
                  }
                >
                  <span className="block w-full py-3 lg:px-5 h-full">
                    Worker Details
                  </span>
                </Button>
              </div>

              <div className="tab-contents">
                <ActiveProfile
                  worker={worker}
                  setWorker={setWorker}
                  handle={handleWorker}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkersDetails;
