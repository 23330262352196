import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkService } from "../../config/axiosUrl";
import { formatTime } from "../DataTable/timeUtils";
const TimespentEditModal = ({ close, data, timespentBreakdown, handle }) => {
  const { handleSubmit, setValue, watch } = useForm();

  const { id } = useParams();
  const { area } = useParams();

  const time = data?.finished_time;
  let formattedTime = formatTime(time);

  const [areaData, setAreaData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(data?.area_id); // State to store selected area id
  const [selectedTaskId, setSelectedTaskId] = useState(data.task_id);
  const [seletectTime, setSelectedTime] = useState(formattedTime);

  useEffect(() => {
    setValue("area", data.area_name);
    setValue("task", data.task_name);
    setValue("time", formattedTime);
  }, [data.area_name, data.task_name, formattedTime, setValue]);

  useEffect(() => {
    WorkService.get(
      `/api/v1/worksite/area/get-areas-data?worksite_id=${id}`
    ).then((res) => {
      setAreaData(res?.data?.data?.data || []);
    });
  }, [id]);

  useEffect(() => {
    if (selectedAreaId) {
      WorkService.get(
        `/api/v1/worksite/list-productivity-orders?area_id=${selectedAreaId}`
      ).then((res) => {
        setTaskData(res?.data?.data || []);
      });
    }
  }, [selectedAreaId]);

  const handleAreaChange = (event) => {
    setSelectedAreaId(event.target.value);
  };

  const handleTaskChange = (event) => {
    setSelectedTaskId(event.target.value);
  };
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleSaveChange = () => {
    let finished = parseFloat(seletectTime.replace(":", "."));

    let params = {
      timesheet_workdone_id: data?.timesheet_workdone_id,
      finished: finished,
      task_id_new: selectedTaskId,
      area_id_new: selectedAreaId,
    };
    WorkService.post(`/api/v1/worksite/edit-task-estimation`, params).then(
      (res) => {
        timespentBreakdown();
        toast?.success(res?.data?.message);
        handle.getAreaById();
        close();
      }
    );
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <div className="flex justify-between text-md mb-5 font-medium">
            {data?.name}
            <button className="close-modal" onClick={close}>
              <FaTimes />
            </button>
          </div>
          <div className="flex flex-col gap-5 accent-gray-50 items-start">
            {/* <span className="flex flex-col w-full items-start text-sm">
              <span>Area</span>
              <select
                className="mass-absent-opt h-8 border w-full rounded"
                name="area"
                onChange={handleAreaChange}
                value={selectedAreaId}
              >
                {areaData.map((area) => (
                  <option key={area?._id} value={area._id}>
                    {area.name}
                  </option>
                ))}
              </select>
            </span>
            <span className="flex flex-col w-full items-start text-sm">
              <span>Task</span>
              <select
                className="mass-absent-opt h-8 border w-full rounded"
                name="taskprod"
                value={selectedTaskId}
                onChange={handleTaskChange}
              >
                {/* <option>Select the task</option> */}
            {/* {taskData?.map((task) => (
                  <option key={task?._id} value={task?._id}>
                    {task?.name}
                  </option>
                ))}
              </select>
            </span> */}
            <span className="flex flex-col w-full items-start text-sm">
              <span>Finished</span>
              <input
                type="time"
                className="mass-absent-opt h-8 border w-full rounded"
                placeholder="Finish time"
                value={seletectTime}
                onChange={handleTimeChange}
              />
            </span>

            <Button
              className="bg-[#0F4C7D] mx-auto text-white"
              onClick={handleSaveChange}
            >
              Save Change
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimespentEditModal;
