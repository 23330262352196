import React from "react";
import { PayRollService, WorkService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CreateTimesheet = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: timesheet?.worksite_id,
      day: timesheet?.day,
      id: timesheet?.id,
      spent: timesheet?.spent,
      isSave: timesheet?.isSave,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/timesheets/create-timesheets",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetTimesheet = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: timesheet?.worksite_id,
      cursor: timesheet?.cursor,
      take: timesheet?.take,
      previous: timesheet?.previous,
      id: timesheet?.id,
      from: timesheet?.from,
      to: timesheet?.to,
      search: timesheet?.search,
    };

    let data = await WorkService?.get(
      "/api/v1/worksite/timesheets/get-timesheet",
      {
        authorization: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateShiftDetails = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: timesheet?.id,
      worksite_id: timesheet?.worksite_id,
      area_id: timesheet?.area_id,
      worksite_timesheet_id: timesheet?.worksite_timesheet_id,
      isSave: timesheet?.isSave,
      worker_data: timesheet?.worker_data,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/timesheets/create-timesheets-data?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

// errorsummary
const CreateShiftDetailsSummary = async (timesheet, date) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: timesheet?.id,
      worksite_id: timesheet?.worksite_id,
      area_id: timesheet?.area_id,
      worksite_timesheet_id: timesheet?.worksite_timesheet_id,
      isSave: timesheet?.isSave,
      worker_data: timesheet?.worker_data,
      date: date,
    };

    let data = await PayRollService?.post(
      "/api/v1/setting/payroll/summary-create-timesheets-data?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const EditShiftDetails = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: timesheet?.id,
      worksite_id: timesheet?.worksite_id,
      area_id: timesheet?.area_id,
      worksite_timesheet_id: timesheet?.worksite_timesheet_id,
      isSave: timesheet?.isSave,
      worker_data: timesheet?.worker_data,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/timesheets/edit-timesheets-data?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

// errors

const GetShift = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: timesheet?.id,
      worksite_timesheet_id: timesheet?.worksite_timesheet_id,
      worksite_id: timesheet?.worksite_id,
      cursor: timesheet?.cursor,
      area_ids: timesheet?.area_ids,
      take: timesheet?.take,
      previous: timesheet?.previous,
      search: timesheet?.search,
      date: timesheet?.date,
    };

    let data = await WorkService?.get(
      "/api/v1/worksite/timesheets/get-timesheet-data",
      {
        authorization: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkerOnSite = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksheet_id: timesheet?.worksheet_id,
      date: timesheet?.date,
      worksite_id: timesheet?.worksite_id,
    };

    let data = await WorkService?.get(
      "/api/v1/worksite/timesheets/workers-on-site",
      {
        authorization: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkerTimeline = async (timesheet) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksheet_id: timesheet?.worksheet_id,
      date: timesheet?.date,
      worksite_id: timesheet?.worksite_id,
    };

    let data = await WorkService?.get(
      "/api/v1/worksite/timesheets/hours-spent-across-tasks",
      {
        authorization: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateTimesheet,
  GetTimesheet,
  CreateShiftDetails,
  GetShift,
  EditShiftDetails,
  GetWorkerTimeline,
  GetWorkerOnSite,
  CreateShiftDetailsSummary,
};
