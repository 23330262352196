import React from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import DeleteEmploymentStatusModal from "../Modal/DeleteEmploymentStatusModal";
import EditEmployStatusModal from "../Modal/EditEmployStatusModal";
import CreateEmployementStatusModal from "../Modal/CreateEmpoyementStatusModal";

const EmployementStatusTable = ({
  data,
  setData,
  handle,
  modalVisible,
  setModalVisible,
  createEmployementStatus,
  setCreateEmployementStatus,
}) => {
  const [userInfo, setUserInfo] = React.useState({});

  const DeleteModalVisible = (item, index) => {
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
    setModalVisible(true);
  };

  const openEditModal = (item, index) => {
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
    setCreateEmployementStatus(true);
  };
  return (
    <>
      {modalVisible && (
        <DeleteEmploymentStatusModal
          setVisibleDelete={setModalVisible}
          userInfo={userInfo}
          data={data}
          handle={handle}
        />
      )}

      {createEmployementStatus && (
        <CreateEmployementStatusModal
          setCreateEmployementStatus={setCreateEmployementStatus}
          data={data}
          setData={setData}
        />
      )}

      <table className="w-full  mx-auto">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-start">
                Employment Status Types
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.data?.map((item, index) => (
            <tr key={index} className="border-b border-[#F0F0F0]">
              <td className="px-4 py-3">
                <div className="flex items-center justify-start">
                  {item?.name}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  <button
                    className="mx-2"
                    onClick={() => openEditModal(item, index)}
                  >
                    <FaEdit title="Edit" />
                  </button>
                  {/* <button
                    className="mx-2"
                    onClick={() => {
                      DeleteModalVisible(item, index);
                    }}
                  >
                    <FaTrashAlt />
                  </button> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default EmployementStatusTable;
