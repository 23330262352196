import React from "react";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { WorkService } from "../../config/axiosUrl";
import { duration } from "moment";
import { useParams } from "react-router-dom";

const SplitDate = (date) => {
  let retDate = date?.split("T");
  return retDate[0];
};

const CreateEditWorksite = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let formData = new FormData();

    formData?.append("id", worksite?.id);
    formData?.append("company_id", worksite?.company_id);
    formData?.append("job_no", worksite?.job_no);
    formData?.append("name", worksite?.name);
    formData?.append("address", worksite?.address);
    formData?.append("builder", worksite?.builder);
    formData?.append("builder_contact_email", worksite?.builder_contact_email);
    formData?.append(
      "project_cost",
      worksite?.project_cost !== null && !isNaN(worksite?.project_cost)
        ? parseFloat(
            String(worksite.project_cost || 0)
              .replace(/\$/g, "")
              .replace(/,/g, "")
          )
        : parseFloat(
            String(worksite.project_cost || 0)
              .replace(/\$/g, "")
              .replace(/,/g, "")
          )
    );

    // formData?.append("project_cost", worksite?.project_cost);
    formData?.append("work_status", worksite?.work_status);
    formData?.append("site_manager_id", worksite?.site_manager_id);
    formData?.append("start_date", worksite?.start_date);
    formData?.append("finish_date", worksite?.finish_date);
    formData?.append("standard_start_time", worksite?.standard_start_time);
    formData?.append(
      "standard_end_weekend_time",
      worksite?.standard_end_weekend_time
    );
    formData?.append(
      "standard_end_friday_time",
      worksite?.standard_end_friday_time
    );
    formData?.append(
      "standard_end_weekday_time",
      worksite?.standard_end_weekday_time
    );
    formData?.append("upper_control", worksite?.upper_control);
    formData?.append("lower_control", worksite?.lower_control);
    formData?.append(
      "productivity_payroll_category",
      worksite?.productivity_payroll_category
    );
    formData?.append(
      "productivity_surplus_rate",
      worksite?.productivity_surplus_rate
    );
    formData?.append("payroll_category_ids", worksite?.payroll_category_ids);
    formData?.append("picture", worksite?.picture);
    formData?.append("isSave", worksite?.isSave);
    formData?.append("worksite_id", worksite?.id);
    formData?.append(
      "worksite_payrolls_id",
      worksite?.worksite_payrolls?.[0]?.id
    );
    formData?.append(
      "worksite_timelines_id",
      worksite?.worksite_timelines?.[0]?.id
    );
    formData?.append(
      "worksite_payroll_categories_id",
      worksite?.worksite_payroll_categories?.[0]?.id
    );

    let data = await WorkService?.post(
      "/api/v1/worksite/create-edit-worksite?dev=4",
      formData,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkSite = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      cursor: worksite?.cursor,
      // take: worksite?.take || 10,
      type: worksite?.type,
      skip: worksite?.skip,
      date: worksite?.date,
      search: worksite?.search,
      worksite_id: worksite?.worksite_id,
    };

    let data = await WorkService?.get("/api/v1/worksite/get-worksite-data", {
      params: params,
      headers: authorization?.headers,
    });

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkSiteById = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/get-worksite-data-by-id?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorksiteStatus = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/change-status-worksite",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteWorksitePayroll = async (payroll) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: payroll?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/delete-worksite-payroll-category",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteWorkSiteImage = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/delete-worksite-image",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const AddProductiveOrderTask = async (task) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: task?.id,
      worksite_id: task?.worksite_id,
      productivity_orders_id: task?.productivity_orders_id,
      hourly_rate_tasks_estimations_ids:
        task?.hourly_rate_tasks_estimations_ids,
      alloc_hours: task?.alloc_hours,
      time_spent: task?.time_spent,
      is_save: task?.is_save,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/add-edit-productivity-orders-data?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkersType = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const params = {
      cursor: worksite?.cursor,
      skip: worksite?.skip,
      worksite_id: worksite?.id,
      worker_status: worksite?.type,
      search: worksite?.search,
    };

    let data = await WorkService?.get(
      `/api/v1/worksite/site-manager/get-workers`,
      {
        params: params,
        headers: authorization?.headers,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatus = async (item, worksite, id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: id ? id : worksite?.id,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: 1,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusResume = async (item, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      work_site_id: worksite?.id,
      workers_ids: [item?.id],
      is_resumed: 1,
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/workers/change-work-status?dev=2",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusFinish = async (item, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: 1,
      is_started: "",
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusTransfer = async (item, worksite, selectedWorksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: selectedWorksite,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: 1,
      is_finished: "",
      is_started: "",
      is_bulk: 0,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusTerminate = async (item, worksite, reason) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: 1,
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
      reason: reason ? reason : "",
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusConfirm = async (item, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
      is_conformed: 1,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusRoster = async (item, worksite, id, isShift) => {
  if (isShift) {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      const paramsShift = {
        area_id: "",
        worksite_id: id,
        workers_data: [
          {
            worker_id: item?.item?._id,
            worksite_timesheet_data_id:
              item?.item?.timesheetData[item?.day].timesheetData.length > 0
                ? item?.item?.timesheetData[item?.day].timesheetData[0]?._id
                : "",
          },
        ],
        is_resumed: "",
        is_terminate: "",
        is_mass_transfer: "",
        is_finished: "",
        is_started: "",
        is_conformed: "",
        is_roster: 1,
        date: SplitDate(item?.item?.timesheetData?.[item?.day]?.date),
      };

      let data = await WorkService?.post(
        "/api/v1/worksite/site-manager/change-work-status?dev=3",
        paramsShift,
        authorization
      );

      return data?.data;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      const itemMap = item?.map((ele, i) => {
        return { worker_id: ele };
      });

      const params = {
        area_id: "",
        worksite_id: id ? id?.id : worksite?.id,
        workers_data: itemMap,
        is_resumed: "",
        is_terminate: "",
        is_mass_transfer: "",
        is_finished: "",
        is_started: "",
        is_conformed: "",
        is_roster: 1,
      };

      let data = await WorkService?.post(
        "/api/v1/worksite/site-manager/change-work-status?dev=3",
        params,
        authorization
      );

      return data?.data;
    } catch (error) {
      return error;
    }
  }
};
const ChangeWorkerStatusOverride = async (item, worksite, id, isShift) => {
  if (isShift) {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      const paramsShift = {
        area_id: "",
        worksite_id: id,
        workers_data: [
          {
            worker_id: item?.item?._id,
            worksite_timesheet_data_id:
              item?.item?.timesheetData[item?.day].timesheetData.length > 0
                ? item?.item?.timesheetData[item?.day].timesheetData[0]?._id
                : "",
          },
        ],
        is_resumed: "",
        is_terminate: "",
        is_mass_transfer: "",
        is_finished: "",
        is_started: "",
        is_conformed: "",
        is_roster: 1,
        is_override: 1,
        date: SplitDate(item?.item?.timesheetData?.[item?.day]?.date),
      };

      let data = await WorkService?.post(
        "/api/v1/worksite/site-manager/change-work-status?dev=3",
        paramsShift,
        authorization
      );

      return data?.data;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      const itemMap = item?.map((ele, i) => {
        return { worker_id: ele };
      });

      const params = {
        area_id: "",
        worksite_id: id ? id?.id : worksite?.id,
        workers_data: itemMap,
        is_resumed: "",
        is_terminate: "",
        is_mass_transfer: "",
        is_finished: "",
        is_started: "",
        is_conformed: "",
        is_roster: 1,
        is_override: 1,
      };

      let data = await WorkService?.post(
        "/api/v1/worksite/site-manager/change-work-status?dev=3",
        params,
        authorization
      );

      return data?.data;
    } catch (error) {
      return error;
    }
  }
};
const ChangeWorkerStatusUnRoster = async (item, id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const paramsShift = {
      area_id: "",
      worksite_id: id,
      workers_data: [
        {
          worker_id: item?.item?._id,
          worksite_timesheet_data_id:
            item?.item?.timesheetData?.[item?.day]?.timesheetData[0]?._id,
        },
      ],
      is_un_roster: 1,
      date: SplitDate(item?.item?.timesheetData?.[item?.day]?.date),
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      paramsShift,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MassClockIn = async (item, worksite, id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const workersData = worksite?.data?.map((e, i) => {
      return {
        worker_id: e?.users[0]?._id,
        worksite_timesheet_data_id: e?._id,
      };
    });
    const params = {
      area_id: "",
      worksite_id: id,
      workers_data: workersData,
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: 1,
      is_conformed: "",
      is_roster: "",
      is_started_bulk: 1,
      start: worksite?.started_time,
      break: "",
      finish: worksite?.finished_time,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MassAbsense = async (item, id, worksite, selectedOption, isShift) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: id,
      workers_data: isShift
        ? [
            {
              worker_id: selectedOption?.item?._id,
              worksite_timesheet_data_id:
                selectedOption?.item?.timesheetData?.[selectedOption?.day]
                  ?.timesheetData[0]?._id,
            },
          ]
        : selectedOption
        ? [
            {
              worker_id: selectedOption?.users[0]?._id,
              worksite_timesheet_data_id: selectedOption?._id,
            },
          ]
        : [],
      // workers_data: selectedOption.map((i) => {
      //   return {
      //     worker_id: i,
      //   };
      // }),
      is_bulk: selectedOption ? 0 : 1,
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
      is_conformed: "",
      is_roster: "",
      is_mass_clock_in: "",
      is_mass_clock_off: "",
      is_mass_absence: 1,
      end_time: "",
      duration: worksite?.duration,
      reason: worksite?.reason,
      absence_type: worksite?.absence_type,
      date: isShift
        ? SplitDate(
            selectedOption?.item?.timesheetData?.[selectedOption?.day]?.date
          )
        : "",
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MarkPresent = async (worksite, item, isShift) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: isShift
        ? [
            {
              worker_id: item?.item?._id,
              worksite_timesheet_data_id:
                item?.item?.timesheetData[item?.day]?.timesheetData?.length > 0
                  ? item?.item?.timesheetData?.[item?.day]?.timesheetData[0]
                      ?._id
                  : "",
            },
          ]
        : item
        ? [
            {
              worker_id: item?.users[0]?._id,
              worksite_timesheet_data_id: item?._id,
            },
          ]
        : [],
      is_bulk: item ? 0 : 1,
      is_present: 1,
      work_status: isShift ? "" : worksite?.worker_status,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CancelWork = async (worksite, item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: item
        ? [
            {
              worker_id: item?.users[0]?._id,
              worksite_timesheet_data_id: item?._id,
            },
          ]
        : [],
      is_bulk: item ? 0 : 1,
      is_cancel: 1,
      work_status: worksite?.worker_status,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MassSignOffOpt = async (item, id, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      work_site_id: id,
      workers_ids: item?.selectedOption,
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
      is_conformed: "",
      is_roster: "",
      is_mass_clock_in: "",
      is_mass_clock_off: "1",
      is_mass_absence: "",
      end_time: "",
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MassTransferOpt = async (item, worksite, id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const itemMap = item?.selectedOption?.map((ele, i) => {
      return { worker_id: ele };
    });
    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: itemMap,
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "1",
      exist_worksite_id: id,
      is_finished: "",
      is_started: "",
      is_conformed: "",
      is_roster: "",
      is_bulk: 1,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ChangeWorkerStatusSignOff = async (item, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: [
        {
          worker_id: item?.users[0]?._id,
          worksite_timesheet_data_id: item?._id,
        },
      ],
      is_resumed: "",
      is_terminate: "",
      is_mass_transfer: "",
      is_finished: "",
      is_started: "",
      is_finished: 1,
      is_sign_off_bulk: 1,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const SaveNotes = async (item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      // area_id: item?.area_id,
      // hourly_task_id: item?.hourly_task_id,
      // productivity_task_id: item?.productivity_task_id,
      // worksite_id: item?.worksite_id,
      // notes: item?.notes,
      // isSave: 1
      id: item?.id,
      note: item?.note,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/save-note",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CancelAll = async (worksite, item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: "",
      worksite_id: worksite?.id,
      workers_data: [],
      is_bulk: item ? 0 : 1,
      is_cancel: 1,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/change-work-status?dev=3",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const confirmWork = async (
  taskArr,
  item,
  worksite_id,
  area_id,
  timesheet_status
) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: worksite_id,
      area_id: area_id,
      worksite_timesheet_data_id: item?._id,
      worksite_timesheet_id: item?.worksite_timesheet_id
        ? item?.worksite_timesheet_id
        : "",
      isSave: 1,
      worker_id: item?.users?.length > 0 ? item?.users[0]?._id : item?._id,
      timesheet_status: timesheet_status ? "Confirmed" : "",
      // payroll_category_id: "647f03605297cb0e8bf5f528",
      ...taskArr,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/confirm-works-form",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const getConfirmWork = async (item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worker_id: item?.worker_id,
      isSave: 0,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/confirm-works-form",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const changeStatus = async (status, item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      status: status,
      ids: item,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/change-status",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const inspectionUpload = async (file, item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      file: file,
      id: item?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/upload",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const getShiftDetails = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      cursor: worksite?.cursor,
      worksite_id: worksite.id,
    };

    let data = await WorkService?.get("/api/v1/worksite/shift-details", {
      params,
      authorization,
    });

    return data?.data;
  } catch (error) {
    return error;
  }
};

const approveWorkerShiftDetails = async (workersData) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      ids: workersData?.ids,
      from: workersData?.from,
      to: workersData?.to,
      worker_id: workersData?.worker_id,
      worksite_id: workersData?.worksite_id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/shift-details-approve",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const markAsSplitShift = async (workersData, selectedWorksiteId) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worker_id: workersData?.worker_id,
      worksite_id: selectedWorksiteId,
      worksite_timesheet_data_id: workersData?._id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/split-shift",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const getPrevShiftDetails = async (startDate) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await WorkService?.get(
      `/api/v1/worksite/shift-details?date=${startDate}&date_type=prev`,
      {
        authorization,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const getNextShiftDetails = async (endDate) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await WorkService?.get(
      `/api/v1/worksite/shift-details?date=${endDate}&date_type=next`,
      {
        authorization,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const deleteImageUpload = async (file, item) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      file: file,
      id: item?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/delete-uploaded-file",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateEditWorksite,
  GetWorkSite,
  GetWorkSiteById,
  GetWorkersType,
  ChangeWorksiteStatus,
  DeleteWorksitePayroll,
  DeleteWorkSiteImage,
  AddProductiveOrderTask,
  ChangeWorkerStatus,
  ChangeWorkerStatusResume,
  ChangeWorkerStatusFinish,
  ChangeWorkerStatusTransfer,
  ChangeWorkerStatusTerminate,
  ChangeWorkerStatusConfirm,
  ChangeWorkerStatusRoster,
  ChangeWorkerStatusUnRoster,
  MassClockIn,
  MassAbsense,
  MarkPresent,
  CancelWork,
  MassSignOffOpt,
  MassTransferOpt,
  ChangeWorkerStatusSignOff,
  SaveNotes,
  CancelAll,
  confirmWork,
  getConfirmWork,
  changeStatus,
  inspectionUpload,
  getShiftDetails,
  SplitDate,
  approveWorkerShiftDetails,
  markAsSplitShift,
  getPrevShiftDetails,
  getNextShiftDetails,
  deleteImageUpload,
  ChangeWorkerStatusOverride,
};
