import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Button, Modal, Popover } from "antd";
import { useParams } from "react-router-dom";
import {
  CancelWork,
  ChangeWorkerStatusFinish,
  ChangeWorkerStatusTerminate,
  GetWorkersType,
  MarkPresent,
} from "../../helper/worksite/worksite";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";
import { ButtonSpinner } from "../../components/spinners";
import { toast } from "react-toastify";
import WorkingWorkerDefaultImg from "../../assets/svg/workingWorkerDeafultImg";
import TransferModal from "./transferModal";
import AbsentModal from "./absentModal";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { PayRollService } from "../../config/axiosUrl";
import CreatingWorkShiftModal from "./CreatingWorkShiftModal";
import SplitShiftModal from "./splitShiftModal";
import { formatTime } from "./timeUtils";
const WorkingTable = ({
  work_status,
  worksite,
  setWorksite,
  selectedTab,
  setWorkerType,
}) => {
  //
  let { id } = useParams();
  let deafaulPage = {
    disclaimer: "Currently, there are no workers engaged in any work",
    redirect: "",
    svg: <WorkingWorkerDefaultImg />,
    tab: true,
  };
  const [timerId, setTimerId] = useState("");
  const [isLoaderFinish, setIsLoaderFinish] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [absenteModal, setAbsentModal] = useState(false);
  const [absenseType, setAbsenseType] = useState();
  const [assignTaskModal, setAssignTask] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const [selectedTimerItem, setSelectedTimerItem] = useState();
  const [displayTextWor, setDisplayTextWor] = useState(false);
  // const [data, setData] = useState(false);
  //

  useEffect(() => {
    setDisplayTextWor(false);
  }, []);

  const saveChanges = async () => {
    setIsLoading(true);
    let data = await ChangeWorkerStatusTerminate(selectedItem, worksite);
    if (data?.status) {
      toast.success(data?.message);
      setOpenTerminateModal(false);
      const param = { type: work_status, id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  const assignTaskWorker = async () => {
    setAssignTask(true);
  };
  const closeAssignTaskWorker = async () => {
    setAssignTask(false);
  };
  const handleWorksite = {
    getWorkers: async (work_status) => {
      let data = await GetWorkersType({ ...worksite, type: work_status });
      if (data?.status) {
        setWorksite({
          ...worksite,
          data: data?.data?.data,
          extra: data?.extra,
          worker_status: work_status,
          isShimmer: false,
          cursor: "",
        });
        return data;
      } else {
        setWorksite({
          ...worksite,
          isShimmer: false,
        });
        return data;
      }
    },
  };
  const openFinishModal = (item) => {
    setFinishModal(true);
    setSelectedTimerItem(item);
  };
  const closeFinishModal = () => {
    setFinishModal(false);
  };
  const finishedTimer = async () => {
    setIsLoaderFinish(true);
    setTimerId(selectedTimerItem?._id);
    let data = await ChangeWorkerStatusFinish(selectedTimerItem, worksite);
    if (data?.status) {
      toast.success("Status change Successfully");
      closeFinishModal();
      handleWorksite?.getWorkers(work_status);
      setIsLoaderFinish(false);
    }
  };
  // const transferWorker = async () => {
  //   setOpenTransferModal(true)
  // }
  // const terminateWorker = async () => {
  //   setOpenTerminateModal(true)
  // }
  const GetAbsenseType = async () => {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let data = await PayRollService?.get(
        `/api/v1/setting/payroll/get-absences-data?type=common`,
        {
          headers: authorization?.headers,
          // params: param
        }
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  };
  const absentWorker = async () => {
    setAbsentModal(true);
    let response = await GetAbsenseType();
    if (response?.status) {
      setAbsenseType(response?.data?.data);
    }
  };
  const presentWorker = async () => {
    let res = await MarkPresent(worksite, selectedItem);
    if (res?.status) {
      toast.success("Status change Successfully");
      handleWorksite?.getWorkers(work_status);
    }
  };

  const splitShift = () => {
    setIsSplit(true);
  };
  const closeSplitShift = () => {
    setIsSplit(false);
  };
  const CancelWorker = async () => {
    let res = await CancelWork(worksite, selectedItem);
    if (res?.status) {
      toast.success("Status change Successfully");
      handleWorksite?.getWorkers(work_status);
    }
  };
  useEffect(() => {
    if (selectedTab == "2") {
      setWorkerType("working");
      handleWorksite?.getWorkers(work_status);
    }
  }, [selectedTab]);
  const content = (
    <div>
      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={transferWorker}>Transfer</p> */}
      <p
        className="px-4 py-2 cursor-pointer border-b-2"
        onClick={selectedItem?.is_absence == 1 ? presentWorker : absentWorker}
      >
        {selectedItem?.is_absence == 1 ? "Mark Present" : "Mark Absent"}
      </p>

      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={terminateWorker}>Terminate</p> */}
      <p
        className="px-4 py-2 cursor-pointer border-b-2"
        onClick={assignTaskWorker}
      >
        Assign work
      </p>
      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={signoffWorker}>Sign Off</p> */}
      <p className="px-4 py-2 cursor-pointer" onClick={CancelWorker}>
        Cancel
      </p>
    </div>
  );
  const onCloseTransefer = () => {
    setOpenTransferModal(false);
  };
  const onClose = () => {
    setOpenTerminateModal(false);
  };
  const onCloseAbsentModal = () => {
    setAbsentModal(false);
  };

  let hourlyRateTask = worksite?.data?.reduce((accumulator, item) => {
    if (item.hourly_rate_task && item.hourly_rate_task.length > 0) {
      accumulator.push(...item.hourly_rate_task.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let productivityOrders = worksite?.data?.reduce((accumulator, item) => {
    if (item.productivity_orders && item.productivity_orders.length > 0) {
      accumulator.push(...item.productivity_orders.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let finalWork;
  if (hourlyRateTask && productivityOrders) {
    finalWork = hourlyRateTask.concat(productivityOrders).join(", ");
  }

  return (
    <>
      {worksite?.isShimmer ? (
        <div className="py-6 px-4 ">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : worksite?.data?.length > 0 ? (
        <div className="relative">
          {/* <div className="absolute top-[-65px] w-1/2 right-0 flex justify-end items-center gap-4">
            <Select defaultValue="Mass Options" className="border-black">
              <Select.Option value="Mass Options">Mass Options</Select.Option>
            </Select>
            <Button className="flex justify-center items-center p-1 pl-3 border border-[#d4d3d3]">
              Roaster Worker{" "}
              <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                <FaPlus />
              </div>
            </Button>
          </div> */}
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    Worker Name
                  </div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center"></div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {worksite?.data?.length > 0 &&
                worksite?.data?.map((item, index) => (
                  <tr key={index} className="border-b border-[#F0F0F0]">
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-start">
                        <div className="relative text-center mr-[10px]">
                          <img
                            src={
                              item?.users_profile_details?.data
                                ? Fileurl + item?.users_profile_details?.data
                                : DefaultCree8Img
                            }
                            alt=""
                            className="w-[50px] h-[50px] rounded mx-auto"
                          />
                          {item?.split_shifts?.length > 0 && (
                            <span className="text-[11px] text-[#FFA200] bg-[#FFA20021] border border-[#FFA200] px-[6px] py-[2px] relative top-[-15px]">
                              Split Shift
                            </span>
                          )}
                        </div>
                        <div>
                          <p className="font-medium">
                            {item?.users.length > 0 ? item.users[0].name : "-"}
                          </p>
                          <p className="text-[10px] text-[#707070]">
                            {finalWork ? `Work: ${finalWork}` : ""}
                            {/* Work : {`${hourlyRateTask},${productivityOrders}`} */}
                            {/* {item.work.map((item, index) => (
                        <span key={index} className="text-[#0F4C7D]">
                          {item}
                        </span>
                      ))} */}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        {item?.is_absence == 1 ? (
                          <p>Absent</p>
                        ) : (
                          <>
                            <div className="text-center mx-5">
                              <p className="text-lg">Started</p>
                              <p className="text-lg text-[#A1A1A1]">
                                {item?.started && formatTime(item.started)}
                              </p>
                            </div>
                            {/* {isLoader && item?.id === timerId ? <ButtonSpinner /> :
                        <div className="text-center mx-5 flex flex-col justify-center items-center cursor-pointer" onClick={() => resumeTimer(item)}>
                          <svg
                            id="_000000ff"
                            data-name="#000000ff"
                            xmlns="http://www.w3.org/2000/svg"
                            width="34.441"
                            height="34.441"
                            viewBox="0 0 34.441 34.441"
                          >
                            <path
                              id="Path_1407"
                              data-name="Path 1407"
                              d="M16.775,0h.885a17.182,17.182,0,0,1,7.367,1.877,17.252,17.252,0,0,1,9.413,14.839v.946A17.276,17.276,0,0,1,20.852,34.054a18.1,18.1,0,0,1-3.189.387h-.88A17.269,17.269,0,0,1,0,17.727V16.721A18.827,18.827,0,0,1,.3,14,17.121,17.121,0,0,1,3.548,6.768,17.231,17.231,0,0,1,16.775,0M10.763,10.763V23.678h4.305V10.763H10.763m8.61,0V23.678h4.305V10.763Z"
                              fill="#ffe097"
                            />
                          </svg>

                          <p className="text-[#000]">Resume</p>
                        </div>} */}
                            {isLoaderFinish && item?._id === timerId ? (
                              <ButtonSpinner />
                            ) : (
                              <div
                                className="text-center mx-5 flex flex-col justify-center items-center cursor-pointer"
                                onClick={() => openFinishModal(item)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="34.441"
                                  height="34.441"
                                  viewBox="0 0 34.441 34.441"
                                >
                                  <g
                                    id="Group_74214"
                                    data-name="Group 74214"
                                    transform="translate(0 -0.344)"
                                  >
                                    <circle
                                      id="Ellipse_27"
                                      data-name="Ellipse 27"
                                      cx="17.221"
                                      cy="17.221"
                                      r="17.221"
                                      transform="translate(0 0.344)"
                                      fill="#ff9595"
                                    />
                                    <rect
                                      id="Rectangle_1869"
                                      data-name="Rectangle 1869"
                                      width="12.101"
                                      height="12.101"
                                      rx="2"
                                      transform="translate(11.17 11.344)"
                                      fill="#fff"
                                    />
                                  </g>
                                </svg>

                                <p className="text-[#000]">Finished</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <Popover
                        placement="left"
                        content={content}
                        trigger="click"
                      >
                        <FaPlus
                          className="text-sm border-2 p-1 border-black w-[25px] h-[25px] flex justify-center items-center rounded-full cursor-pointer marginAuto"
                          onClick={() => (
                            setSelectedItem(item), setDisplayTextWor(false)
                          )}
                        />
                      </Popover>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <TableDefaultPage data={deafaulPage} />
      )}
      <TransferModal
        isOpen={openTransferModal}
        onClose={onCloseTransefer}
        worksite={worksite}
        setWorksite={setWorksite}
        selectedItem={selectedItem}
      />
      <Modal
        title="Are You Sure To Terminate This Worker"
        className="clockin-modal"
        open={openTerminateModal}
        onCancel={onClose}
        footer={[
          <Button
            key="save"
            onClick={saveChanges}
            className="btn btn-blue bg-blue-disable"
          >
            {isLoading ? <ButtonSpinner /> : "Terminate"}
          </Button>,
        ]}
      >
        <p className="mt-7">
          Name : <span className="text-[#A1A1A1] ml-8">Smith Work</span>
        </p>
      </Modal>
      {absenteModal && (
        <AbsentModal
          onCloseAbsentModal={onCloseAbsentModal}
          openAbsentModal={absenteModal}
          absenseType={absenseType}
          worksite={worksite}
          setWorksite={setWorksite}
          selectedItem={selectedItem}
          workStatus={"Working"}
        />
      )}
      {assignTaskModal && (
        <CreatingWorkShiftModal
          assignTaskModal={assignTaskModal}
          closeAssignTaskWorker={closeAssignTaskWorker}
          selectedItem={selectedItem}
          isComplted={false}
          isSplit={isSplit}
          // data={data}
          displayTextWor={displayTextWor}
          shiftOpen={splitShift}
          workingTable={true}
          handle={() => handleWorksite?.getWorkers(work_status)}
        />
      )}
      {isSplit && (
        <SplitShiftModal
          // setData={setData}
          setDisplayTextWor={setDisplayTextWor}
          closeModal={closeSplitShift}
          selectedItem={selectedItem}
          getWorkersList={() => handleWorksite?.getWorkers(work_status)}
        />
      )}
      {finishModal && (
        <Modal
          title={
            selectedTimerItem?.worksite_timesheet_workdone?.length == 0
              ? "Worker does not have any work assigned. Do you want to finish his shift?"
              : "Do you want to finish his shift?"
          }
          className="clockin-modal"
          open={finishModal}
          onCancel={closeFinishModal}
          footer={[
            <>
              <Button
                key="save"
                onClick={finishedTimer}
                className="btn btn-blue bg-blue-disable"
              >
                {isLoaderFinish ? <ButtonSpinner /> : "Yes"}
              </Button>
              <Button
                onClick={closeFinishModal}
                className="btn bg-blue-disable"
              >
                No
              </Button>
            </>,
          ]}
        >
          <p className="mt-7">
            {selectedTimerItem?.worksite_timesheet_workdone?.length == 0
              ? "This worker has not been allocated a task are you sure you want it finish his work?"
              : "Are you sure you want it finish his work?"}
          </p>
        </Modal>
      )}
    </>
  );
};

export default WorkingTable;
