import React, { Fragment, useState } from "react";
import CodeTable from "../../../../../shared/DataTable/CodeTable";
import { Button, Collapse, Select } from "antd";
import PayrollCategories from "../../../../../shared/DataTable/PayrollCategories";
import { FaPlus } from "react-icons/fa";
import {
  PayRollService,
  UserService,
  WorkService,
} from "../../../../../config/axiosUrl";
import SingleWorkersProfile from "../SingleWorkerProfile";
import { Controller, useForm } from "react-hook-form";
import TerminatedProfile from "./TerminatedProfile";
import WorkerDetailsInduction from "./WorkerDetailsInduction";
import { TableDefaultSvg } from "../../../../../assets/svg/tableDefaul";
import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { ButtonSpinner } from "../../../../../components/spinners";
import PayrollCategoriesWorkers from "./PayrollCategoriesWorkers";
import { toast } from "react-toastify";
import { Modal } from "antd";
import useWindowDimensions from "../../../../../utils/routes/useWindowDimensions";

const { Panel } = Collapse;

const ActiveProfile = ({ worker, setWorker, handle }) => {
  const { width } = useWindowDimensions();
  let deafaulPage = {
    disclaimer: "No Payroll Categories",
    description: "Payroll Categories not selected",
    buttonText: "",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const { handleSubmit, control, setValue, reset } = useForm();

  const [task, setTask] = useState();
  const [payroll, setPayroll] = useState();

  const [site, setWorksite] = useState([]);
  const [selectedWorksiteId, setSelectedWorksiteId] = useState("");
  const [selectedPayrollWorksite, setselectedPayrollWorksite] = useState("");
  const [selectedPayrollData, setselectedPayrollData] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [notes, setNotes] = useState("");

  const { id } = useParams();

  //  taskbreakdown
  const workTaskBreakdown = useCallback(() => {
    UserService.post(
      `/api/v1/personal/workers/Worksite-task-breakdown?worker_id=${id}&worksite_id=${selectedWorksiteId}`
    )
      .then((res) => {
        setTask(res?.data?.data?.Worksite_task_breakdown_data || []);
      })
      .catch((error) => {
        console.error("Error fetching site manager history:", error);
      });
  }, [selectedWorksiteId]);

  useEffect(() => {
    workTaskBreakdown();
  }, [selectedWorksiteId]);

  // for worksite name

  const Worksite = useCallback(() => {
    WorkService.get("/api/v1/worksite/get-worksite-data").then((res) => {
      setWorksite(res?.data?.data?.data || []);
    });
  }, []);

  useEffect(() => {
    Worksite();
  }, []);

  const handleSelectChange = (value) => {
    setSelectedWorksiteId(value);
  };

  const handleSelectChangePayroll = (value) => {
    setselectedPayrollWorksite(value);
  };

  const handlePayrollChange = (value) => {
    setselectedPayrollData(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setselectedPayrollWorksite("");
    setselectedPayrollData("");
    reset();
  };

  // payroll

  const categoryPayroll = useCallback(() => {
    PayRollService.get(
      `/api/v1/payroll/setting/add-new-payroll-category?worker_id=${id}&worksite_id=${selectedPayrollWorksite}`
    )
      .then((res) => {
        setPayroll(res?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching PAYROLL:", error);
      });
  }, [selectedPayrollWorksite, id]);

  useEffect(() => {
    categoryPayroll();
  }, [selectedPayrollWorksite, id]);

  //  save data

  const submit = useCallback(async () => {
    await new Promise((res) => {
      setSubmitting(true);
      res(true);
    });

    UserService.post("/api/v1/worksite/assign-payroll-category-worker?dev=4", {
      worker_id: id,
      worksite_id: selectedPayrollWorksite,
      ids: [selectedPayrollData],
    })

      .then((res) => {
        setNotes(res?.data?.data?.data || []);
        setSubmitting(false);
        handleCancel();
        handle.getWorker();
      })
      .catch((error) => {
        console.error("Error fetching notes :", error);
        toast.error("Failed to assign worker");
        setSubmitting(false);
      });
  }, [selectedPayrollWorksite, id, selectedPayrollData]);

  return (
    <div
      style={{
        overflowY: width <= 767 ? "scroll" : "visible",
        maxHeight: width <= 767 ? "75vh" : "auto",
      }}
    >
      <div className="details mt-5 bg-white p-4">
        <SingleWorkersProfile
          worker={worker}
          setWorker={setWorker}
          handle={handle}
        />

        {worker?.status == 3 ? (
          <div className="mt-8 lg:overflow-hidden overflow-x-auto">
            <TerminatedProfile
              worker_termination_logs={worker?.worker_termination_logs}
              handle={handle}
            />
          </div>
        ) : worker?.status == 1 ? (
          <>
            {/* Worker Full Details */}
            <div className="worker-full-details mt-5 ">
              <p className="fs-18">Worker Full Details</p>

              <div
                className={
                  width > 767
                    ? "flex justify-between w-full items-center mt-3"
                    : "flex justify-between w-full items-center mr-[14px] overflow-auto mt-3"
                }
              >
                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                  <p className="fs-14 text-[#A1A1A1] mr-5">Shift Worked</p>
                  <p className="fs-14">N/A</p>
                </div>
                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                  <p className="fs-14 text-[#A1A1A1] mr-5">Hours Worked</p>
                  <p className="fs-14">N/A</p>
                </div>
                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                  <p className="fs-14 text-[#A1A1A1] mr-5">
                    Productivity Average
                  </p>
                  <p className="fs-14">N/A</p>
                </div>
                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                  <p className="fs-14 text-[#A1A1A1] mr-5">Hourly Average</p>
                  <p className="fs-14">N/A</p>
                </div>
                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                  <p className="fs-14 text-[#A1A1A1] flex items-center">
                    Induction
                    <span className="fs-10 ml-3 text-[#FF0000] border rounded-md px-2 py-1 border-[#FF0000] bg-[#FF000033]">
                      Induction Incomplete
                    </span>
                    {false && (
                      <span className="fs-10 ml-3 text-[#41C700] border rounded-md px-2 py-1 border-[#41C700] bg-[#42c70033]">
                        Induction Completed
                      </span>
                    )}
                  </p>
                  <p className="fs-14">N/A</p>
                </div>
              </div>
            </div>

            <div className="payroll mt-8">
              <div className="flex justify-between items-center">
                <p className={width > 767 ? "fs-18" : "fs-14"}>
                  Payroll Categories
                </p>
                <Button
                  onClick={showModal}
                  className={
                    width > 767
                      ? "flex justify-center items-center py-3 border border-[#111111] h-[42px] "
                      : "flex justify-center items-center py-1 border border-[#111111] h-[32px] fs-10"
                  }
                >
                  Add New Worksite
                  <div
                    className={
                      width > 767
                        ? "bg-orange-500 p-2 rounded-md text-white ml-3"
                        : "bg-orange-500 p-1 rounded-md text-white ml-1"
                    }
                  >
                    <FaPlus />
                  </div>
                </Button>
              </div>

              <div className="mt-8 lg:overflow-hidden overflow-x-auto">
                <PayrollCategoriesWorkers worker={worker} handle={handle} />
              </div>

              <div className="mt-8 lg:overflow-hidden overflow-x-auto"></div>
            </div>
            <div className="breakdown mt-5 bg-white p-4">
              <p className="fs-18 mb-5">Task Breakdown</p>

              <label>Worksite</label>
              <Select
                className="lg:w-3/5 w-full block mt-3 mb-20"
                onChange={handleSelectChange}
              >
                {site?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>

              <Fragment>
                <table className="w-full">
                  <thead className="bg-[#E8F3FF] text-center">
                    <tr className="text-left">
                      <th className=" px-4 py-3 ">
                        <div className="flex items-center justify-start">
                          Code
                        </div>
                      </th>
                      <th className=" px-4 py-3 ">
                        <div className="flex items-center justify-start">
                          Task
                        </div>
                      </th>
                      <th className=" px-4 py-3 ">
                        <div className="flex items-center justify-start">
                          Hours
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {task?.map((item, index) =>
                      item?.worksite_timesheet_workdone?.productivity_orders.map(
                        (order, orderIndex) => (
                          <tr
                            key={orderIndex}
                            className="border-b border-[#F0F0F0]"
                          >
                            <td className="px-4 py-3">{order.code}</td>
                            <td className="px-4 py-3">{order.name}</td>
                            <td className="px-4 py-3">{order.time_spent}</td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </Fragment>
              {task?.every(
                (item) =>
                  item?.worksite_timesheet_workdone?.productivity_orders
                    .length === 0
              ) && (
                <div className="mt-8 lg:overflow-hidden overflow-x-auto">
                  <CodeTable />
                </div>
              )}
            </div>
          </>
        ) : (
          <WorkerDetailsInduction
            worker={worker}
            setWorker={setWorker}
            handle={handle}
          />
        )}
      </div>
      <Collapse accordion className="bg-white border-0">
        <Panel header={"Casual Work"}>
          <SingleWorkersProfile
            worker={worker}
            setWorker={setWorker}
            handle={handle}
          />
        </Panel>
      </Collapse>

      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit(submit)}>
          <label>Worksite</label>
          <Controller
            name="selectedPayrollWorksite"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                className="lg:w-5/5 w-full block mt-3 mb-20"
                onChange={(value) => {
                  field.onChange(value);
                  handleSelectChangePayroll(value);
                }}
                value={field.value}
              >
                {site?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          <label>Payroll Categories</label>
          <Controller
            name="selectedPayrollData"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                className="lg:w-5/5 w-full block mt-3 mb-20"
                onChange={(value) => {
                  field.onChange(value);
                  handlePayrollChange(value);
                }}
                value={field.value}
              >
                {payroll &&
                  Array.isArray(payroll) &&
                  payroll.map((item, itemIndex) =>
                    Array.isArray(item.payroll_category)
                      ? item.payroll_category.map((payroll_category, index) => (
                          <Select.Option
                            key={`${itemIndex}-${index}`}
                            value={payroll_category._id}
                          >
                            {payroll_category.name}
                          </Select.Option>
                        ))
                      : null
                  )}
              </Select>
            )}
          />

          <button
            className="mx-auto bg-[#0F4C7D] text-white px-8 py-2 rounded-lg"
            type="submit"
            disabled={submitting}
          >
            {submitting ? <ButtonSpinner className="mx-8" /> : "Save"}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default ActiveProfile;
