import React, { Fragment, useCallback, useEffect, useForm } from "react";
import CreateNewSiteAllowance from "./CreateNewSiteAllowanceModal";
import { Button } from "antd";
import { FaAngleDown, FaAngleUp, FaPlus, FaSearch } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import PopupSelector from "./PopupSelector";
import { Archive, Edit, Trash } from "../../../../../assets/svg";
import Pagination from "../../../../../components/PaginationAPI/Pagination";
import { PayRollService, UserService } from "../../../../../config/axiosUrl";
import { toast } from "react-toastify";
import Confirmation from "../../../../../components/Confirmation";

const PayrollAllowance = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(-1);
  const [search, setSearch] = useState("");
  const [getAllowance, setGetAllowance] = useState(true);

  const toggleExpand = function (index) {
    setExpanded((prev) => (prev === index ? -1 : index));
  };
  const [popupData, setPopupData] = useState({
    tasks: [],
    payroll: [],
    taskLoading: true,
    payrollLoading: true,
    allLoaded: false,
  });
  const [visibleModal, setVisibleModal] = useState(false);

  const [selector, setSelector] = useState(false);

  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    isDeleting: false,
  });

  const [assignState, setAssignState] = useState({
    selected: [],
    visible: false,
    type: "",
    currentEdit: {},
    taskSubmitting: false,
    payrollSubmitting: false,
  });
  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const [payrollAllowance, setPayrollAllowance] = useState({
    data: [],
    payroll_category_allowance_payroll_category_data: [],
    pageLoading: true,
    loading: true,
    search: "",
  });

  const [taskAllowance, setTaskAllowance] = useState({
    data: [],
    loading: true,
    search: "",
  });

  const pageFunctions = {
    toggleModal: useMemo(
      () =>
        function () {
          setVisibleModal((prev) => !prev);
        },
      []
    ),
    navigate: useMemo(
      () =>
        function (id) {
          navigate(`/admin/settings/payroll-category/${id || ""}`);
        },
      []
    ),
    onEdit: useMemo(
      () =>
        function (id) {
          navigate(`/admin/settings/payroll-category/${id || ""}`);
        },
      []
    ),
    onSelect: useCallback((id) => {
      setAssignState((prev) => {
        let selected = prev.selected;

        if (selected.includes(id)) {
          selected.splice(selected.indexOf(id), 1);
        } else {
          selected.push(id);
        }

        return {
          ...prev,
          selected,
        };
      });
    }, []),

    toggleSelector: useMemo(
      () =>
        function () {
          setSelector((prev) => !prev);
        },
      []
    ),

    onDelete: useMemo(
      () => (current) => {
        setDeleteModal((prev) => ({
          ...prev,
          visible: !prev.visible,
          current: prev.visible ? {} : current,
        }));
      },
      []
    ),

    onDeleteFail: useMemo(
      () => () => {
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: false,
        }));
      },
      []
    ),
    onDeleteSuccess: useMemo(
      () => () => {
        setDeleteModal((prev) => ({
          ...prev,
          visible: false,
          current: {},
          isDeleting: false,
        }));
      },
      []
    ),

    toggleSelect: useCallback((currentEdit) => {
      setAssignState((prev) => ({
        ...prev,
        visible: !prev.visible,
        currentEdit,
        taskSubmitting: false,
        selected:
          currentEdit?.payroll_category_allowance_payroll_category_data?.map(
            (item) => item?.payroll_category_id
          ) || [],
      }));
    }, []),
  };

  const apiFunctions = {
    get: useCallback((res) => {
      setPayrollAllowance((prev) => ({
        ...prev,
        data: res?.data?.data?.data || [],
        loading: false,
      }));
    }, []),
    getEmp: useCallback(function (search = "", cursor = "") {
      PayRollService.get(
        `/api/v1/payroll/setting/get-payroll-category-data?search=${search}&cursor=${cursor}`
      ).then((res) => {
        setPopupData((prev) => {
          const filtered = search ? res?.data?.data?.data : [];
          if (!search) {
            const tasks = prev.tasks.concat(res?.data?.data?.data);
            const ids = [];

            tasks.forEach((item) => {
              if (!ids.includes(item.id)) {
                ids.push(item.id);
                filtered.push(item);
              }
            });
          }
          return {
            ...prev,
            tasks: filtered,
            taskLoading: false,
            allLoaded: res?.data?.extra?.totalItems <= filtered.length,
          };
        });
      });
    }, []),
    deleteWorker: useCallback(async () => {
      await new Promise((resolve) => {
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: true,
        }));
        resolve(true);
      });
      let { current } = deleteModal;
      PayRollService.post(
        "/api/v1/setting/payroll/delete-assign-payroll-category-payroll-allowance?dev=3",
        {
          id: current?.id,
          payroll_category_allowance_id: current?.payroll_category_allowance_id,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            setAssignState((prev) => ({
              ...prev,
              reget: !prev.reget,
            }));
            pageFunctions.onDeleteSuccess();
            setGetAllowance((prev) => !prev);
          } else {
            pageFunctions.onDeleteFail();
            toast.success(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error("Failed to delete worker");
          pageFunctions.onDeleteFail();
        });
    }, [deleteModal, payrollAllowance.data]),

    assignTaskAllowance: useCallback(async () => {
      await new Promise((resolve) => {
        setAssignState((prev) => ({
          ...prev,
          taskSubmitting: true,
        }));
        resolve(true);
      });

      await PayRollService.post(
        "api/v1/setting/payroll/assign-payroll-category-to-payroll-allowance",
        {
          payrollCategoryIds: assignState.selected || [],
          payroll_category_allowance_id: assignState.currentEdit.id,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            const index = payrollAllowance.data.findIndex(
              (item) => item.id === assignState.currentEdit.id
            );

            const updatedPayrollAllowanceData = [...payrollAllowance.data];
            updatedPayrollAllowanceData[index] = res.data.data.data;

            setPayrollAllowance({
              ...payrollAllowance,
              data: updatedPayrollAllowanceData,
            });

            pageFunctions.toggleSelect();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setAssignState((prev) => ({
            ...prev,
            taskSubmitting: false,
          }));
        });
    }, [assignState, payrollAllowance, pageFunctions, toast]),
  };
  useEffect(() => {
    apiFunctions.getEmp();
  }, []);

  const arciveAgreements = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post(
      "api/v1/payroll/setting/archive-payroll-category-allowance-by-id",
      {
        id: onArchive.data?.id || "",
      }
    )
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Agreements");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  return (
    <div>
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Payroll Category Allowance`}
        details={onArchive.data?.name}
        onConfirm={arciveAgreements}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />
      <Confirmation
        title={"Are sure to delete"}
        open={deleteModal.visible}
        details={deleteModal.current?.payroll_category?.name || ""}
        detailsTitle="Name"
        onCancel={() => pageFunctions.onDelete()}
        onConfirm={apiFunctions.deleteWorker}
        isSubmitting={deleteModal?.isDeleting}
      />
      <PopupSelector
        visible={assignState.visible}
        title={`Search Payroll Catergory name`}
        data={popupData.tasks}
        onSearch={apiFunctions.getEmp}
        onCheck={pageFunctions.onSelect}
        onHide={() => pageFunctions.toggleSelect()}
        selected={assignState.selected}
        isSubmitting={assignState.taskSubmitting}
        onSubmit={apiFunctions.assignTaskAllowance}
        searchPlaceholder="Search by Payroll Categories Name"
        allLoaded={popupData.allLoaded}
        onScrollEnd={(search) =>
          apiFunctions.getEmp(
            search,
            popupData.tasks[popupData.tasks.length - 1].id
          )
        }
      />

      <div className="flex justify-between items-center bg-white p-5">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Payroll Category Allowance Name"
            onChange={({ target: { value } }) => setSearch(value)}
            className="h-[31px] bg-transparent text-sm w-full px-2"
          />
        </div>
        <Button
          onClick={() => pageFunctions.onEdit()}
          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
        >
          Create New
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>

      <div className="bg-white">
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">Name</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">
                  Hourly Rate
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {payrollAllowance?.data?.map((item, index) => (
              <Fragment key={index?.id}>
                <tr className="border-b-2 border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-start">
                      {item.name}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.hourly_rate}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex gap-4 items-center justify-center">
                      <button
                        onClick={() => pageFunctions.onEdit(item?.id)}
                        className="text-black bg-transparent border-none"
                      >
                        <Edit title="Edit" />
                      </button>
                      <button
                        onClick={() => onArchiveClick(item)}
                        className="text-black bg-transparent border-none"
                      >
                        <Archive title="Archive" />
                      </button>

                      <Button
                        className="px-2 bg-[#FE650C]"
                        onClick={() => toggleExpand(index)}
                      >
                        {expanded === index ? (
                          <FaAngleUp className="scale-125 fill-white" />
                        ) : (
                          <FaAngleDown className="scale-125 fill-white" />
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>

                {/* inside toggle delete button */}

                {expanded === index && (
                  <Fragment>
                    {item?.payroll_category_allowance_payroll_category_data?.map(
                      (item) => (
                        <tr>
                          <td colSpan={3}>
                            <div className="flex justify-center py-4 px-2">
                              <div className="flex justify-between w-[80%] px-2  border-b-2 pb-2 border-b-[#F0F0F0]">
                                <p>{item.payroll_category.name}</p>
                                <div className="cursor-pointer">
                                  <Trash
                                    title="Trash"
                                    onClick={() => pageFunctions.onDelete(item)}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}

                    {/* toggle section */}
                    <tr>
                      <td colSpan={3}>
                        <div className="flex justify-end w-[90%] py-2">
                          <Button
                            className="flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px]"
                            onClick={() => pageFunctions.toggleSelect(item)}
                          >
                            Add New
                            <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                              <FaPlus />
                            </div>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white">
        <Pagination
          api="api/v1/setting/payroll/get-payroll-allowance"
          apiService={PayRollService}
          getRes={apiFunctions.get}
          extraParams={{ search }}
          dependecy={[getAllowance, search, onArchive.reget]}
        />
      </div>
    </div>
  );
};

export default PayrollAllowance;
