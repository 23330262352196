import { Button, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaPlug, FaPlus, FaTrash } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MultySelect from "../../multySelect/multyselect";
import {
  GetAreaById,
  MergeProductiveOrder,
} from "../../../helper/worksite/worksiteDetails.js/areas";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../spinners";

const MergeCreate = () => {
  const { id } = useParams();
  const { area } = useParams();
  let navigate = useNavigate();

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Productive Orders",
    searchBarTitle: "Search by Productive Orders",
    buttonTitle: "Add",
  };

  const initialMergeCreate = {
    code: "",
    name: "",
    productiveOrderOptions: [],
    selectedProductiveOptions: [],
    isLoading: false,
    errors: {
      code: "",
      name: "",
      selectedProductiveOptions: "",
    },
  };

  let code = useRef();
  let name = useRef();

  const [multySelect, setMultySelect] = useState(initialMultySelectValue);
  const [mergeCreate, setMergeCreate] = useState(initialMergeCreate);

  const handleMergeCreate = {
    getMergeHistory: () => {
      navigate(
        `/admin/worksite/details/areas/cards/merge/history/${id}/${area}`
      );
    },
    getCards: () => {
      navigate(`/admin/worksite/details/areas/cards/${id}/${area}`);
    },
    get: (e) => {
      setMergeCreate({
        ...mergeCreate,
        [e?.target?.name]: e?.target?.value,
      });
    },
    create: async () => {
      if (!mergeCreate?.code) {
        setMergeCreate({
          ...mergeCreate,
          errors: {
            ...mergeCreate?.initialMergeCreate,
            code: "Enter code",
          },
        });
        code?.current?.focus();
      } else if (!mergeCreate?.name) {
        setMergeCreate({
          ...mergeCreate,
          errors: {
            ...mergeCreate?.initialMergeCreate?.errors,
            name: "Enter name",
          },
        });
        name?.current?.focus();
      } else if (multySelect?.selectedOption?.length < 2) {
        setMergeCreate({
          ...mergeCreate,
          errors: {
            ...mergeCreate?.initialMergeCreate?.errors,
            selectedProductiveOptions: "Add atleast 2 Productive Orders",
          },
        });
      } else {
        setMergeCreate({
          ...mergeCreate,
          isLoading: true,
          errors: {
            ...mergeCreate?.initialMergeCreate?.errors,
          },
        });

        const params = {
          area_id: area,
          worksite_id: id,
          code: mergeCreate?.code,
          name: mergeCreate?.name,
          productivity_order_ids: multySelect?.selectedOption,
          is_save: 1,
        };

        let data = await MergeProductiveOrder(params);
        if (data?.status) {
          toast?.success(data?.message);
          handleMergeCreate?.getCards();
          setMergeCreate(initialMergeCreate);
        } else {
          toast?.error(data?.message);
          setMergeCreate({
            ...mergeCreate,
            isLoading: false,
          });
        }
      }
    },
    delete: (id) => {
      let getSelectedOption = [...multySelect?.selectedOption];
      let deleteIndex = getSelectedOption?.indexOf(id);
      getSelectedOption?.splice(deleteIndex, 1);

      setMultySelect({
        ...multySelect,
        selectedOption: getSelectedOption,
      });
    },
    getMerge: async () => {
      const params = {
        id: area,
      };
      let data = await GetAreaById(params);

      if (data?.status) {
        setMergeCreate({
          ...mergeCreate,
          productiveOrderOptions: [...data?.data?.data?.productivity_orders],
        });
      }
    },
    addProductiveOrder: () => {
      setMultySelect({
        ...multySelect,
        isMultySelect: true,
        options: mergeCreate?.productiveOrderOptions,
        selectedOption: [],
        isLoading: false,
        isShimmer: false,
        title: "Available Productive Orders",
        searchBarTitle: "Search by Productive Orders",
        buttonTitle: "Add",
      });
    },
    createProductiveOrder: () => {
      setMultySelect({
        ...multySelect,
        isMultySelect: false,
      });
    },
    search: () => {},
    addTask: () => {},
  };

  useEffect(() => {
    (async () => {
      handleMergeCreate?.getMerge();
    })();
  }, []);

  return (
    <>
      <div>
        <div className="bg-[#E8E8E8] py-3 flex px-3">
          <Button className="justify-center items-center px-4 mr-3 h-[42px]  w-42 btn-blue btn">
            Merge
          </Button>
          <Button
            onClick={handleMergeCreate?.getMergeHistory}
            className="justify-center items-center px-4 mr-3 h-[42px] text-cyan-500 w-42 border border-cyan-500"
          >
            Merging History
          </Button>
        </div>

        <div>
          <div className="py-5 " onClick={handleMergeCreate?.getCards}>
            <div className="flex">
              <div>
                <div className="pt-2 pl-4 pr-3">
                  <FaArrowLeft />
                </div>
              </div>
              <div className="text-xl">Merging Productivity Orders</div>
            </div>
          </div>

          <div className="border border-gray-200" />
          <div className="px-6 py-4">
            <div className="py-5 text-xl">Merging Productivity Orders</div>

            <div className="text-slate-400 text-sm ">
              The selected productivity orders will have their work and
              estimations combined under a new productivity order with the
              abbreviation and name entered below.
            </div>

            <div>
              <div className="flex py-4">
                <div>
                  <div>
                    <div>
                      <label htmlFor="code">Code</label>
                    </div>
                    <div>
                      <Input
                        id="code"
                        ref={code}
                        className={
                          mergeCreate?.errors?.code
                            ? "w-50 mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-50 mt-2"
                        }
                        value={mergeCreate?.code}
                        onChange={handleMergeCreate?.get}
                        name="code"
                        autoComplete="off"
                      />
                      {mergeCreate?.errors?.code && (
                        <p className="pt-2 text-red-600 text-sm">
                          {mergeCreate?.errors?.code}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="name">Name</label>
                  </div>
                  <div>
                    <Input
                      id="name"
                      ref={name}
                      className={
                        mergeCreate?.errors?.name
                          ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                          : "w-full mt-2"
                      }
                      value={mergeCreate?.name}
                      onChange={handleMergeCreate?.get}
                      name="name"
                      autoComplete="off"
                    />
                    {mergeCreate?.errors?.name && (
                      <p className="pt-2 text-red-600 text-sm">
                        {mergeCreate?.errors?.name}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="border border-gray-200 " />
          </div>

          <div className="py-6">
            <div className="text-xl flex px-6 justify-between">
              <div className="pt-2">
                Add the Productivity Orders that need to be merged
                <div>
                  {mergeCreate?.errors?.selectedProductiveOptions && (
                    <p className="pt-2 text-red-600 text-sm">
                      {mergeCreate?.errors?.selectedProductiveOptions}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <Button
                  onClick={handleMergeCreate?.addProductiveOrder}
                  className="flex justify-center items-center  border border-[#111111] h-[42px]"
                >
                  Add New
                  <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                    <FaPlus />
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="border border-gray-200 " />
          </div>
        </div>

        <div className="px-8">
          {multySelect?.options?.map((task, i) => {
            return (
              <>
                {multySelect?.selectedOption?.includes(task?.id) && (
                  <>
                    <div className="flex py-4 justify-between">
                      <div>{task?.name}</div>
                      <div>
                        <FaTrash
                          onClick={() => handleMergeCreate?.delete(task?.id)}
                        />
                      </div>
                    </div>
                    <div className="border border-gray-200" />
                  </>
                )}
              </>
            );
          })}
        </div>

        <div className="pb-4">
          <div className="py-8">
            <Button
              className="float-right	justify-center items-center px-4 mr-3 h-[42px]  w-1/5 btn-blue btn"
              onClick={handleMergeCreate?.create}
            >
              {mergeCreate?.isLoading ? <ButtonSpinner /> : "Merge"}
            </Button>
          </div>
        </div>
      </div>

      {multySelect?.isMultySelect && (
        <MultySelect
          data={multySelect}
          setData={setMultySelect}
          search={handleMergeCreate?.search}
          submit={handleMergeCreate?.createProductiveOrder}
        />
      )}
    </>
  );
};

export default MergeCreate;
