import React, { Fragment, useCallback, useState } from "react";
import { ArchiveDefault, Unarchive } from "../../../../assets/svg";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { PayRollService } from "../../../../config/axiosUrl";
import { useNavigate } from "react-router-dom";
import Confirmation from "../../../../components/Confirmation";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import Pagination from "../../../../components/PaginationAPI/Pagination";
import { NoSearchTableSvg } from "../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../components/noOption/noOption";
import DefaultPage from "./Defaultpage";
const Absence = () => {
  const navigate = useNavigate();
  const [archivedPC, setArchivedPC] = useState({
    data: [],
    search: "",
    loading: true,
  });

  const [unarchive, setUnarchive] = useState({
    isSubmitting: false,
    visible: false,
    data: {},
    reget: false,
  });

  const getArchivePC = useCallback((res) => {
    setArchivedPC((prev) => ({
      ...prev,
      data: res?.data?.data?.data || [],
      loading: false,
    }));
  }, []);

  const unArchive = useCallback(async (id) => {
    await new Promise((resolve) => {
      setUnarchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });

    PayRollService.post("api/v1/payroll/setting/reactive-absences-by-id", {
      id,
    })
      .then((res) => {
        if (res?.data?.status) {
          setUnarchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setUnarchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.status);
        }
      })
      .catch((err) => {
        setUnarchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Reactivate Absence");
      });
  }, []);

  const onReactiveClick = useCallback((data) => {
    setUnarchive((prev) => ({
      ...prev,
      visible: !!data,
      data,
    }));
  }, []);

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };
  return (
    <Fragment>
      <Confirmation
        open={unarchive.visible}
        isSubmitting={unarchive.isSubmitting}
        title="Are you sure you want to reactivate this Payroll Category"
        details={unarchive.data?.name}
        onCancel={() => onReactiveClick()}
        onConfirm={() => unArchive(unarchive.data.id)}
        buttonText="Reactivate"
      />
      {archivedPC.loading ? (
        <TextShimmer data={{ line: 20, gap: 15 }} />
      ) : (
        <Fragment>
          <div className="flex justify-between items-center bg-white p-5">
            <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
              <FaSearch className="ml-2" />
              <input
                placeholder="Search by Absences"
                className="h-[31px] bg-transparent text-sm w-full px-2"
                onChange={({ target: { value: search } }) =>
                  setArchivedPC((prev) => ({ ...prev, search }))
                }
              />
            </div>
            <button
              className="text-[#FE650C] underline"
              onClick={() => navigate("/admin/settings/payroll/absences/1/1")}
            >
              View all Active Absences
            </button>
          </div>
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3 w-[80%]">
                  <div className="flex items-center ">Name</div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {archivedPC.data.length > 0 ? (
                archivedPC.data.map((item, index) => (
                  <tr className="border-b-2 border-[#F0F0F0]" key={index}>
                    <td className="px-4 py-3">
                      <div className="flex items-center">{item.name}</div>
                    </td>
                    <td className="px-4 py-3">
                      <div
                        className="flex items-center justify-center gap-1 select-none cursor-pointer"
                        onClick={() => onReactiveClick(item)}
                      >
                        <Unarchive /> Reactivate
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="px-4 py-3 text-center">
                    {archivedPC.search ? (
                      <NoTableOption data={noSearchOption} />
                    ) : (
                      <DefaultPage />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Fragment>
      )}

      <Pagination
        api="api/v1/payroll/setting/get-archive-absences-data"
        apiService={PayRollService}
        getRes={getArchivePC}
        extraParams={{
          search: archivedPC.search,
        }}
        dependecy={[archivedPC.search, unarchive.reget]}
      />
    </Fragment>
  );
};

export default Absence;
