import React, { useCallback, useEffect, useState } from "react";
import AgreementTable from "../../../../../shared/DataTable/AgreementTable";
import {
  DeleteAgreement,
  GetAgreement,
} from "../../../../../helper/setup/payroll/agreement";
import { FaSearch } from "react-icons/fa";
import { SearchData } from "../../../../../helper/global/global";
import { PayRollService } from "../../../../../config/axiosUrl";
import { TablePagination } from "../../../../../components/pagination/pagination";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { TableDefaultSvg } from "../../../../../assets/svg/tableDefaul";
import { NoSearchTableSvg } from "../../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { toast } from "react-toastify";
import Confirmation from "../../../../../components/Confirmation";

const Payroll = () => {
  const initialvalues = {
    data: [],
    search: "",
    extra: {},
    selectedData: {},
    isShimmer: true,
    isLoading: false,
    pagination: {
      api: "/api/v1/payroll/setting/get-agreement-data",
      service: PayRollService,
    },
  };
  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });
  const [agreement, setAgreement] = useState(initialvalues);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [cursorvalue, setCursorValue] = useState();

  let deafaulPage = {
    disclaimer: "No Agreement Found",
    description: "Create Your First Agreement",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "/admin/settings/payroll/agreements/create",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handleAgreement = {
    search: async (e) => {
      setAgreement({
        ...agreement,
        [e?.target?.name]: e?.target?.value,
      });
      const params = {
        value: e?.target?.value,
        api: "/api/v1/payroll/setting/get-agreement-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setAgreement({
          ...agreement,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
        });
      }
    },
    delete: async () => {
      setAgreement({
        ...agreement,
        isLoading: true,
      });
      let allData = [...agreement?.data];
      allData?.splice(agreement?.selectedData?.index, 1);

      let data = await DeleteAgreement(agreement?.selectedData);
      if (data?.status) {
        setAgreement({
          ...agreement,
          data: allData,
          extra: {
            ...agreement?.extra,
            totalItems: agreement?.extra?.totalItems - 1,
          },
          selectedData: {},
          isLoading: false,
        });
        setModalVisible(false);
      } else {
        setAgreement({
          ...agreement,
          isLoading: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      let data = await GetAgreement(cursorvalue);

      setCursorValue(data?.extra?.cursor);
      if (data?.status) {
        setAgreement({
          ...agreement,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
        });
      }
    })();
  }, [onArchive.reget]);

  const arciveAgreements = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post("api/v1/payroll/setting/archive-agreements-by-id", {
      id: onArchive.data?.id || "",
    })
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Agreements");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  return (
    <div className="p-6">
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Absence`}
        details={onArchive.data?.name}
        onConfirm={arciveAgreements}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />
      {agreement?.isShimmer ? (
        <>
          <div className="px-20">
            <div className="bg-white py-4 px-4">
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </div>
          </div>
        </>
      ) : (
        <>
          {!agreement?.data?.length > 0 && !agreement?.search ? (
            <>
              <TableDefaultPage data={deafaulPage} />
            </>
          ) : (
            <>
              <div className="px-20">
                <div className="bg-white py-4 px-4">
                  <div className="bg-white border border-[#111111] p-1 flex items-center rounded-md w-full lg:w-1/3">
                    <FaSearch className="ml-2" />
                    <input
                      placeholder="Search by Agreement Name"
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      name="search"
                      onChange={handleAgreement?.search}
                    />
                  </div>
                </div>

                <AgreementTable
                  data={agreement}
                  setData={setAgreement}
                  handle={handleAgreement}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  onArchive={onArchiveClick}
                  cursorvalue={cursorvalue}
                />
              </div>
              <div className="px-20">
                <div className="bg-white py-4 px-4">
                  {agreement?.data?.length <= 0 && agreement?.search ? (
                    <>
                      <NoTableOption data={noSearchOption} />
                    </>
                  ) : (
                    <>
                      <TablePagination
                        data={agreement}
                        setData={setAgreement}
                        setCursorValue={setCursorValue}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Payroll;
