import { Button, Card, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaDownload, FaEdit, FaPlus, FaSearch } from "react-icons/fa";
import CreateNewAreaModal from "../../../../../shared/Modal/CreateNewAreaModal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CraeteEditArea,
  GetAreas,
} from "../../../../../helper/worksite/worksiteDetails.js/areas";
import { toast } from "react-toastify";
import { SearchData } from "../../../../../helper/global/global";
import { SettingService, WorkService } from "../../../../../config/axiosUrl";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import {
  NoSearchTableSvg,
  TableDefaultSvg,
} from "../../../../../assets/svg/tableDefaul";
import AreasDefaultSvg from "../../../../../assets/svg/areasDefault";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import InfiniteScroll from "react-infinite-scroll-component";
import { ButtonSpinner } from "../../../../../components/spinners";
import DownloadQAReport from "../../DownloadQAReport";
import CreateEditAreaModal from "../../../../../shared/Modal/CreateNewEditAreaModal";

const Areas = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  const initialvalues = {
    isSave: 1,
    data: [],
    extra: {},
    search: "",
    worksite_id: id,
    name: "",
    abbreviation: "",
    errors: {
      name: "",
      abbreviation: "",
    },
    isLoading: false,
    isShimmer: true,
  };

  const initialVisible =
    location?.pathname == `/admin/worksite/details/areas-create/${id}`
      ? true
      : false;

  const [areas, setAreas] = useState(initialvalues);
  const [visibleAreaModal, setVisibleAreaModal] = useState(initialVisible);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  let errorkeys = Reflect.ownKeys(initialvalues?.errors);
  let errors = useRef([]);

  const createNewArea = () => {
    navigate(`/admin/worksite/details/areas-create/${id}`);
  };

  errorkeys?.map((value, index) => (errors.current[value] = React.createRef()));

  const handleAreas = {
    fetch: async () => {
      const params = {
        ...areas,
        worksite_id: id,
        cursor: areas?.extra?.cursor,
      };

      if (areas?.data?.length != areas?.extra?.totalItems) {
        let getData = await GetAreas(params);
        if (getData?.status) {
          let allData = [...areas?.data];
          getData?.data?.data?.map((area, index) => {
            allData?.push({ ...area });
          });
          setAreas({
            ...areas,
            data: allData,
            extra: { ...getData?.extra },
            isShimmer: false,
          });
        }
      }
    },
    search: async (e) => {
      const params = {
        ...areas,
        search: e?.target?.value,
      };

      let getData = await handleAreas?.getAllAreas(params);
    },
    get: (e) => {
      setAreas({
        ...areas,
        [e?.target?.name]: e?.target?.value,
      });
    },
    submit: () => {
      let errorCount = 0;
      errorkeys?.forEach((value, index) => {
        if (errorCount == index) {
          if (!areas?.[value]) {
            setAreas({
              ...areas,
              errors: {
                ...initialvalues?.errors,
                [value]: `Enter ${value?.replace(/_/g, " ")} `,
              },
            });
            errors?.current?.[value]?.current?.focus();
          } else {
            errorCount++;
            setAreas({
              ...areas,
              errors: {
                ...initialvalues?.errors,
              },
            });

            if (errorCount == errorkeys?.length) {
              handleAreas?.create();
            }
          }
        }
      });
    },
    create: async () => {
      setAreas({
        ...areas,
        isLoading: true,
      });

      let data = await CraeteEditArea(areas);
      if (data?.status) {
        const params = {
          worksite_id: id,
        };

        let getData = await GetAreas(params);
        if (getData?.status) {
          setAreas({
            ...areas,
            data: [...getData?.data?.data],
            name: "",
            abbreviation: "",
          });
          setVisibleAreaModal(false);
          toast?.success(data?.message);
          navigate(`/admin/worksite/details/areas/${id}`);
        } else {
          setAreas({
            ...areas,
            isLoading: false,
          });
        }
      } else {
        setAreas({
          ...areas,
          isLoading: false,
        });
        toast?.error(data?.message);
      }
    },
    getAllAreas: async (areasData) => {
      const params = {
        worksite_id: id,
        search: areasData?.search,
      };

      let getData = await GetAreas(params);
      //
      if (getData?.status) {
        setAreas({
          ...areas,
          data: [...getData?.data?.data],
          extra: { ...getData?.extra },
          search: areasData?.search,
          isShimmer: false,
        });
      }
    },
  };

  let deafaulPage = {
    disclaimer: "No areas Found",
    description: "Create Your First area",
    buttonText: "Create New Area",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  useEffect(() => {
    (async () => {
      let getData = await handleAreas?.getAllAreas();
    })();
  }, []);

  const handleEditClick = (area) => {
    setSelectedArea(area);
    setEditModalVisible(true);
  };
  return (
    <>
      {location?.pathname === `/admin/worksite/details/areas-create/${id}` && (
        <CreateNewAreaModal
          data={areas}
          errors={errors}
          handle={handleAreas}
          setVisibleAreaModal={setVisibleAreaModal}
        />
      )}
      {editModalVisible && (
        <CreateEditAreaModal
          area={selectedArea}
          setEditModalVisible={setEditModalVisible}
          setAreas={setAreas}
          handleAreas={handleAreas}
        />
      )}

      {areas?.isShimmer ? (
        <div className="p-4">
          <TextShimmer data={{ gap: 15, line: 15 }} />
        </div>
      ) : (
        <>
          {areas?.data?.length <= 0 && !areas?.search ? (
            <div className="py-8 px-4 h-[70vh]">
              <TableDefaultPage data={deafaulPage} />
            </div>
          ) : (
            <>
              <div className="">
                {/* <div className="h-[45vh] overflow-y-scroll fixed bg-white mr-10 "> */}
                <div className="lg:flex justify-between items-center px-4">
                  <div className="flex justify-between w-full items-center gap-3">
                    <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white min-w-[300px]">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        placeholder="Search by Area, Code"
                        className="h-[31px] bg-transparent text-sm w-full px-2"
                        onChange={handleAreas?.search}
                      />
                    </div>

                    <group className="flex">
                      <div className="mx-3">
                        <DownloadQAReport type="areas" />
                      </div>
                      <div>
                        <Button
                          onClick={createNewArea}
                          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                        >
                          Create New{" "}
                          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                            <FaPlus />
                          </div>
                        </Button>
                      </div>
                    </group>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    {areas?.data?.length <= 0 && areas?.search ? (
                      <div className="grid lg:grid-cols-1  mx-4">
                        <NoTableOption data={noSearchOption} />
                      </div>
                    ) : (
                      <div id="scrollableDiv" className="h-[400]">
                        <InfiniteScroll
                          className=""
                          dataLength={areas?.data?.length}
                          next={handleAreas?.fetch}
                          hasMore={
                            areas?.data?.length != areas?.extra?.totalItems
                          }
                          loader={
                            <div>
                              <ButtonSpinner />
                            </div>
                          }
                          height={400}
                          endMessage={<div></div>}
                          scrollableTarget="scrollableDiv"
                        >
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 bg-white mx-4 my-4">
                            {areas?.data?.map((areas) => (
                              <Card className="rounded-md border-[#E8E8E8] cursor-pointer card h-full">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <FaEdit
                                    onClick={() => handleEditClick(areas)}
                                  />
                                </div>
                                <Link
                                  to={`/admin/worksite/details/areas/cards/${id}/${areas?._id}`}
                                  key={areas?._id}
                                >
                                  <div className="rounded-md border-[#E8E8E8] cursor-pointer">
                                    <p className="text-sm">
                                      {areas?.abbreviation
                                        ? areas?.abbreviation
                                        : "N/A"}
                                    </p>
                                    <p className="text-xl mt-2 mb-4">
                                      {areas?.name ? areas?.name : "N/A"}
                                    </p>
                                    <p>{areas?.tasks} Tasks</p>

                                    <p className="mt-2 text-[#FE0C0C] flex justify-start items-center text-xs">
                                      {areas?.tasks_without_alloc > 0 ? (
                                        <>
                                          <AiOutlineWarning /> &nbsp;
                                          {`${areas?.tasks_without_alloc} Tasks Without Allocation`}
                                        </>
                                      ) : (
                                        <div className="h-4" />
                                      )}
                                    </p>

                                    <p className="my-2">
                                      {areas?.hours ? areas?.hours : "N/A"}{" "}
                                      Hours
                                    </p>
                                    <p className="text-xs text-[#A1A1A1]">
                                      Last 7 days hour worked Hours
                                    </p>

                                    <div className="text-center p-2 w-full bg-[#D5E9FE] mt-5 mb-3">
                                      <p className="text-xs">
                                        Productivity Order
                                      </p>
                                    </div>

                                    <div className="grid grid-cols-4">
                                      <div className="text-center">
                                        <p className="text-xs text-[#A1A1A1]">
                                          Total
                                        </p>
                                        <p className="text-sm">
                                          {areas?.total ? areas?.total : "0"}
                                        </p>
                                      </div>
                                      <div className="text-center">
                                        <p className="text-xs text-[#A1A1A1]">
                                          Approved
                                        </p>
                                        <p className="text-sm">
                                          {areas?.approved
                                            ? areas?.approved
                                            : "0"}
                                        </p>
                                      </div>
                                      <div className="text-center">
                                        <p className="text-xs text-[#A1A1A1]">
                                          Active
                                        </p>
                                        <p className="text-sm">
                                          {areas?.active ? areas?.active : "0"}
                                        </p>
                                      </div>
                                      <div className="text-center">
                                        <p className="text-xs text-[#A1A1A1]">
                                          Remaining
                                        </p>
                                        <p className="text-sm">
                                          {areas?.remaining
                                            ? areas?.remaining
                                            : "0"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </Card>
                            ))}
                          </div>
                        </InfiniteScroll>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Areas;
