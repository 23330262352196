import React, {
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from "react";
import CreateWorksiteAllowence from "./CreateWorksiteAllowence";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../../../components/PaginationAPI/Pagination";
import { PayRollService, UserService } from "../../../../../config/axiosUrl";
import { FaAngleDown, FaAngleUp, FaPlus, FaSearch } from "react-icons/fa";
import {
  Archive,
  Edit,
  Trash,
  WorkerAllowanceDefault,
} from "../../../../../assets/svg";
import Confirmation from "../../../../../components/Confirmation";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import WorkerAllowanceAssignModal from "./WorkerAllowanceAssignModal";
import DefaulGlobal from "../../../../../components/default/DefaultGloabal";

const WorkerAllowence = () => {
  const { type } = useParams();

  const navigate = useNavigate();
  const [visibleModal, setVisibleModal] = useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [getAllowance, setGetAllowance] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    isDeleting: false,
  });

  const toggleExpand = function (index) {
    setExpanded((prev) => (prev === index ? -1 : index));
  };

  const [workerAllowance, setWorkerAllowance] = useState({
    data: [],
    pageLoading: true,
  });

  const [search, setSearch] = useState("");
  const [workers, setWorkers] = useState({
    currentEdit: "",
    visible: false,
    allWorkers: [],
    isLoading: true,
  });

  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const pageFunctions = {
    onDelete: useMemo(
      () => (current) => {
        setDeleteModal((prev) => ({
          ...prev,
          visible: !prev.visible,
          current: prev.visible ? {} : current,
        }));
      },
      []
    ),
    toggleModal: useMemo(
      () => () => {
        setVisibleModal((prev) => !prev);
      },
      []
    ),
    onEdit: useMemo(
      () => (id) => {
        navigate(`/admin/settings/edit-worker-allowance/${id}`);
      },
      []
    ),
    onDeleteFail: useMemo(
      () => () => {
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: false,
        }));
      },
      []
    ),
    onDeleteSuccess: useMemo(
      () => () => {
        setDeleteModal((prev) => ({
          ...prev,
          visible: false,
          current: {},
          isDeleting: false,
        }));
      },
      []
    ),
    toggleAssign: useCallback(
      (currentEdit = "", data = null) => {
        let allowanceID = workers.currentEdit?.id;

        setWorkers((prev) => ({
          ...prev,
          visible: !prev.visible,
          currentEdit,
        }));

        if (data) {
          setWorkerAllowance((prev) => {
            let index = prev.data.findIndex(({ id }) => id === allowanceID);
            if (index > -1) {
              prev.data[index] = data;
            }

            return prev;
          });
        }
      },
      [workers.currentEdit]
    ),
  };

  const apiFunctions = {
    get: useCallback((res) => {
      setWorkerAllowance((prev) => ({
        ...prev,
        data: res?.data?.data?.data || [],
        pageLoading: false,
      }));
    }, []),
    getWorkers: useCallback((search = "") => {
      UserService.get(
        `/api/v1/personal/workers/get-lists?type=${
          type ? type : 1
        }&search=${search}`
      ).then((res) => {
        setWorkers((prev) => ({
          ...prev,
          allWorkers: res?.data?.data?.data,
        }));
      });
    }, []),
    deleteWorker: useCallback(async () => {
      await new Promise((resolve) => {
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: true,
        }));
        resolve(true);
      });
      let current = deleteModal.current;
      PayRollService.post(
        "api/v1/setting/payroll/delete-worker-allowance-worker-data",
        {
          deleted_ids: [current?.id],
          payroll_worker_allowance_id: current?.payroll_worker_allowance_id,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            pageFunctions.onDeleteSuccess();
            setGetAllowance((prev) => !prev);
            // setWorkerAllowance((prev) => {
            //   let state = prev.data;
            //   let index = current.index;

            //   if (current?.childIndex > -1) {
            //     let data = state[index];
            //     data?.payroll_worker_allowance_worker_data.splice(
            //       current?.childIndex,
            //       1
            //     );

            //     state[index] = data;
            //   } else if (index > -1) {
            //     state.splice(index, 1);
            //   }
            //   return { ...prev, data: state };
            // });
          } else {
            pageFunctions.onDeleteFail();
            toast.success(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.success("Failed to delete worker");
          pageFunctions.onDeleteFail();
        });
    }, [deleteModal]),
    deleteWorkerAllowance: useCallback(async () => {
      await new Promise((resolve) => {
        setDeleteModal((prev) => ({
          ...prev,
          isDeleting: true,
        }));
        resolve(true);
      });
      PayRollService.post("api/v1/setting/payroll/delete-worker-allowance", {
        id: deleteModal?.current?.id,
      })
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            pageFunctions.onDeleteSuccess();
            setGetAllowance((prev) => !prev);
          } else {
            pageFunctions.onDeleteFail();
            toast.success(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.success("Failed to delete worker");
          pageFunctions.onDeleteFail();
        });
    }, [deleteModal]),
    createWorker: useCallback(async (data) => {
      await new Promise((resolve) => {
        setIsCreating(true);
        resolve(true);
      });
      PayRollService.post(
        "api/v1/setting/payroll/create-worker-allowance",
        data
      )
        .then((res) => {
          let message = res?.data?.message;
          if (res?.data?.status) {
            toast.success(message);
            reset();
            setVisibleModal(false);
            setGetAllowance((prev) => !prev);
          } else {
            toast.error(message);
          }
          setIsCreating(false);
        })
        .catch((err) => {
          toast.error("Failed to create worker allowance");

          setIsCreating(false);
        });
    }, []),
  };

  useEffect(() => apiFunctions.getWorkers(), []);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const arciveAgreements = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post(
      "api/v1/payroll/setting/archive-workers-allowance-by-id",
      {
        id: onArchive.data?.id || "",
      }
    )
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Agreements");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  return (
    <div>
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Worker Allowance`}
        details={onArchive.data?.name}
        onConfirm={arciveAgreements}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />

      <CreateWorksiteAllowence
        open={visibleModal}
        toggleModal={pageFunctions.toggleModal}
        onSubmit={apiFunctions.createWorker}
        isSubmitting={isCreating}
        handleSubmit={handleSubmit}
        control={control}
      />
      <Confirmation
        title={"Are You Sure You Wish To Delete This Worker Allowance"}
        open={deleteModal.visible}
        details={
          deleteModal.current?.name || deleteModal?.current?.users?.name || ""
        }
        detailsTitle="Name"
        onCancel={pageFunctions.onDelete}
        onConfirm={
          deleteModal.current?.childIndex > -1
            ? apiFunctions.deleteWorker
            : apiFunctions.deleteWorkerAllowance
        }
        isSubmitting={deleteModal?.isDeleting}
      />
      <WorkerAllowanceAssignModal
        loading={workers.isLoading}
        open={workers.visible}
        onCancel={pageFunctions.toggleAssign}
        onSearch={apiFunctions.getWorkers}
        data={workers.allWorkers}
        allowance={workers.currentEdit}
      />
      <div className="flex justify-between items-center bg-white p-5">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Worker Allowance Name"
            onChange={({ target: { value } }) => setSearch(value)}
            className="h-[31px] bg-transparent text-sm w-full px-2"
          />
        </div>
        <Button
          onClick={pageFunctions.toggleModal}
          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
        >
          Create New
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>

      {workerAllowance.pageLoading ? (
        <TextShimmer
          data={{
            line: 25,
            gap: 15,
            className: "bg-white",
          }}
        />
      ) : (
        <div className="bg-white">
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">Name</div>
                </th>

                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-end pr-4">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {workerAllowance?.data?.map((item, index) => (
                <Fragment key={item?.id}>
                  <tr className="border-b-2 border-[#F0F0F0]">
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-start">
                        {item.name}
                      </div>
                    </td>

                    <td className="px-4 py-3">
                      <div className="flex gap-4 items-center justify-end">
                        <button
                          onClick={() =>
                            pageFunctions.onDelete({ ...item, index })
                          }
                          className="text-black bg-transparent border-none"
                        >
                          <Trash title="Trash" />
                        </button>
                        <button
                          onClick={() => onArchiveClick(item)}
                          className="text-black bg-transparent border-none"
                        >
                          <Archive title="Archive" />
                        </button>
                        <button
                          onClick={() => pageFunctions.onEdit(item?.id)}
                          className="text-black bg-transparent border-none"
                        >
                          <Edit title="Edit" />
                        </button>
                        <Button
                          className="px-2 bg-[#FE650C]"
                          onClick={() => toggleExpand(index)}
                        >
                          {expanded === index ? (
                            <FaAngleUp className="scale-125 fill-white" />
                          ) : (
                            <FaAngleDown className="scale-125 fill-white" />
                          )}
                        </Button>
                      </div>
                    </td>
                  </tr>
                  {expanded === index && (
                    <Fragment>
                      {item?.payroll_worker_allowance_worker_data?.map(
                        (child, childIndex) => (
                          <tr key={child.id}>
                            <td colSpan={2}>
                              <div className="flex justify-center py-4 px-2">
                                <div className="flex justify-between w-[80%] px-2  border-b-2 pb-2 border-b-[#F0F0F0]">
                                  <p>{child?.users?.name}</p>
                                  <div className="cursor-pointer">
                                    <Trash
                                      onClick={() =>
                                        pageFunctions.onDelete({
                                          ...child,
                                          index,
                                          childIndex,
                                          payroll_worker_allowance_id: item.id,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td colSpan={3}>
                          <div className="flex justify-end w-[90%] py-2">
                            <Button
                              className="flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px]"
                              onClick={() => pageFunctions.toggleAssign(item)}
                            >
                              Assign
                              <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                                <FaPlus />
                              </div>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
          {!workerAllowance.data?.length && (
            <DefaulGlobal
              DefaultImage={WorkerAllowanceDefault}
              header="No Worker Allowances found"
              description={"Create your Worker Allowances now"}
              buttonTitle="Create New"
              pageHandler={pageFunctions.toggleModal}
            />
          )}
        </div>
      )}

      <div className="bg-white">
        <Pagination
          api="/api/v1/setting/payroll/get-workers-allowance"
          apiService={PayRollService}
          getRes={apiFunctions.get}
          extraParams={{ search }}
          dependecy={[getAllowance, search, onArchive.reget]}
        />
      </div>
    </div>
  );
};

export default WorkerAllowence;
