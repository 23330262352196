import React, { useEffect, useRef, useState } from "react";
import AbsenceForm from "./AbsenceForm";
import { useParams, useNavigate } from "react-router-dom";
import {
  CreateEditAbsences,
  GetAbsencesById,
} from "../../../../../helper/setup/payroll/absences";
import { toast } from "react-toastify";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { FaTimes } from "react-icons/fa";

const CreateAbsence = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  const initialvalues = {
    id: id ? id : "",
    isSave: id ? 0 : 1,
    type: "",
    name: "",
    absence_accrued_name: "",
    absence_taken_name: "",
    default_duration: "",
    accrual_method: "",
    required_weekday_hours: "",
    required_weekend_hours: "",
    daily_accrual: "",
    summary_column: "",
    export_taken: 0,
    export_accrued: 0,
    track_as_entitlement: 0,
    exclude_from_shift_cost: 0,
    accrue_when_absent: 0,
    export_without_job_number: 0,
    errors: {
      name: "",
      absence_accrued_name: "",
      absence_taken_name: "",
      default_duration: "",
      accrual_method: "",
      required_weekday_hours: "",
      required_weekend_hours: "",
      daily_accrual: "",
      summary_column: "",
    },
    isLoading: false,
    isShimmer: true,
  };

  const [absences, setAbsences] = useState(initialvalues);

  let errors = useRef([]);
  [
    "name",
    "absence_accrued_name",
    "absence_taken_name",
    "default_duration",
    "accrual_method",
    "required_weekday_hours",
    "required_weekend_hours",
    "daily_accrual",
    "summary_column",
  ]?.map((value, index) => {
    errors.current[value] = React?.createRef();
  });

  const handleAbsences = {
    get: (e) => {
      setAbsences({
        ...absences,
        [e?.target?.name]: e?.target?.value,
      });
    },
    getNumbers: (e) => {
      if (!isNaN(e?.target?.value) && !e?.target?.value?.length == 0) {
        if (parseInt(e?.target?.value) >= 0) {
          setAbsences({
            ...absences,
            [e?.target?.name]: String(e?.target?.value),
          });
        }
      } else if (e?.target?.value?.length == 0) {
        setAbsences({
          ...absences,
          [e?.target?.name]: String(e?.target?.value),
        });
      }
    },
    check: (e) => {
      setAbsences({
        ...absences,
        [e?.target?.name]: Number(e?.target?.checked),
      });
    },
    submit: async (e) => {
      let keys = Reflect.ownKeys(absences?.errors);
      let num = 0;

      keys?.map((value, index) => {
        if (num < keys?.length) {
          if (num == index) {
            if (!absences?.[value]) {
              setAbsences({
                ...absences,
                errors: {
                  ...initialvalues?.errors,
                  [value]: `Enter ${value?.replace(/_/g, " ")}`,
                },
              });
              errors?.current?.[value]?.current?.focus();
            } else {
              setAbsences({
                ...absences,
                errors: { ...initialvalues?.errors },
              });

              num++;

              if (num == keys?.length) {
                handleAbsences?.create();
              }
            }
          }
        }
      });
    },
    create: async () => {
      setAbsences({
        ...absences,
        errors: { ...initialvalues?.errors },
        isLoading: true,
      });

      let data = await CreateEditAbsences(absences);
      if (data?.status) {
        toast?.success(data?.message);
        setAbsences(initialvalues);
        if (id) {
          navigate(-1);
        } else {
          navigate("/admin/settings/payroll/absences/1/1");
        }
      } else {
        toast?.error(data?.message);
        setAbsences({
          ...absences,
          isLoading: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (id) {
        let data = await GetAbsencesById(absences);

        if (data?.status) {
          setAbsences({
            ...absences,
            ...data?.data?.data,
            isShimmer: false,
          });
        }
      } else {
        setAbsences({
          ...initialvalues,
          isShimmer: false,
        });
        setTimeout(() => {
          errors?.current?.["name"]?.current?.focus();
        }, 100);
      }
    })();
  }, [id]);

  return (
    <div className="p-6 lg:w-1/2 w-full mx-auto my-4 bg-white">
      {absences?.isShimmer ? (
        <TextShimmer data={{ gap: 15, line: 21 }} />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-bold mb-10">
              {id
                ? "Editing Absence RDO-Salary"
                : "Creating Absence RDO-Salary"}
            </h2>
            <button
              className="ml-auto"
              onClick={() => navigate("/admin/settings/payroll/absences/1/1")}
            >
              <FaTimes />
            </button>
          </div>

          <AbsenceForm
            data={absences}
            setData={setAbsences}
            handle={handleAbsences}
            errors={errors}
          />
        </>
      )}
    </div>
  );
};

export default CreateAbsence;
