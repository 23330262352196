import React from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import InputError from "../../../../components/InputError";
import { useEffect } from "react";
const ChildSupportModal = ({
  visible,
  onCancel,
  isEditing,
  editData,
  onSave,
}) => {
  const modalTitle = isEditing
    ? "Editing Child Support"
    : "Creating Child Support";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });

  useEffect(() => {
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset();
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    reset();
    onSave(data);
  };
  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Child Support Name</label>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="value">Value</label>
          <Controller
            name="value"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          <Controller
            name="frequency"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select {...field} className="mt-3 w-full">
                <Select.Option value="1">Weekly</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="item mt-5 text-center">
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChildSupportModal;
