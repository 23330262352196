import React, { useEffect, useState } from "react";
import { FaDotCircle, FaInfoCircle } from "react-icons/fa";
import Line from "../Charts/line";
import { Button, Card } from "antd";
import NewPieChart from "../Charts/pie";
import WeeklyBar from "../Charts/weeklyBar";

import { AiOutlineDollarCircle, AiOutlineProfile } from "react-icons/ai";
import { GrUserWorker } from "react-icons/gr";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { TableDefaultSvg } from "../../../../../assets/svg/tableDefaul";
import { GetAreas } from "../../../../../helper/worksite/worksiteDetails.js/areas";
import { useNavigate, useParams } from "react-router-dom";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import OverviewDefaultSvg from "../../../../../assets/svg/overviewDefaultSvg";
import { GetOverview } from "../../../../../helper/worksite/worksiteDetails.js/overview";
import { WorkService } from "../../../../../config/axiosUrl";
const OverViews = ({ mainDate }) => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [timesheet, setTimesheet] = useState([]);
  const [avgWorkers, setAvgWorkers] = useState(0);

  const initialvalue = {
    isShimmer: true,
    data: [],
  };

  const timesheetData = () => {
    let params = { worksite_id: id };

    WorkService.post(
      `api/v1/worksite/get-graph-timesheets_reports`,
      params
    ).then((res) => {
      setTimesheet(res?.data?.data?.data);
    });
  };

  useEffect(() => {
    timesheetData();
  }, []);
  const [overview, setOverView] = useState(initialvalue);

  let deafaulPage = {
    disclaimer: "No data available for Displaying Graphs and stats",
    description:
      "Before estimations can be created, the worksite needs to have areas added.",
    buttonText: "Create Area",
    svg: <OverviewDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  const calculateAvgWorkerCount = () => {
    if (
      timesheet?.worksite_timesheet_data &&
      timesheet?.worksite_timesheet_data.length > 0
    ) {
      const totalWorkerCounts = timesheet?.worksite_timesheet_data.reduce(
        (sum, day) => {
          // Check if 'totalWorkerCountDaywise' is present in the day object
          const workerCount = day.totalWorkerCountDaywise || 0;
          return sum + workerCount;
        },
        0
      );
      return Math.round(totalWorkerCounts / 7);
    }
    return 0; // Default value if there is no timesheet data
  };
  //
  const handleOverview = {
    getOverview: async () => {
      const params = {
        worksite_id: id,
        date: mainDate,
      };

      let data = await GetOverview(params);
      if (data?.status) {
        setOverView({
          ...overview,
          data: data?.data?.data,
          isShimmer: false,
        });
      }
    },
  };

  const getTaskPage = () => {
    navigate(`/admin/worksite/details/tasks/${id}`);
  };

  useEffect(() => {
    (async () => {
      let data = await handleOverview?.getOverview();
    })();
  }, [mainDate]);

  return (
    <>
      <div className="p-4">
        {overview?.isShimmer ? (
          <TextShimmer data={{ gap: 15, line: 20 }} />
        ) : (
          <>
            {overview?.data?.overview?.length <= 0 ? (
              <div className="py-8 h-[70vh]">
                <TableDefaultPage data={deafaulPage} />
              </div>
            ) : (
              <div>
                <Card>
                  <div className="flex justify-between items-center">
                    <h2 className="tex-3xl font-bold">Overview Timeline</h2>

                    <button className="bg-[#F3FFEE] border-[#52C41A] flex justify-center items-center text-black px-4 py-2 rounded-md">
                      <FaDotCircle className="mr-3 text-[#52C41A]" /> On Time{" "}
                      <FaInfoCircle className="ml-3" />
                    </button>
                  </div>
                  <Line data={overview} setData={setOverView} />
                </Card>

                <div className="lg:flex justify-between items-start gap-5 mt-5">
                  <div className="lg:w-1/2">
                    <Card className="w-full">
                      <div className="flex justify-between items-center">
                        <div>
                          <h2 className="text-xl font-medium">Task Summary</h2>
                          <p className="text-gray-400 text-sm">
                            {overview?.data?.task_summer?.overdue}&nbsp;Overdue
                            Tasks
                          </p>
                        </div>
                        <Button
                          className="bg-gray-200 border-none"
                          onClick={getTaskPage}
                        >
                          View Tasks
                        </Button>
                      </div>

                      <NewPieChart data={overview} setData={setOverView} />
                    </Card>
                  </div>
                  <div className="lg:w-1/2">
                    <Card className="w-full h-full">
                      <div className="flex justify-between items-start">
                        <div className="w-[40%]">
                          <div>
                            <h2 className="text-xl font-medium">
                              Timesheets Reports
                            </h2>
                            <p className="text-gray-400 text-sm">
                              Weekly Overview
                            </p>
                          </div>
                          <div className="mt-10 flex justify-start items-center">
                            <div>
                              <h2 className="text-3xl font-bold">
                                {timesheet?.work_hours}
                              </h2>
                              <p className="text-gray-400">Work Hours</p>
                            </div>
                            {/* <div className="ml-5 bg-[#CBEFB9] border-2 border-[#41C700] text-xs px-2 py-1 rounded-md">
                              +0.00%
                            </div> */}
                          </div>
                        </div>
                        <div className="w-[60%] mb-20%">
                          <WeeklyBar timesheet={timesheet} />
                        </div>
                      </div>

                      <hr className="my-3" />

                      <div className="lg:flex justify-between items-center">
                        <div className="lg:w-1/3 w-full lg:border-r text-center">
                          <div className="flex justify-center items-center text-[#A1A1A1] text-xl">
                            <AiOutlineDollarCircle className="mr-2" /> Cost
                          </div>
                          <h2 className="text-2xl mt-1 font-bold">
                            $
                            {timesheet?.dollar
                              ? Number(timesheet.dollar).toLocaleString("en-AU")
                              : "0"}
                          </h2>
                        </div>

                        <div className="lg:w-1/3 w-full lg:border-r text-center">
                          <div className="flex justify-center items-center text-[#A1A1A1] text-xl">
                            <AiOutlineProfile className="mr-2" /> Tasks
                          </div>
                          <h2 className="text-2xl mt-1 font-bold">
                            {timesheet?.tasks || 0}
                          </h2>
                        </div>
                        <div className="lg:w-1/3 w-full text-center">
                          <div className="flex justify-center items-center text-[#A1A1A1] text-xl">
                            <GrUserWorker className="mr-2" /> Avg Workers
                          </div>
                          <h2 className="text-2xl mt-1 font-bold">
                            {calculateAvgWorkerCount()}
                          </h2>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OverViews;
