import React from "react";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { UserService } from "../../config/axiosUrl";

const CreateEditWorkers = async (workers) => {
  //
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const formData = new FormData();
    formData?.append("id", workers?.id);
    formData?.append("worker_details_id", workers?.worker_details_id);
    formData?.append("name", workers?.name);
    formData?.append("email", workers?.email);
    formData?.append("password", workers?.password);
    formData?.append("cpassword", workers?.cpassword);
    formData?.append("employee_id", workers?.employee_id);
    formData?.append("role", workers?.role);
    formData?.append("employment_status_id", workers?.employment_status_id);
    formData?.append("work_site_id", workers?.work_site_id);
    formData?.append("is_send_shift_receipts", workers?.is_send_shift_receipts);
    formData?.append("isSave", workers?.isSave);
    formData?.append("profile_image", workers?.profile_image);
    formData?.append("worker_designation_id", workers?.worker_designation_id);
    formData?.append("last_name", workers?.lastname);

    let data = await UserService?.post(
      "/api/v1/personal/workers/create-edit-worker?dev=4",
      formData,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkers = async (workers) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      type: workers?.type,
      search: workers?.search,
      worksite_id: workers?.worksite_id,
    };

    let data = await UserService?.get("/api/v1/personal/workers/get-lists", {
      headers: authorization?.headers,
      params: params,
    });
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkerstab = async (workers) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      search: workers?.search,
      worksite_id: workers?.worksite_id,
    };

    let data = await UserService?.get(
      `/api/v1/personal/workers/get-lists?type=${workers?.type}`,
      {
        headers: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteWorkers = async (workers) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: workers?.id,
    };

    let data = await UserService?.post(
      "/api/v1/personal/workers/remove-worker",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorkersById = async (workers) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: workers?.id,
    };

    let data = await UserService?.post(
      "/api/v1/personal/workers/get-worker-data-by-id?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const TerminateRevokeWorker = async (workers) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: workers?.id,
      status: workers?.status,
    };

    let data = await UserService?.post(
      "/api/v1/personal/workers/terminate-revoke-worker",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const AddPayrollCategory = async (worker) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      ids: worker?.ids,
      worker_id: worker?.worker_id,
    };

    let data = await UserService?.post(
      "/api/v1/worksite/assign-payroll-category-worker?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CreateEditWorkers,
  GetWorkers,
  GetWorkerstab,
  DeleteWorkers,
  GetWorkersById,
  TerminateRevokeWorker,
  AddPayrollCategory,
};
