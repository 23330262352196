import { Button, Form, Modal, Select, TimePicker } from "antd";
import React, { useState } from "react";
import { ButtonSpinner } from "../../components/spinners";
import TextArea from "antd/es/input/TextArea";
import { GetWorkersType, MassAbsense } from "../../helper/worksite/worksite";
import { useParams } from "react-router-dom";

const AbsentModal = ({
  onCloseAbsentModal,
  openAbsentModal,
  absenseType,
  worksite,
  setWorksite,
  selectedItem,
  workStatus,
  isShift,
  fetchShiftDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [absentReason, setAbsentReson] = useState("");
  const [duration, setDuration] = useState("");
  const id = useParams();
  const saveChanges = async () => {
    setIsLoading(true);
    if (isShift) {
      const response = await MassAbsense(
        [],
        id?.id,
        worksite,
        selectedItem,
        isShift
      );
      if (response?.status) {
        onCloseAbsentModal();
        fetchShiftDetails();
      }
    } else {
      const response = await MassAbsense([], id?.id, worksite, selectedItem);
      if (response?.status) {
        onCloseAbsentModal();
        const param = { type: workStatus, id: id?.id };
        const res = await GetWorkersType(param);
        if (res?.status) {
          setWorksite({
            ...worksite,
            data: res?.data?.data,
          });
        }
      }
    }
  };
  const onChange = (time, timeString) => {
    setDuration(timeString);
    setWorksite({
      ...worksite,
      duration: timeString,
    });
  };
  const handleChange = (value) => {
    setWorksite({
      ...worksite,
      absence_type: value,
    });
  };
  return (
    <Modal
      title={`${
        !isShift
          ? selectedItem?.users?.length > 0
            ? selectedItem?.users[0]?.name
            : selectedItem?.item?.name
          : ""
      } Mark Absent`}
      className="clockin-modal"
      open={openAbsentModal}
      onCancel={onCloseAbsentModal}
      footer={[
        <Button
          key="save"
          onClick={saveChanges}
          className="btn btn-blue bg-blue-disable"
        >
          {isLoading ? <ButtonSpinner /> : !isShift ? "Assign" : "Absent"}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Absence Type">
          <Select placeholder="Select Absense Type" onChange={handleChange}>
            {absenseType?.length > 0 &&
              absenseType?.map((item) => (
                <Select.Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Duration" name="duration">
          <TimePicker onChange={onChange} className="mass-absent-opt" />
        </Form.Item>
        {/* <div className='reason-block'>
                    <div className='flex justify-between'>
                        <p>Reason</p>
                        <a>+Add</a>
                    </div>
                    <div className='mt-3'>
                        <ul>
                            <li><div className='flex justify-between items-center'>1. Worker Had injury So he left for work
                            <div className='flex justify-around'>
                                <FaEdit className='mr-2 cursor-pointer'/>
                                <Trash className='cursor-pointer'/>
                                </div></div></li>
                        </ul>
                    </div>
                </div> */}
        <Form.Item label="Reason for absence" name="reason">
          <TextArea
            rows={4}
            value={absentReason}
            onChange={(e) => {
              setAbsentReson(e?.target?.value);
              setWorksite({
                ...worksite,
                reason: e?.target?.value,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AbsentModal;
