import React, { useEffect, useRef, useState } from "react";
import TabHeader from "./tab-header";
import TabBody from "./tab-body";
import { CreateInduction } from "../../../helper/induction/induction";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const InductionTab = ({ userName }) => {
  let navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const worker_id = queryParameters.get("worker_id");
  const work_site_id = queryParameters.get("work_site_id");
  const disclaimer_id = queryParameters.get("disclaimer_id");
  const [activeTab, setActiveTab] = React.useState(1);

  const initialvlaues = {
    agree_and_continue: false,
    employment_status_id: "",

    name: userName,
    gender: "",
    dob: "",
    address: "",
    city: "",
    post_code: "",
    mobile: "",
    driver_licence: "",
    shirt_size: "",
    pant_size: "",

    super_fund_name: "",
    super_fund_number: "",
    tax_file_number: "",
    bert: "",
    qLeave: "",

    bank_bsb: "",
    bank_account_number: "",

    c_name: "",
    c_mobile: "",
    c_existing_injuries: "0",
    c_existing_injuries_text: "",
    c_medicle_condition: "",
    c_has_ever_made_a_worker_compensation_claim: "0",
    c_blood_type: "",
    c_is_subcontractor: "0",

    workers_experiences: [
      {
        trade: "",
        year: "",
      },
    ],
    trade_certificate_0: "",
    trade_certificate_1: "",
    trade_certificate_2: "",
    trade_certificate_3: "",
    trade_certificate_4: "",
    workers_previous_employement_details: [
      {
        company: "",
        job: "",
        duties: "",
        duration: "",
      },
    ],

    is_have_working_visa: "0",
    is_have_working_visa_text: "",
    licence_file: "",
    blue_card_file: "",
    worker_id: worker_id,
    work_site_id: work_site_id,
    disclaimer_id: disclaimer_id,

    errors: {
      name: "",
      gender: "",
      dob: "",
      address: "",
      city: "",
      post_code: "",
      mobile: "",
      driver_licence: "",
      shirt_size: "",
      pant_size: "",
      super_fund_name: "",
      super_fund_number: "",
      tax_file_number: "",
      bert: "",
      qLeave: "",
      bank_bsb: "",
      bank_account_number: "",
      c_name: "",
      c_mobile: "",
      c_medicle_condition: "",
      c_blood_type: "",
      c_is_subcontractor: "",
      licence_file: "",
      blue_card_file: "",
    },
  };

  const [induction, setInduction] = useState(initialvlaues);

  let errorKeys = Reflect.ownKeys(initialvlaues?.errors);

  let errors = useRef([]);
  errorKeys?.map((value, index) => {
    errors.current[value] = React.createRef();
  });
  useEffect(() => {
    setInduction({
      ...induction,
      name: userName,
    });
  }, [userName]);
  const handleInduction = {
    agreeandcontinue: () => {
      setInduction({
        ...induction,
        agree_and_continue: true,
      });
      setActiveTab(2);
    },
    get: (e) => {
      setInduction({
        ...induction,
        [e?.target?.name]: e?.target?.value,
      });
    },

    getTradeCert: (e, index) => {
      let allWorkExp = [...induction?.workers_experiences];
      allWorkExp[index].trade_certificate = e?.target?.files[0];
      setInduction({
        ...induction,
        workers_experiences: allWorkExp,
      });
    },
    getTrade: (e, index) => {
      let allWorkExp = [...induction?.workers_experiences];
      allWorkExp[index].trade = e.target.value;
      setInduction({
        ...induction,
        workers_experiences: allWorkExp,
      });
    },
    getTradeYear: (e, index) => {
      let allWorkExp = [...induction?.workers_experiences];
      allWorkExp[index].year = e.target.value;
      setInduction({
        ...induction,
        workers_experiences: allWorkExp,
      });
    },
    getPrevCompany: (e, index) => {
      let allPrev = [...induction?.workers_previous_employement_details];
      allPrev[index].company = e.target.value;
      setInduction({
        ...induction,
        workers_previous_employement_details: allPrev,
      });
    },
    getJob: (e, index) => {
      let allPrev = [...induction?.workers_previous_employement_details];
      allPrev[index].job = e.target.value;
      setInduction({
        ...induction,
        workers_previous_employement_details: allPrev,
      });
    },
    getDuties: (e, index) => {
      let allPrev = [...induction?.workers_previous_employement_details];
      allPrev[index].duties = e.target.value;
      setInduction({
        ...induction,
        workers_previous_employement_details: allPrev,
      });
    },
    getDuration: (e, index) => {
      let allPrev = [...induction?.workers_previous_employement_details];
      allPrev[index].duration = e.target.value;
      setInduction({
        ...induction,
        workers_previous_employement_details: allPrev,
      });
    },
    getFiles: (e) => {
      setInduction({
        ...induction,
        [e?.target?.name]: e?.target?.files[0],
      });
    },
    submit: () => {
      let errorCount = 0;
      errorKeys?.map((value, index) => {
        if (errorCount == index) {
          if (!induction?.[value]) {
            setInduction({
              ...induction,
              errors: {
                ...initialvlaues?.errors,
                [value]: `Enter ${value?.replace(/_|id/g, " ")} `,
              },
            });

            errors?.current?.[value]?.current?.focus();
          } else {
            errorCount++;
            setInduction({
              ...induction,
              errors: {
                ...initialvlaues?.errors,
              },
            });

            if (errorCount == errorKeys?.length) {
              handleInduction?.create();
            }
          }
        }
      });
    },
    create: async () => {
      setInduction({
        ...induction,
        isLoading: true,
      });
      let data = await CreateInduction(induction);
      if (data?.status) {
        toast?.success(data?.message);
        setInduction(initialvlaues);
        navigate("/thankyou");
      } else {
        toast?.error(data?.message);
        setInduction({
          ...induction,
          isLoading: false,
        });
      }
    },
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <TabHeader
        handleTabChange={handleTabChange}
        data={induction}
        setData={setInduction}
        handle={handleInduction}
      />
      <div className="overflow-y-scroll induction-form-height">
        <TabBody
          activeTab={activeTab}
          data={induction}
          setData={setInduction}
          errors={errors}
          handle={handleInduction}
        />
      </div>
    </div>
  );
};

export default InductionTab;
