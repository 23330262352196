import React, { Fragment, useEffect, useState } from "react";
import MultiLineChart from "../Charts/MultiLineChart";
import TimesheetTable from "../../../../../shared/DataTable/TimesheetTable";
import { Button, Input } from "antd";
import { FaArrowRight, FaPlus } from "react-icons/fa";
import {
  CreateTimesheet,
  GetTimesheet,
} from "../../../../../helper/worksite/worksiteDetails.js/timesheet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { ButtonSpinner } from "../../../../../components/spinners";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import AreasDefaultSvg from "../../../../../assets/svg/areasDefault";
import InfiniteScroll from "react-infinite-scroll-component";

const Timesheet = () => {
  const { id } = useParams();

  const chatSections = [
    {
      id: 1,
      name: "Days Worked",
      color: "#3366CC",
    },
    {
      id: 2,
      name: "Workers",
      color: "#DC3912",
    },
    {
      id: 3,
      name: "Task Count",
      color: "#FF9900",
    },
    {
      id: 4,
      name: "Cost",
      color: "#109618",
    },
  ];

  const initialTimesheet = {
    data: [],
    graphData: [],
    from: null,
    to: null,
    search: "",
    extra: {},
    isAddLoading: false,
    isShimmer: true,
    errors: {
      from: "",
      to: "",
    },
  };

  const initialDate = {
    from: null,
    to: null,
  };

  const [timesheet, setTimesheet] = useState(initialTimesheet);

  const [date, setDate] = useState(initialDate);

  let deafaulPage = {
    disclaimer: "No data available for displaying timesheet",
    description: "The timesheet needs to be added.",
    buttonText: "Create Timesheet",
    svg: <AreasDefaultSvg />,
  };

  let areadeafaulPage = {
    disclaimer: "No data available for displaying timesheet",
    description: "The timesheet needs to have areas added.",
    buttonText: "Create Area",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  const handleTimesheet = {
    date: async (e, type) => {
      if (type == "from") {
        setTimesheet({
          ...timesheet,
          from: e?.target?.value,
        });
      } else if (type == "to") {
        const params = {
          ...timesheet,
          from: timesheet?.from,
          worksite_id: id,
          to: e?.target?.value,
          cursor: timesheet?.extra?.cursor,
        };
        let data = await handleTimesheet?.get(params);
      }
    },
    fetch: async () => {
      const params = {
        worksite_id: id,
        cursor: timesheet?.extra?.cursor,
      };

      if (timesheet?.data?.length != timesheet?.extra?.totalItems) {
        let data = await GetTimesheet(params);
        if (data?.status) {
          let allData = [...timesheet?.data];
          data?.data?.data?.map((tsheet, index) => {
            allData?.push({ ...tsheet });
          });
        }
      }
    },
    get: async (tsheet) => {
      const params = {
        worksite_id: id,
        cursor: tsheet?.cursor,
        take: tsheet?.take,
        previous: tsheet?.previous,
        id: tsheet?.id,
        search: tsheet?.search,
        from: tsheet?.from,
        to: tsheet?.to,
      };

      let data = await GetTimesheet(params);

      if (data?.status) {
        setTimesheet({
          data: [...data?.data?.data],
          graphData: [...data?.data?.graphData],
          extra: { ...data?.extra },
          from:
            moment(data?.data?.graphData?.[0]?.name).format("YYYY-MM-DD") ||
            tsheet?.from,
          to:
            moment(
              data?.data?.graphData[data?.data?.graphData?.length - 1]?.name
            ).format("YYYY-MM-DD") || tsheet?.to,
          isAddLoading: false,
          isShimmer: false,
        });
        return data;
      }
    },
    create: async () => {
      setTimesheet({
        ...timesheet,
        isAddLoading: true,
      });

      const params = {
        worksite_id: id,
        isSave: 1,
      };

      let data = await CreateTimesheet(params);
      if (data?.status) {
        const getParams = {
          worksite_id: id,
        };
        let getData = await handleTimesheet?.get(getParams);
        if (getData?.status) {
          toast?.success(data?.message);
        }
      } else {
        setTimesheet({
          ...timesheet,
          isAddLoading: false,
        });
        toast?.error(data?.message);
      }
    },
  };

  useEffect(() => {
    (async () => {
      const params = {
        worksite_id: id,
      };
      let data = await handleTimesheet?.get(params);
    })();
  }, []);

  return (
    <div>
      {timesheet?.isShimmer ? (
        <Fragment>
          <div>
            <TextShimmer data={{ gap: 15, line: 20 }} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {timesheet?.areaCount <= 0 ? (
            <div className="py-8 h-[70vh]">
              <TableDefaultPage data={areadeafaulPage} />
            </div>
          ) : timesheet?.data?.length <= 0 &&
            !timesheet?.search &&
            !timesheet?.from &&
            !timesheet?.to ? (
            <div className="py-8 h-[70vh]">
              <div className="h-full flex justify-center items-center flex-col bg-white">
                <div className="py-2">{deafaulPage?.svg}</div>
                <div className="">{deafaulPage?.disclaimer}</div>
                <div className="text-gray-300 text-base py-2">
                  {deafaulPage?.description}
                </div>
                <div className="pt-2">
                  <Button
                    className="btn-blue"
                    onClick={handleTimesheet?.create}
                    disabled={timesheet?.isAddLoading}
                  >
                    {timesheet?.isAddLoading ? (
                      <ButtonSpinner />
                    ) : (
                      deafaulPage?.buttonText
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between my-2 px-5">
                <div className="flex items-center gap-2">
                  {chatSections.map((type) => (
                    <div className="flex items-center">
                      <span
                        className="w-3 h-3"
                        style={{
                          backgroundColor: type.color,
                        }}
                      />
                      <p className="font-lexend text-md font-semibold mx-1">
                        {type.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 items-center">
                  <div>
                    <div>
                      <Input
                        type="date"
                        placeholder="Select from"
                        className={
                          timesheet?.errors?.from
                            ? "text-sm border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "text-sm"
                        }
                        value={timesheet?.from}
                        onChange={(e) => handleTimesheet?.date(e, "from")}
                      />
                    </div>
                    {timesheet?.errors?.from && (
                      <p className="pt-2 text-red-600 text-sm">
                        {timesheet?.errors?.from}
                      </p>
                    )}
                  </div>

                  <FaArrowRight size={25} />
                  <div>
                    <div>
                      <Input
                        type="date"
                        placeholder="Select to"
                        className={
                          timesheet?.errors?.to
                            ? "text-sm border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "text-sm"
                        }
                        value={timesheet?.to}
                        onChange={(e) => handleTimesheet?.date(e, "to")}
                      />
                    </div>
                    {timesheet?.errors?.to && (
                      <p className="pt-2 text-red-600 text-sm">
                        {timesheet?.errors?.to}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <MultiLineChart
                data={timesheet}
                setDate={setTimesheet}
                handle={handleTimesheet}
              />

              <div className="mt-5 flex justify-end items-center ">
                <div className="p-4">
                  <Button
                    onClick={handleTimesheet?.create}
                    className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                    disabled={timesheet?.isAddLoading}
                  >
                    {timesheet?.isAddLoading ? (
                      <div className="px-8">
                        <ButtonSpinner />
                      </div>
                    ) : (
                      <div className="flex">
                        <div>
                          <div className="pt-1">Add New</div>
                        </div>
                        <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                          <FaPlus />
                        </div>
                      </div>
                    )}
                  </Button>
                </div>
              </div>

              <div>
                <div id="scrollableDiv" className="h-[400]">
                  <InfiniteScroll
                    className=""
                    dataLength={timesheet?.data?.length}
                    next={handleTimesheet?.fetch}
                    hasMore={
                      timesheet?.data?.length != timesheet?.extra?.totalItems
                    }
                    loader={
                      <div className="bg-[#f5f5f5]">
                        <ButtonSpinner />
                      </div>
                    }
                    height={400}
                    endMessage={<div></div>}
                    scrollableTarget="scrollableDiv"
                  >
                    <TimesheetTable data={timesheet} />
                  </InfiniteScroll>
                </div>
              </div>
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Timesheet;
