import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetWorkers } from "../../../helper/personnel/workers";
import { UserService } from "../../../config/axiosUrl";
import HeaderSearch from "../../../layouts/HeaderSearch";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import MobileHeader from "./MobileHeader";
import { SearchData } from "../../../helper/global/global";
import { NoTableOption } from "../../../components/noOption/noOption";
import { NoSearchTableSvg } from "../../../assets/svg/tableDefaul";
import { NewTablePagination } from "../../../components/pagination/newPagination";

const WorkersTableSiteManager = ({ hasStatus }) => {
  const initialvalues = {
    data: [],
    selectedData: {},
    extra: {},
    pagination: {
      service: UserService,
      api: "/api/v1/personal/workers/get-lists?type=ALL",
      route: "/admin/personnel/workers",
      search: "",
      cursor: "",
      precursor: "",
    },
    isTabLoading: false,
    isLoading: false,
    isRowLoading: false,
    rowIndex: "",
    isShimmer: true,
  };
  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };
  const [workers, setWorkers] = useState(initialvalues);

  const [searchValue, setSearchValue] = useState("");
  const handleWorkers = {
    getWorker: async (value) => {
      setWorkers({
        ...workers,
        pagination: {
          ...workers?.pagination,
        },
      });

      let data = await GetWorkers();
      if (data?.status) {
        setWorkers({
          ...workers,
          pagination: {
            ...workers?.pagination,
          },
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
          isTabLoading: false,
        });
      }
    },
    search: async (e) => {
      setSearchValue(e?.target?.value);
      const params = {
        value: e?.target?.value,
        api: "/api/v1/personal/workers/get-lists?type=ALL",
        service: UserService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setWorkers({
          ...workers,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          pagination: {
            ...workers?.pagination,
            search: e?.target?.value,
          },
        });
      }
    },
  };
  useEffect(() => {
    (async () => {
      await handleWorkers?.getWorker();
    })();
  }, []);

  return (
    <>
      <MobileHeader />
      <div className="bg-white p-2 mobile-height overflow-y-scroll">
        {workers?.isShimmer ? (
          <div className="bg-white px-4 py-3">
            <TextShimmer data={{ gap: 15, line: 20 }} />
          </div>
        ) : (
          <>
            <HeaderSearch
              placeHolder={"Search by Workers Name"}
              handle={handleWorkers}
            />
            <p className="text-[18px] w-full px-2 mt-2">Workers</p>
            <table className="table-auto w-full">
              <tbody className="bg-white">
                {workers?.data?.map((item, index) => (
                  <tr key={index} className="border-b border-[#F0F0F0]">
                    <td className="px-3 py-3">
                      <Link
                        to={`/site-manager/workers/1/details/${item?.id}`}
                        className="flex flex-col items-start justify-center"
                      >
                        <span className="text-xl">{item.name}</span>
                        <span className="text-xs">{item.email}</span>
                      </Link>
                    </td>
                    <td className="px-3 py-3">
                      <div className="flex flex-col items-end justify-center">
                        <span className="text-sm">
                          <span className="text-[#A1A1A1]">Card ID:</span>{" "}
                          {item.employee_id}
                        </span>
                        <span className="text-sm">
                          <span className="text-[#A1A1A1]">Status:</span>{" "}
                          {item.status == 1 ? "Active" : "Terminated"}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {workers?.data?.length <= 0 && workers?.pagination?.search && (
              <>
                <NoTableOption data={noSearchOption} />
              </>
            )}
            {workers?.data?.length > 0 && (
              <NewTablePagination
                data={workers}
                setData={setWorkers}
                isMobile={true}
                isApp={true}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default WorkersTableSiteManager;
