import React, { useEffect, useState, useContext, createContext } from "react";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import {
  DeleteEmployeeStatus,
  GetEmployeeStatus,
} from "../../../../../helper/setup/payroll/employeentstatus";
import EmployementStatusTable from "../../../../../shared/DataTable/EmployementStatusTable";
import { FaSearch } from "react-icons/fa";
import { PayRollService } from "../../../../../config/axiosUrl";
import { SearchData } from "../../../../../helper/global/global";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import {
  TableDefaultSvg,
  NoSearchTableSvg,
} from "../../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { TablePagination } from "../../../../../components/pagination/pagination";
import { CreateAdminContext } from "../../../../../layouts/SettingsLayout";

const EmployementStatus = () => {
  //context
  const [createAdmincontext, setcreateAdminContext] =
    useContext(CreateAdminContext);

  const initialValues = {
    data: [],
    selectedData: {},
    seach: "",
    extra: {},
    pagination: {
      api: "/api/v1/setting/payroll/get-employment-status-data",
      service: PayRollService,
    },
    isShimmer: true,
    isLoading: false,
  };

  const [employee, setEmployee] = useState(initialValues);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [createEmployementStatus, setCreateEmployementStatus] = useState(false);

  let deafaulPage = {
    disclaimer: "No Employment Status Found",
    description: "Create Your First Employment Status",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handleEmployee = {
    search: async (e) => {
      const params = {
        value: e?.target?.value,
        api: "/api/v1/setting/payroll/get-employment-status-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setEmployee({
          ...employee,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          [e?.target?.name]: e?.target?.value,
        });
      }
    },
    delete: async () => {
      setEmployee({
        ...employee,
        isLoading: true,
      });
      let allData = [...employee?.data];
      allData?.splice(employee?.selectedData?.index, 1);
      let data = await DeleteEmployeeStatus(employee?.selectedData);
      if (data?.status) {
        setEmployee({
          ...employee,
          data: allData,
          extra: {
            ...employee?.extra,
            totalItems: employee?.extra?.totalItems - 1,
          },
          selectedData: {},
          isLoading: false,
        });
        setModalVisible(false);
      } else {
        setEmployee({
          ...employee,
          isLoading: false,
        });
      }
    },
    submit: () => {},
  };

  useEffect(() => {
    (async () => {
      let data = await GetEmployeeStatus();

      if (data?.status) {
        setEmployee({
          ...employee,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
        });
        setcreateAdminContext(false);
      }
    })();
  }, [createAdmincontext]);

  return (
    <>
      <div className="p-6">
        {employee?.isShimmer ? (
          <div className="">
            <div className="w-full lg:w-10/12 mx-auto bg-white px-4 py-3">
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </div>
          </div>
        ) : (
          <>
            {!employee?.data?.length > 0 && !employee?.search ? (
              <>
                <div className="w-full lg:w-10/12 mx-auto bg-white px-4 py-3">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              </>
            ) : (
              <>
                <div className="px-20">
                  <div className="bg-white py-4 px-4">
                    <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-2/4">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        type="text"
                        placeholder="Search by Employment Status Types"
                        className="h-[31px] bg-transparent text-sm w-full px-2"
                        key="1"
                        name="search"
                        onChange={handleEmployee?.search}
                      />
                    </div>
                  </div>

                  <EmployementStatusTable
                    data={employee}
                    setData={setEmployee}
                    handle={handleEmployee}
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    createEmployementStatus={createEmployementStatus}
                    setCreateEmployementStatus={setCreateEmployementStatus}
                  />

                  <div className="w-full  mx-auto bg-white px-4 py-3">
                    {employee?.data?.length <= 0 && employee?.search ? (
                      <>
                        <NoTableOption data={noSearchOption} />
                      </>
                    ) : (
                      <>
                        <TablePagination
                          data={employee}
                          setData={setEmployee}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EmployementStatus;
