const TaskDataDefaultPage = ({ data }) => {
  return (
    <>
      <div
        className={"h-full flex justify-center items-center flex-col bg-white"}
      >
        <div className="py-2">{data?.svg}</div>
        <div className="">{data?.disclaimer}</div>
        <div className="text-gray-300 text-base py-2">{data?.description}</div>
      </div>
    </>
  );
};

export default TaskDataDefaultPage;
